import React from 'react';
import useDelay from '../hooks/useDelay';
import Spinner from '../img/spinner.gif';

export const LoadingSpinner = ({ className }) => {
  const isPastDelay = useDelay(250);

  if (isPastDelay) {
    return <img src={Spinner} alt="loading" className={className} />;
  }
  return null;
};

export default LoadingSpinner;
