import React, { memo } from 'react';
import PropTypes from 'prop-types';
import style from '../style/components/Counter.module.scss';

const Counter = ({ kind, children, ...props }) => (
  <div
    className={`${style.counter} ${kind === 'success' ? style.success : ''} ${
      kind === 'info' ? style.info : ''
    }`}
    {...props}
  >
    {children}
  </div>
);

Counter.propTypes = {
  children: PropTypes.node.isRequired,
  kind: PropTypes.string,
};

export default memo(Counter);
