import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import WebsiteRow from './WebsitesRow';
import WebsiteInfoModal from './WebsiteInfoModal';
import SearchBar from '../SearchBar';
import WebsiteProps from '../../proptypes/website';
import websiteIcon from '../../img/icons/websites.svg';
import style from '../../style/components/signin/WebsitesGrantedAccess.module.scss';
import Card from '../Card';

class WebsitesGrantedAccess extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleRevoke: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    websites: PropTypes.arrayOf(WebsiteProps).isRequired,
  };

  state = {
    active: {},
    isModalOpen: false,
  };

  handleModalClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleModalOpen = (id) => {
    const { websites } = this.props;
    const active = websites.find((site) => site.id === id);

    this.setState({
      active,
      isModalOpen: true,
    });
  };

  render() {
    const { active, isModalOpen } = this.state;
    const { handleChange, handleRevoke, search, websites } = this.props;

    return (
      <Card
        title="Websites Granted Access"
        icon={<img alt="" src={websiteIcon} />}
      >
        <p>
          These are websites you have given access to. They can request info you
          chose to share with them.
        </p>
        <SearchBar handleChange={handleChange} search={search} />
        <table className={style.table}>
          <thead>
            <tr>
              <th>WEBSITE NAME</th>
              <th>AUTHORIZED</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {websites.map((site) => (
              <WebsiteRow
                key={site.id}
                style={style}
                handleModalOpen={this.handleModalOpen}
                handleRevoke={handleRevoke}
                {...site}
              />
            ))}
          </tbody>
        </table>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={this.handleModalClose}
          className={style.modal}
          overlayClassName={style.overlay}
        >
          <WebsiteInfoModal
            handleModalClose={this.handleModalClose}
            {...active}
          />
        </Modal>
      </Card>
    );
  }
}

export default WebsitesGrantedAccess;
