import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fetchUser } from '../actions/user';
import UserProps from '../proptypes/user';
import Profile from '../components/overview/Profile';

export class OverviewProfile extends Component {
  static propTypes = {
    actionFetchUser: PropTypes.func.isRequired,
    user: UserProps.isRequired,
  };

  componentDidMount() {
    const { actionFetchUser, user } = this.props;
    if (!user.isFetching) {
      actionFetchUser();
    }
  }

  render() {
    const { user } = this.props;

    return <Profile user={user} />;
  }
}

export const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = {
  actionFetchUser: fetchUser,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OverviewProfile),
);
