import React, { Fragment, Component } from 'react';
import QRCode from 'qrcode';
import { sendToSentry } from '../../utils/error';
import ButtonLink from '../ButtonLink';
import IdProps from '../../proptypes/id';
import { MMDDYYYY } from '../../utils/time';
import { popup } from '../../utils/popup';
import { IdH3 } from '../IdHead';
import Expand from '../../img/icons/expand-bar.svg';
import style from '../../style/components/wallet/Id.module.scss';
import QrCode from './QrCode';

// this is a helper function that splits a string across lines using <div>s
const split = (value) => {
  if (!value) {
    return '';
  }
  if (typeof value === 'string') {
    const array = value.split('\n');
    if (array.length === 1) {
      return value;
    }
    return (
      <Fragment>
        {array.map((s) => (
          <div key={s}>{s}</div>
        ))}
      </Fragment>
    );
  }
  if (typeof value === 'object' && value.length >= 0) {
    // array.
    return (
      <Fragment>
        {value.map((s) => (
          <div key={s}>{s}</div>
        ))}
      </Fragment>
    );
  }
  return '';
};

class Credential extends Component {
  static propTypes = {
    ...IdProps,
  };

  state = {
    collapsed: true,
  };

  async componentDidMount() {
    // we want to calculate this when the whole credential loads, so
    // it will be ready when the user expands the card

    const { qr_string } = this.props;

    if (qr_string) {
      try {
        const qrImage = await QRCode.toDataURL(qr_string, {
          errorCorrectionLevel: 'L',
          margin: 0,
        });
        this.setState({ qrImage });
      } catch (e) {
        sendToSentry(e);
      }
    }
  }

  toggleCollapse = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  handlePopup = (e) => {
    e.preventDefault();
    const { target } = e;
    popup(target.target, target.href);
  };

  renderCTA() {
    const { shop_url } = this.props;

    return (
      shop_url && (
        <div className={style.cta}>
          <a
            href={`${shop_url}&utm_medium=web`}
            target="shop"
            onClick={this.handlePopup}
          >
            Find Discounts
          </a>
        </div>
      )
    );
  }

  renderDetails() {
    const { properties } = this.props;

    return properties.map(({ handle, name, value, type }) => {
      if (type === 'sub_title') {
        return (
          <div className={style.sub_title} key={handle}>
            <div className={style.dt}>{name}</div>
            <div className={style.dd}>{split(value)}</div>
          </div>
        );
      }

      if (type === 'list_item') {
        return (
          <div className={style.list_item}>
            {name} {split(value)}
          </div>
        );
      }

      if (type === 'title') {
        return <div className={style.title}>{name}</div>;
      }

      return null;
    });
  }

  renderCollapsed() {
    const {
      color,
      credential_icon_url,
      stars,
      status_text,
      subtitle,
      title,
      type,
      verified,
    } = this.props;

    return (
      <button
        className={style.idCollapsed}
        onClick={this.toggleCollapse}
        aria-expanded="false"
      >
        <IdH3
          color={color}
          iconUrl={credential_icon_url}
          stars={stars}
          title={title}
          type={type}
        />
        <div className={style.idBody}>
          <div className={style.left}>{subtitle}</div>
          <div className={style.right}>
            <div className={style[status_text]}>{status_text}</div>
            <div>{MMDDYYYY(verified)}</div>
          </div>
        </div>
        {this.renderCTA()}
        <div className={style.idExpandBar}>
          <img src={Expand} alt="Expand content bar" />
        </div>
      </button>
    );
  }

  renderExpanded() {
    const {
      color,
      credential_icon_url,
      full_name,
      qr_link_text,
      qr_link_url,
      qr_logo,
      qr_title,
      stars,
      status_text,
      subtitle,
      title,
      url,
      booster_url,
      verified,
    } = this.props;

    const { qrImage } = this.state;

    return (
      <div className={style.idExpanded}>
        <IdH3
          color={color}
          iconUrl={credential_icon_url}
          stars={stars}
          title={title}
        />
        <div className={style.idBody}>
          <div className={style.left}>{full_name}</div>
          <div className={style.right}>
            <div className={style[status_text]}>{status_text}</div>
            <div>{MMDDYYYY(verified)}</div>
          </div>
        </div>
        {this.renderCTA()}
        <div className={style.detailsWrapper}>
          <div className={style.details}>
            <div className={style.group_title}>{subtitle}</div>
          </div>
          {qrImage && (
            <QrCode
              qrImage={qrImage}
              title={qr_title}
              logo={qr_logo}
              text={qr_link_text}
              url={qr_link_url}
            />
          )}
          <div className={style.details}>{this.renderDetails()}</div>

          {url && (
            <div className={style.reverify}>
              <a href={url} target="id" onClick={this.handlePopup}>
                Reverify ID
              </a>
            </div>
          )}
          {booster_url && (
            <div className={style.reverify}>
              <a href={booster_url} target="id" onClick={this.handlePopup}>
                Add a Booster
              </a>
            </div>
          )}
        </div>

        <div className={style.button}>
          <ButtonLink onClick={this.toggleCollapse} aria-expanded="true">
            collapse
          </ButtonLink>
        </div>
      </div>
    );
  }

  renderComponentCard() {
    const { properties, title, credential_icon_url, disclaimer_html } =
      this.props;

    return (
      <idme-id-card
        class={style.idOuter}
        headerText={title}
        iconURL={credential_icon_url}
        headingLevel="3"
      >
        <idme-dl wrapAfter="2">
          {properties.map((item) => (
            <idme-dl-item
              key={item.name}
              term={item.name}
              detail={item.value}
            ></idme-dl-item>
          ))}
        </idme-dl>
        <hr className={style.idSeparator} />
        <section className={style.idDisclaimer}>
          <div dangerouslySetInnerHTML={{ __html: disclaimer_html }} />
        </section>
      </idme-id-card>
    );
  }

  render() {
    const { collapsed } = this.state;

    if (this.props.type === 'rx') {
      return this.renderComponentCard();
    }

    return (
      <div className={style.idOuter}>
        {collapsed ? this.renderCollapsed() : this.renderExpanded()}
      </div>
    );
  }
}

export default Credential;
