import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MMDDYYYY, HHMM } from '../../utils/time';

const formatId = (id) => {
  // Add a "-" every 4 characters
  const formatted = String(id).replace(/.{4}/g, '$&-');
  return formatted.slice(-1) === '-' ? formatted.slice(0, -1) : formatted;
};

const SupportRequestRow = ({
  id,
  description,
  comment_count,
  updated_at,
  created_at,
  style,
}) => {
  return (
    <tr className={style.row}>
      <td title="TICKET #">{formatId(id)}</td>
      <td title="DESCRIPTION">{description}</td>
      <td title="ACTIVITY" className={style.noWrap}>
        {comment_count === 1
          ? 'Submitted'
          : `${comment_count - 1} comment${comment_count - 1 !== 1 ? 's' : ''}`}
      </td>
      <td title="UPDATED">
        {updated_at === created_at
          ? null
          : MMDDYYYY(updated_at) + '\n' + HHMM(updated_at)}
      </td>
      <td title="SUBMITTED">
        {MMDDYYYY(created_at) + '\n' + HHMM(created_at)}
      </td>
    </tr>
  );
};

SupportRequestRow.propTypes = {
  comment_count: PropTypes.number,
  created_at: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  style: PropTypes.shape({}).isRequired,
  updated_at: PropTypes.string,
};

SupportRequestRow.defaultProps = {
  comment_count: 0,
  created_at: '',
  description: '',
  id: null,
  updated_at: '',
};

export default memo(SupportRequestRow);
