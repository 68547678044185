import {
  FETCH_EMAIL_CONFIRM_BEGIN,
  FETCH_EMAIL_CONFIRM_SUCCESS,
  FETCH_EMAIL_CONFIRM_FAILURE,
} from '../actions/emailConfirmation';

export const initialState = {
  isFetching: false,
  isSuccess: false,
  message: '',
  message2: '',
  showLink: false,
};

const emailConfirmation = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_EMAIL_CONFIRM_BEGIN:
      return {
        ...state,
        isFetching: true,
        showLink: false,
      };
    case FETCH_EMAIL_CONFIRM_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        message: 'Your confirmation link has expired.',
        message2:
          'Please visit your profile page to resend a new confirmation link.',
        showLink: true,
      };
    case FETCH_EMAIL_CONFIRM_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        message: undefined,
        message2: undefined,
        showLink: true,
      };
    default:
      return state;
  }
};

export default emailConfirmation;
