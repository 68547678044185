import React, { useCallback } from 'react';
import StyledModal from '../StyledModal';

import { useDispatch } from 'react-redux';
import { cancelAuthorization } from '../../actions/linkedAccounts';

const CancelAccessRequestButton = ({ closeModal, isOpen, request }) => {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      dispatch(
        cancelAuthorization({
          id: request.id,
        }),
      );

      closeModal();
    },
    [dispatch, request, closeModal],
  );

  return (
    <StyledModal
      isOpen={isOpen}
      closeModal={closeModal}
      title={'Cancel your request to access ' + request.website_name + '?'}
      primaryButton={{
        text: 'Yes',
        action: handleSubmit,
      }}
      cancelButton={{
        text: 'No',
      }}
    >
      <p>
        We will send an email notifying {request.name} ({request.email}) that
        you no longer need access to this ID.me partner site.
      </p>
    </StyledModal>
  );
};

export default CancelAccessRequestButton;
