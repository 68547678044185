import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import AlertBanner from '../components/AlertBanner';
import ConfirmationModal from '../components/ConfirmationModal';
import { fetchAlerts, confirmAlerts } from '../actions/alerts';

class AlertsContainer extends Component {
  state = {
    modalIsOpen: false,
  };

  componentDidMount() {
    const { actionFetchAlerts, isFetching } = this.props;
    if (!isFetching) {
      actionFetchAlerts();
    }
  }

  componentDidUpdate(prevProps) {
    const { success } = this.props;

    if (success && !prevProps.success) {
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({ modalIsOpen: true });
    }
  }

  handleClick = () => {
    const { actionConfirmAlerts, isFetching } = this.props;
    if (!isFetching) {
      actionConfirmAlerts();
    }
  };

  handleClose = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { isFetching, fname } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <Fragment>
        <AlertBanner
          {...this.props}
          handleClick={this.handleClick}
          loading={isFetching}
        />
        <ConfirmationModal
          fname={fname}
          handleClose={this.handleClose}
          isOpen={modalIsOpen}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.alerts,
  fname: state.user.fname,
});

const mapDispatchToProps = {
  actionConfirmAlerts: confirmAlerts,
  actionFetchAlerts: fetchAlerts,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsContainer);
