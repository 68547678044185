import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Preferences from '../components/overview/Preferences';
import { fetchHomepage, fetchSubscriptions } from '../actions/preferences';
import { countActiveSubscriptions } from '../utils/filters';
import memoize from '../utils/memoize';
import UserProps from '../proptypes/user';

export class OverviewPreferences extends Component {
  static propTypes = {
    actionFetchHomepage: PropTypes.func.isRequired,
    actionFetchSubscriptions: PropTypes.func.isRequired,
    homepage: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    subscriptions: PropTypes.arrayOf(PropTypes.shape({})),
    user: UserProps.isRequired,
  };

  static defaultProps = {
    homepage: '',
    subscriptions: [],
  };

  memoizedCount = memoize(countActiveSubscriptions);

  componentDidMount() {
    const { actionFetchHomepage, actionFetchSubscriptions, isFetching } =
      this.props;
    if (!isFetching) {
      actionFetchSubscriptions();
      actionFetchHomepage();
    }
  }

  render() {
    const { homepage, subscriptions, user } = this.props;
    const count = this.memoizedCount(subscriptions);

    if (user?.is_minor) {
      return null;
    }

    return <Preferences homepage={homepage} count={count} />;
  }
}

export const mapStateToProps = (state) => ({
  user: state.user,
  ...state.preferences,
});

const mapDispatchToProps = {
  actionFetchHomepage: fetchHomepage,
  actionFetchSubscriptions: fetchSubscriptions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OverviewPreferences),
);
