import React, { useEffect, useState } from 'react';
import SelfieModal from './SelfieModal';
import StyledModal from '../StyledModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteRequestByType,
  fetchDataRequestByType,
} from '../../actions/dataRequests';
import { SELFIE_DELETE } from '../../constants';

const SelfieDeletionModal = ({ closeModal, isOpen }) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const dispatch = useDispatch();

  const dataRequests = useSelector((state) => state.dataRequests);

  const handleDeleteSelfie = () => dispatch(deleteRequestByType(SELFIE_DELETE));

  useEffect(() => {
    if (dataRequests.selfieDeleteSuccess) {
      dispatch(fetchDataRequestByType(SELFIE_DELETE));
      setIsConfirmModalOpen(false);
    }
  }, [
    dispatch,
    dataRequests.selfieDeleteSuccess,
    closeModal,
    setIsConfirmModalOpen,
  ]);

  return (
    <>
      <StyledModal
        isOpen={isOpen}
        closeModal={closeModal}
        title="Delete my selfie"
        primaryButton={{
          text: 'Delete my selfie',
          action: () => {
            closeModal();
            setIsConfirmModalOpen(true);
          },
        }}
      >
        <p>
          ID.me is committed to providing members with control over how their
          data is used. As part of this commitment, our members can request that
          we delete the selfies they provide to us during identity
          verification.&nbsp;
          <strong>
            Please note that deleting the selfie may make account recovery more
            difficult.
          </strong>
        </p>

        <p>
          <strong>Your selfie is a representation of you</strong>
          <br />
          It enables ID.me to combat fraud and identity theft.
        </p>
        <p>
          <strong>What happens after selfie deletion?</strong>
          <br />
          It may take up to seven (7) days to delete your selfie and associated
          biometric data. We will confirm that we have received your request
          with the following message: “
          <i>
            We've received your request to delete your data. It may take up to 7
            days to be removed.
          </i>
          ”
        </p>
        <p>
          <strong>NOTE: Once selfie is deleted, it cannot be undone.</strong>
        </p>
      </StyledModal>
      <SelfieModal
        isOpen={isConfirmModalOpen}
        handleCancel={() => setIsConfirmModalOpen(false)}
        handleConfirm={handleDeleteSelfie}
        submitting={dataRequests.isFetching}
      />
    </>
  );
};

export default SelfieDeletionModal;
