import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../ButtonLink';
import Credential from './Credential';
import Expired from './Expired';
import Inactive from './Inactive';
import IdProps from '../../proptypes/id';
import downArrow from '../../img/icons/down-arrow.svg';
import idIcon from '../../img/icons/id.svg';
import style from '../../style/components/wallet/MyIds.module.scss';
import { useSelector } from 'react-redux';

const MyIds = ({
  active,
  collapseActive,
  collapseExpired,
  collapseInactive,
  expired,
  inactive,
  isActiveCollapsed,
  isExpiredCollapsed,
  isInactiveCollapsed,
}) => {
  const isMinor = useSelector((state) => state.user?.is_minor);

  return (
    <div className={style.wrapper}>
      <main className={style.ids}>
        <h3 className={style.title}>
          <img alt="IDs" src={idIcon} />
          My IDs
        </h3>
        {active.length > 0 && (
          <Fragment>
            <h4 className={style.subtitle}>
              <ButtonLink
                onClick={collapseActive}
                aria-expanded={!isActiveCollapsed}
              >
                <img
                  alt=""
                  src={downArrow}
                  className={isActiveCollapsed ? style.collapsed : ''}
                />
                <span className="sr-only">
                  {isActiveCollapsed ? 'expand' : 'collapse'}
                </span>
              </ButtonLink>
              {`Active IDs (${active.length})`}
            </h4>
            {!isActiveCollapsed && (
              <section className={style.idList}>
                {active.map((credential) => (
                  <Credential key={credential.type} {...credential} />
                ))}
              </section>
            )}
          </Fragment>
        )}
        {expired.length > 0 && (
          <Fragment>
            <h4 className={style.subtitle}>
              <ButtonLink
                onClick={collapseExpired}
                aria-expanded={!isExpiredCollapsed}
              >
                <img
                  alt=""
                  src={downArrow}
                  className={isExpiredCollapsed ? style.collapsed : ''}
                />
                <span className="sr-only">
                  {isExpiredCollapsed ? 'expand' : 'collapse'}
                </span>
              </ButtonLink>
              {`Expired IDs (${expired.length})`}
            </h4>
            {!isExpiredCollapsed && (
              <Fragment>
                <p className={style.error}>
                  The following ID
                  {expired.length > 1 ? 's' : ''} ha
                  {expired.length > 1 ? 've' : 's'} expired. Tap card for
                  details.
                </p>
                <section className={style.idList}>
                  {expired.map((credential) => (
                    <Expired key={credential.type} {...credential} />
                  ))}
                </section>
              </Fragment>
            )}
          </Fragment>
        )}
        {inactive.length > 0 && (
          <Fragment>
            <h4 className={style.subtitle}>
              <ButtonLink
                onClick={collapseInactive}
                aria-expanded={!isInactiveCollapsed}
              >
                <img
                  alt=""
                  src={downArrow}
                  className={isInactiveCollapsed ? style.collapsed : ''}
                />
                <span className="sr-only">
                  {isInactiveCollapsed ? 'expand' : 'collapse'}
                </span>
              </ButtonLink>
              {`Inactive IDs (${inactive.length})`}
            </h4>
            {!isInactiveCollapsed && isMinor && (
              <Fragment>
                <p>
                  {`ID.me members below the age of 18 cannot verify or access community-specific IDs and services (e.g., Jobs, Shop, and Rx). These features will be unlocked for your account on your 18th birthday.`}
                </p>
              </Fragment>
            )}
            {!isInactiveCollapsed && (
              <section className={style.idList}>
                {inactive.map((credential) => (
                  <Inactive
                    key={credential.type}
                    isMinor={isMinor}
                    {...credential}
                  />
                ))}
              </section>
            )}
          </Fragment>
        )}
      </main>
    </div>
  );
};

MyIds.propTypes = {
  active: PropTypes.arrayOf(
    PropTypes.shape({
      ...IdProps,
    }),
  ),
  collapseActive: PropTypes.func.isRequired,
  collapseExpired: PropTypes.func.isRequired,
  collapseInactive: PropTypes.func.isRequired,
  expired: PropTypes.arrayOf(
    PropTypes.shape({
      ...IdProps,
    }),
  ),
  inactive: PropTypes.arrayOf(
    PropTypes.shape({
      ...IdProps,
    }),
  ),
  isActiveCollapsed: PropTypes.bool.isRequired,
  isExpiredCollapsed: PropTypes.bool.isRequired,
  isInactiveCollapsed: PropTypes.bool.isRequired,
};

MyIds.defaultProps = {
  active: [],
  expired: [],
  inactive: [],
};

export default MyIds;
