import axios from 'axios';
import { URL_TWOFA } from '../constants/urls';

export const FETCH_FACTORS_BEGIN = 'FETCH_FACTORS_BEGIN';
export const FETCH_FACTORS_SUCCESS = 'FETCH_FACTORS_SUCCESS';
export const FETCH_FACTORS_FAILURE = 'FETCH_FACTORS_FAILURE';

export const FETCH_FACTOR_UPDATE_BEGIN = 'FETCH_FACTOR_UPDATE_BEGIN';
export const FETCH_FACTOR_UPDATE_SUCCESS = 'FETCH_FACTOR_UPDATE_SUCCESS';
export const FETCH_FACTOR_UPDATE_FAILURE = 'FETCH_FACTOR_UPDATE_FAILURE';

export const FETCH_FACTOR_DELETE_BEGIN = 'FETCH_FACTOR_DELETE_BEGIN';
export const FETCH_FACTOR_DELETE_SUCCESS = 'FETCH_FACTOR_DELETE_SUCCESS';
export const FETCH_FACTOR_DELETE_FAILURE = 'FETCH_FACTOR_DELETE_FAILURE';

export const fetchFactors = () => async (dispatch) => {
  dispatch({ type: FETCH_FACTORS_BEGIN });

  try {
    const response = await axios.get(URL_TWOFA);

    dispatch({
      payload: response.data,
      type: FETCH_FACTORS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_FACTORS_FAILURE,
    });
  }
};

export const updateFactor =
  ({ name, id }) =>
  async (dispatch) => {
    dispatch({ type: FETCH_FACTOR_UPDATE_BEGIN });

    try {
      const response = await axios.put(`${URL_TWOFA}/${id}`, { name });

      dispatch({
        payload: response.data,
        type: FETCH_FACTOR_UPDATE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.message,
        type: FETCH_FACTOR_UPDATE_FAILURE,
      });
    }
  };

export const deleteFactor = (id) => async (dispatch) => {
  dispatch({ type: FETCH_FACTOR_DELETE_BEGIN });

  try {
    const response = await axios.delete(`${URL_TWOFA}/${id}`);

    dispatch({
      payload: response.data,
      type: FETCH_FACTOR_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_FACTOR_DELETE_FAILURE,
    });
  }
};
