import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ButtonPrimary from '../ButtonPrimary';
import LoadingSpinner from '../LoadingSpinner';
import Lock from '../../img/icons/lock-data.svg';
import checkmark from '../../img/icons/check-blue.svg';
import style from '../../style/components/california/Lock.module.scss';

const LockData = ({ handleLock, failed, loading, requested }) => {
  if (requested) {
    return (
      <Fragment>
        <img className={style.image} src={Lock} alt="" />
        <h1 className={style.headline}>Data Lock Requested</h1>
        <p>
          <img src={checkmark} alt="checkmark" />
          Your request to lock your personal ID.me data has been received.
          <br />
          If you wish to revert this change, log in to your ID.me account's
          privacy page to manage your data setting.
        </p>
        <Link className={style.link} to="/overview">
          Continue
        </Link>
      </Fragment>
    );
  }

  if (failed) {
    // error?
    return null;
  }

  return (
    <Fragment>
      <img className={style.image} src={Lock} alt="" />
      <h1 className={style.headline}>Lock your data?</h1>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Fragment>
          <p className={style.error}>
            Your privacy matters to us. Locking your data means opting out of a
            transfer that might be considered a sale of your information.
          </p>
          <p className={style.strong}>Would you like to proceed?</p>
          <div className={style.buttons}>
            <Link className={style.linkSecondary} to="/manage-data">
              Cancel Request
            </Link>
            <ButtonPrimary onClick={handleLock}>Lock my Data</ButtonPrimary>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default LockData;
