import React from 'react';
import style from '../../style/components/profile/EmailAddError.module.scss';

const EmailAddError = () => (
  <div className={style.errorWrapper}>
    <p className={style.error}>
      <strong>Email not added</strong>
      <br />
      This email could not be added because it is either invalid, already
      associated with another account, or you've attempted to add too many
      emails within a short period of time.
    </p>
    <p className={style.small}>
      If you feel like you have received this message in error, please
      click&nbsp;
      <a href="https://help.id.me/hc/en-us/requests/new">here</a>
      &nbsp;to request assistance.
    </p>
  </div>
);

export default EmailAddError;
