import React, { memo, useEffect } from 'react';
import Header from './Header';
import PersonalProfile from './PersonalProfile';
import WorkProfile from './WorkProfile';
import style from '../../style/components/profile/ProfilesWrapper.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWorkProfiles } from '../../actions';

const ProfilesWrapper = () => {
  // get user from redux state
  const user = useSelector((state) => state.user);
  const workProfiles = useSelector((state) => state.workProfile.profiles);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWorkProfiles());
  }, [dispatch]);

  // show work profile if there is at least one
  const showWorkProfile = workProfiles.length > 0;

  return (
    <>
      <Header user={user} />

      <div className={style.profilesWrapper}>
        <PersonalProfile user={user} />
        {showWorkProfile ? <WorkProfile workProfile={workProfiles[0]} /> : null}
      </div>
    </>
  );
};

export default memo(ProfilesWrapper);
