import React from 'react';
import PropTypes from 'prop-types';
import style from '../../style/components/linkages/LinkedAccounts.module.scss';
import AccessRequestsTable from './AccessRequestsTable';

const AccessRequests = ({ linkageRequests }) => (
  <div className={style.accounts}>
    <h3 className={style.title}>
      <idme-icon name="mail" />
      Access Requests
    </h3>
    <div className={style.table}>
      <AccessRequestsTable linkageRequests={linkageRequests} />
    </div>
  </div>
);

AccessRequests.propTypes = {
  linkageRequests: PropTypes.arrayOf(PropTypes.shape({})),
  isFetching: PropTypes.bool.isRequired,
};

AccessRequests.defaultProps = {
  linkageRequests: [],
};

export default AccessRequests;
