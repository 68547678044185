import {
  FETCH_EMAILS_BEGIN,
  FETCH_EMAILS_SUCCESS,
  FETCH_EMAILS_FAILURE,
  FETCH_EMAIL_ADD_BEGIN,
  FETCH_EMAIL_ADD_SUCCESS,
  FETCH_EMAIL_ADD_FAILURE,
  FETCH_EMAIL_UPDATE_BEGIN,
  FETCH_EMAIL_UPDATE_SUCCESS,
  FETCH_EMAIL_UPDATE_FAILURE,
  FETCH_EMAIL_PRIMARY_BEGIN,
  FETCH_EMAIL_PRIMARY_SUCCESS,
  FETCH_EMAIL_PRIMARY_FAILURE,
  FETCH_EMAIL_RESEND_BEGIN,
  FETCH_EMAIL_RESEND_SUCCESS,
  FETCH_EMAIL_RESEND_FAILURE,
  FETCH_EMAIL_DELETE_BEGIN,
  FETCH_EMAIL_DELETE_SUCCESS,
  FETCH_EMAIL_DELETE_FAILURE,
  RESET_EMAIL_STATE,
} from '../actions/emails';

export const initialState = {
  isSuccess: false,
  emailAddError: false,
  emails: [],
  error: null,
  isFetching: false,
};

const emails = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_EMAILS_BEGIN:
    case FETCH_EMAIL_PRIMARY_BEGIN:
    case FETCH_EMAIL_RESEND_BEGIN:
    case FETCH_EMAIL_DELETE_BEGIN:
    case FETCH_EMAIL_UPDATE_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_EMAILS_SUCCESS:
    case FETCH_EMAIL_PRIMARY_SUCCESS:
    case FETCH_EMAIL_RESEND_SUCCESS:
    case FETCH_EMAIL_DELETE_SUCCESS:
      return {
        ...state,
        emails: action.payload,
        error: null,
        isFetching: false,
      };
    case FETCH_EMAILS_FAILURE:
    case FETCH_EMAIL_PRIMARY_FAILURE:
    case FETCH_EMAIL_RESEND_FAILURE:
    case FETCH_EMAIL_DELETE_FAILURE:
    case FETCH_EMAIL_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case FETCH_EMAIL_ADD_FAILURE:
      return {
        ...state,
        emailAddError: true,
        error: action.payload,
        isFetching: false,
      };
    case FETCH_EMAIL_ADD_BEGIN:
      return {
        ...state,
        isSuccess: false,
        error: null,
        isFetching: true,
      };
    case FETCH_EMAIL_ADD_SUCCESS:
    case FETCH_EMAIL_UPDATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        emails: action.payload,
        error: null,
        isFetching: false,
      };
    case RESET_EMAIL_STATE:
      return {
        ...state,
        emailAddError: false,
        isSuccess: false,
        isFetching: false,
        error: null,
      };

    default:
      return state;
  }
};

export default emails;
