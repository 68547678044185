import { useEffect, useState } from 'react';

const useDelay = (delay = 250) => {
  const [isPastDelay, setIsPastDelay] = useState(false);
  useEffect(() => {
    const id = window.setTimeout(() => {
      setIsPastDelay(true);
    }, delay);
    return () => {
      window.clearTimeout(id);
    };
  }, [delay]);

  return isPastDelay;
};

export default useDelay;
