import React, { Fragment, useState } from 'react';
import CancelAccessRequestModal from './CancelAccessRequestModal';

const CancelAccessRequestButton = ({ request }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Fragment>
      <idme-button
        variant="secondary"
        size="small"
        text="Cancel"
        layout="content"
        onClick={() => setIsModalOpen(true)}
      />
      <CancelAccessRequestModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        request={request}
      />
    </Fragment>
  );
};

export default CancelAccessRequestButton;
