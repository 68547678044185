import React from 'react';
import useDelay from '../hooks/useDelay';
import Spinner from '../img/spinner.gif';
import style from '../style/components/LoadingPage.module.scss';

const LoadingPage = () => {
  const isPastDelay = useDelay(250);

  if (isPastDelay) {
    return (
      <div className={style.fullPage}>
        <img src={Spinner} alt="Loading" />
      </div>
    );
  }
  return <div className={style.fullPage} />;
};

export default LoadingPage;
