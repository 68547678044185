import React, { useCallback } from 'react';
import StyledModal from '../StyledModal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLinkage } from '../../actions/linkedAccounts';

const RevokeLinkageModal = ({ closeModal, isOpen, account }) => {
  // TOOD: Not actually really doing anything yet, to follow
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      dispatch(
        deleteLinkage({
          id: account.linkage_id,
        }),
      );

      closeModal();
    },
    [dispatch, account, closeModal],
  );

  const isMinor = useSelector((state) => state?.user.is_minor);

  return (
    <StyledModal
      isOpen={isOpen}
      closeModal={closeModal}
      title={'Revoke ' + account.name + "'s link with your account?"}
      primaryButton={{
        text: 'Revoke',
        action: handleSubmit,
      }}
    >
      <p>
        Revoking a linked account may result in {isMinor ? 'your' : 'their'}{' '}
        losing access to benefits or offers from ID.me partner sites.
      </p>
    </StyledModal>
  );
};

export default RevokeLinkageModal;
