import React, { useEffect, Fragment } from 'react';
import ReactGA from 'react-ga';
import OverviewProfile from '../containers/OverviewProfile';
import OverviewIds from '../containers/OverviewIds';
import OverviewLogins from '../containers/OverviewLogins';
import OverviewPreferences from '../containers/OverviewPreferences';
import OverviewCashback from '../containers/OverviewCashback';
import OverviewActivities from '../containers/OverviewActivities';
import OverviewDataDownload from '../containers/OverviewDataDownload';
import style from '../style/pages/Overview.module.scss';
import BiometricsModal from '../containers/BiometricsModal';
import { getReactEnv } from '../constants';

const Overview = () => {
  useEffect(() => {
    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  return (
    <Fragment>
      <div className={style.gray}>
        <main className={style.content}>
          <OverviewDataDownload />
          <OverviewProfile isOverviewPage />
          <OverviewIds />
          <OverviewLogins />
          <div className={style.bottomRow}>
            <OverviewPreferences />
            <OverviewCashback />
            <OverviewActivities />
          </div>
        </main>
      </div>
      <BiometricsModal />
    </Fragment>
  );
};

export default Overview;
