import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import SupportRequestsTable from './SupportRequestsTable';
import textBubbleIcon from '../../img/icons/text-bubble.svg';
import style from '../../style/components/help/SupportRequests.module.scss';
import ButtonLink from '../ButtonLink';
import EmailsModal from '../EmailsModal';

const SupportRequests = ({ requests, isFetching, user }) => {
  const [isEmailsModalOpen, setIsEmailsModalOpen] = useState(false);

  return (
    <>
      <EmailsModal
        isOpen={isEmailsModalOpen}
        handleCancel={() => setIsEmailsModalOpen(false)}
      />

      <section className={style.card}>
        <h3 className={style.title}>
          <img alt="text-bubble" src={textBubbleIcon} />
          My Open Tickets
        </h3>
        <p>
          You will receive messages from ID.me Member Support at:{' '}
          <strong>{user.email}</strong>.&nbsp; If we can reach you at a better
          email address, you can{' '}
          <ButtonLink onClick={() => setIsEmailsModalOpen(true)}>
            update your primary email address
          </ButtonLink>
          .
        </p>
        <SupportRequestsTable requests={requests} isFetching={isFetching} />
        <br />
        <p>
          <i>
            If you submitted a request but don't see it, we've already addressed
            it! Check your email for a message from ID.me Member Support.
          </i>
        </p>
      </section>
    </>
  );
};

SupportRequests.propTypes = {
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      status: PropTypes.string,
    }),
  ).isRequired,
};

export default memo(SupportRequests);
