import React, { useEffect } from 'react';
import style from '../../style/components/changeRequests/ChangeRequestsModal.module.scss';
import { popup } from '../../utils/popup';
import TextFieldsIcon from '../../img/icons/text-fields.svg';
import FileIcon from '../../img/icons/file.svg';
import ClockIcon from '../../img/icons/clock.svg';
import AvailableRequestTile from './AvailableRequestTile';
import PendingRequestTile from './PendingRequestTile';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChangeRequests } from '../../actions';
import StyledModal from '../StyledModal';

const ChangeRequestsModal = ({ closeModal, isOpen }) => {
  const requests = useSelector((state) => state.changeRequests.requests);

  const dispatch = useDispatch();
  useEffect(() => {
    // fetch change requests upon modal open
    if (isOpen) {
      dispatch(fetchChangeRequests());
    }
  }, [isOpen, dispatch]);

  const AVAILABLE_CHANGE_REQUEST_CONTENT = {
    name_change: {
      description:
        'We will ask you to resubmit your identity information and documents below.',
      icon: FileIcon,
      title: 'Change my legal name',
    },
    name_correction: {
      description:
        'If we made a mistake while verifying your identity, this option will prompt us to review your documents again.',
      icon: TextFieldsIcon,
      title: 'Edit an error in my name',
    },
  };

  const PENDING_CHANGE_REQUEST_CONTENT = {
    name_change: {
      description:
        'Additional name correction services will be unavailable until this process is complete.',
      icon: ClockIcon,
      title: 'Pending: Name Change',
    },
    name_correction: {
      description:
        'Additional name correction services will be unavailable until this process is complete.',
      icon: ClockIcon,
      title: 'Pending: Name Correction',
    },
  };

  const onRequestClick = (request) => {
    return (e) => {
      e.preventDefault();
      closeModal();
      popup(request.type, request.uri);
    };
  };

  return (
    <StyledModal
      isOpen={isOpen}
      closeModal={closeModal}
      title="What would you like to update?"
    >
      <p>
        Updating your name will require ID.me staff to reverify your identity
        using new or previously submitted documents (as applicable).
      </p>
      <p className={style.background}>
        <span>Name updates may take 1-3 days to process.</span>
        <span>Thank you for your patience!</span>
      </p>

      {requests.pending.map((request) => {
        const content = PENDING_CHANGE_REQUEST_CONTENT[request.type];
        return (
          content && (
            <PendingRequestTile
              key={request.type}
              title={content.title}
              description={content.description}
              icon={content.icon}
            />
          )
        );
      })}

      {requests.available.map((request) => {
        const content = AVAILABLE_CHANGE_REQUEST_CONTENT[request.type];
        return (
          content && (
            <AvailableRequestTile
              key={request.type}
              title={content.title}
              description={content.description}
              icon={content.icon}
              uri={request.uri}
              onClick={onRequestClick(request)}
            />
          )
        );
      })}
    </StyledModal>
  );
};

export default ChangeRequestsModal;
