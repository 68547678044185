import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavBar from '../components/NavBar';
import { fetchFeatureFlags } from '../actions/featureFlags';
import { sessionEnd } from '../actions/session';
import { fetchUser } from '../actions/user';
import UserProps from '../proptypes/user';
import { BASE_DOMAIN, IDP_URL } from '../constants';

export class NavBarContainer extends Component {
  static propTypes = {
    actionFetchFeatureFlags: PropTypes.func.isRequired,
    actionFetchUser: PropTypes.func.isRequired,
    actionSignout: PropTypes.func.isRequired,
    featureFlags: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isLoggingOut: PropTypes.bool.isRequired,
    user: UserProps,
  };

  static defaultProps = {
    featureFlags: {},
    user: {
      email: '',
      fname: '',
      lname: '',
    },
  };

  componentDidMount() {
    const { actionFetchFeatureFlags, isFetching } = this.props;
    if (!isFetching) {
      actionFetchFeatureFlags();
    }
  }

  componentDidUpdate(prevProps) {
    const { actionFetchUser, isLoggedIn } = this.props;
    if (isLoggedIn && !prevProps.isLoggedIn) {
      actionFetchUser();
    }
  }

  render() {
    const { isLoggedIn, user } = this.props;

    // To prevent the NavBar from flashing before redirecting to IDP:
    // do not show the NavBar if
    // the user is not logged in
    // AND is not redirecting from an ID.me site
    //   OR is redirecting from IDP
    if (
      !isLoggedIn &&
      (!document.referrer.includes(BASE_DOMAIN) ||
        document.referrer.includes(IDP_URL))
    ) {
      return null;
    }

    return <NavBar isLoggedIn={isLoggedIn} user={user} />;
  }
}

export const mapStateToProps = (state) => {
  return {
    ...state.session,
    featureFlags: state.featureFlags,
    user: state.user,
  };
};

const mapDispatchToProps = {
  actionFetchFeatureFlags: fetchFeatureFlags,
  actionFetchUser: fetchUser,
  actionSignout: sessionEnd,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBarContainer);
