import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataDownloadReady from '../components/DataDownloadReady';
import { fetchDataDownloadStatus } from '../actions/california';

class OverviewDataDownload extends Component {
  componentDidMount() {
    const { actionFetchDataDownloadStatus } = this.props;
    actionFetchDataDownloadStatus();
  }

  render() {
    const { isDownloadReady } = this.props;
    if (isDownloadReady) {
      return <DataDownloadReady />;
    }
    return null;
  }
}

const mapStateToProps = (state) => ({ ...state.california });

const mapDispatchToProps = {
  actionFetchDataDownloadStatus: fetchDataDownloadStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OverviewDataDownload);
