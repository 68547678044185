import React, { Fragment, useState } from 'react';
import WebsitesSharedDataModal from './WebsitesSharedDataModal';

const WebsitesSharedDataButton = ({ site }) => {
  const [isSharedDataModalOpen, setIsSharedDataModalOpen] = useState(false);

  return (
    <Fragment>
      <idme-button
        variant="secondary"
        size="small"
        text="View shared data"
        layout="content"
        onClick={() => setIsSharedDataModalOpen(true)}
      />
      <WebsitesSharedDataModal
        isOpen={isSharedDataModalOpen}
        closeModal={() => setIsSharedDataModalOpen(false)}
        site={site}
      />
    </Fragment>
  );
};

export default WebsitesSharedDataButton;
