import axios from 'axios';
import { URL_PASSWORD } from '../constants/urls';

export const FETCH_UPDATE_PASSWORD_BEGIN = 'FETCH_UPDATE_PASSWORD_BEGIN';
export const FETCH_UPDATE_PASSWORD_SUCCESS = 'FETCH_UPDATE_PASSWORD_SUCCESS';
export const FETCH_UPDATE_PASSWORD_FAILURE = 'FETCH_UPDATE_PASSWORD_FAILURE';
export const PASSWORD_CLEAR_ERROR = 'PASSWORD_CLEAR_ERROR';

export const updatePassword = (passwords) => async (dispatch) => {
  dispatch({ type: FETCH_UPDATE_PASSWORD_BEGIN });

  try {
    const response = await axios.put(URL_PASSWORD, passwords);

    if (response.data.errors) {
      // sometimes we get array of array of strings, sometimes we get array of strings.
      // coerce into an array of strings.
      const payload = response.data.errors.flat();

      dispatch({
        payload,
        type: FETCH_UPDATE_PASSWORD_FAILURE,
      });
      return false;
    } else {
      dispatch({
        type: FETCH_UPDATE_PASSWORD_SUCCESS,
      });
      return true;
    }
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_UPDATE_PASSWORD_FAILURE,
    });
    return false;
  }
};

export const clearError = () => ({
  type: PASSWORD_CLEAR_ERROR,
});
