import axios from 'axios';
import { URL_CLOSE_ACCOUNT } from '../constants/urls';

export const FETCH_CLOSE_ACCOUNT_BEGIN = 'FETCH_CLOSE_ACCOUNT_BEGIN';
export const FETCH_CLOSE_ACCOUNT_SUCCESS = 'FETCH_CLOSE_ACCOUNT_SUCCESS';
export const FETCH_CLOSE_ACCOUNT_FAILURE = 'FETCH_CLOSE_ACCOUNT_FAILURE';

export const FETCH_CLOSE_ACCOUNT_CONFIRM_BEGIN =
  'FETCH_CLOSE_ACCOUNT_CONFIRM_BEGIN';
export const FETCH_CLOSE_ACCOUNT_CONFIRM_SUCCESS =
  'FETCH_CLOSE_ACCOUNT_CONFIRM_SUCCESS';
export const FETCH_CLOSE_ACCOUNT_CONFIRM_FAILURE =
  'FETCH_CLOSE_ACCOUNT_CONFIRM_FAILURE';

export const fetchCloseAccountReasons = () => async (dispatch) => {
  dispatch({
    type: FETCH_CLOSE_ACCOUNT_BEGIN,
  });

  try {
    const response = await axios.get(`${URL_CLOSE_ACCOUNT}/new`);

    dispatch({
      payload: response.data.reasons,
      type: FETCH_CLOSE_ACCOUNT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_CLOSE_ACCOUNT_FAILURE,
    });
  }
};

export const closeAccountConfirm =
  ({ comment, id }) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_CLOSE_ACCOUNT_CONFIRM_BEGIN,
    });

    try {
      await axios.post(URL_CLOSE_ACCOUNT, {
        comment,
        reason_id: id,
      });

      dispatch({
        type: FETCH_CLOSE_ACCOUNT_CONFIRM_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.message,
        type: FETCH_CLOSE_ACCOUNT_CONFIRM_FAILURE,
      });
    }
  };
