import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LockData from '../components/california/LockData';
import { fetchIsCaliforniaResident, lockData } from '../actions/california';

class LockDataContainer extends React.Component {
  state = {
    redirect: false,
    requested: false,
  };

  async componentDidMount() {
    const { actionFetchIsCaliforniaResident, isCaliforniaResident } =
      this.props;
    if (!isCaliforniaResident) {
      await actionFetchIsCaliforniaResident();

      const { isCaliforniaResident: isResident } = this.props;
      if (!isResident) {
        this.setState({ redirect: true });
      }
    }
  }

  handleLock = async () => {
    const { actionLockData } = this.props;
    const requested = await actionLockData();

    if (requested) {
      this.setState({ requested });
    } else {
      this.setState({ failed: true });
    }
  };

  render() {
    const { failed, redirect, requested } = this.state;
    if (redirect) {
      return <Redirect to="/manage-data" />;
    }
    const { isFetching } = this.props;
    return (
      <LockData
        failed={failed}
        loading={isFetching}
        requested={requested}
        handleLock={this.handleLock}
      />
    );
  }
}

const mapStateToProps = (state) => ({ ...state.california });

const mapDispatchToProps = {
  actionFetchIsCaliforniaResident: fetchIsCaliforniaResident,
  actionLockData: lockData,
};

export default connect(mapStateToProps, mapDispatchToProps)(LockDataContainer);
