import {
  FETCH_CCPA_BEGIN,
  FETCH_CCPA_SUCCESS,
  FETCH_CCPA_FAILURE,
  FETCH_CALIFORNIA_BEGIN,
  FETCH_CALIFORNIA_SUCCESS,
  FETCH_CALIFORNIA_FAILURE,
  FETCH_LOCK_DATA_BEGIN,
  FETCH_LOCK_DATA_SUCCESS,
  FETCH_LOCK_DATA_FAILURE,
  FETCH_REQUEST_DATA_BEGIN,
  FETCH_REQUEST_DATA_SUCCESS,
  FETCH_REQUEST_DATA_FAILURE,
  FETCH_DATA_STATUS_BEGIN,
  FETCH_DATA_STATUS_SUCCESS,
  FETCH_DATA_STATUS_FAILURE,
  FETCH_LOCK_STATUS_BEGIN,
  FETCH_LOCK_STATUS_SUCCESS,
  FETCH_LOCK_STATUS_FAILURE,
  FETCH_DATA_FILE_BEGIN,
  FETCH_DATA_FILE_SUCCESS,
  FETCH_DATA_FILE_FAILURE,
} from '../actions/california';

export const initialState = {
  content: '',
  error: null,
  isDownloadPending: false,
  isDownloadReady: false,
  isFetched: false,
  isFetching: false,
  isLocked: false,
  isLockPending: false,
  updatedAt: '',
  version: '',
};

const california = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_CCPA_BEGIN:
    case FETCH_CALIFORNIA_BEGIN:
    case FETCH_REQUEST_DATA_BEGIN:
    case FETCH_LOCK_DATA_BEGIN:
    case FETCH_DATA_STATUS_BEGIN:
    case FETCH_LOCK_STATUS_BEGIN:
    case FETCH_DATA_FILE_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_CCPA_SUCCESS:
    case FETCH_CALIFORNIA_SUCCESS:
    case FETCH_LOCK_DATA_SUCCESS:
    case FETCH_REQUEST_DATA_SUCCESS:
    case FETCH_DATA_STATUS_SUCCESS:
    case FETCH_LOCK_STATUS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        isFetched: true,
        isFetching: false,
      };
    case FETCH_DATA_FILE_SUCCESS:
      return {
        ...state,
        error: null,
        isDownloadReady: false,
        isFetched: true,
        isFetching: false,
      };
    case FETCH_CCPA_FAILURE:
    case FETCH_CALIFORNIA_FAILURE:
    case FETCH_REQUEST_DATA_FAILURE:
    case FETCH_LOCK_DATA_FAILURE:
    case FETCH_DATA_STATUS_FAILURE:
    case FETCH_LOCK_STATUS_FAILURE:
    case FETCH_DATA_FILE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetched: false,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default california;
