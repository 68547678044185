import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import style from '../../style/components/signin/DataRights.module.scss';
import dataRightsIcon from '../../img/icons/book-open.svg';
import RemoveDataModal from './RemoveDataModal';
import RequestDataModal from './RequestDataModal';
import RequestDataButton from './RequestDataButton';
import { useDispatch } from 'react-redux';
import { fetchDataDownloadsStatus } from '../../actions/dataDownloads';
import Card from '../Card';

const DataRights = () => {
  const history = useHistory();
  const [isRequestDataModalOpen, setIsRequestDataModalOpen] = useState(false);
  const [isRemoveDataModalOpen, setIsRemoveDataModalOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataDownloadsStatus());
  }, [dispatch]);

  const choices = [
    {
      title: 'Update my profile',
      description: (
        <>
          <p>
            You may change your address and have those changes take effect
            immediately on your account.
          </p>
          <p>
            Updating your name will require ID.me staff to reverify your
            identity using new or previously submitted documents.
          </p>
          <p>This may take up to 3 days to process.</p>
        </>
      ),
      button: (
        <idme-button
          variant="secondary"
          text="Update my information"
          type="button"
          size="small"
          onClick={() => history.push('/profile')}
        />
      ),
    },
    {
      title: 'Download my data',
      description: (
        <>
          <p>
            All ID.me users have the right to control their personal
            information. Click "Request my data" to start the process of
            gathering a consolidated copy of your personal data for download as
            a PDF.
          </p>
          <p>You may download a copy of your data once every 90 days.</p>
        </>
      ),
      button: (
        <RequestDataButton
          setIsRequestDataModalOpen={setIsRequestDataModalOpen}
        />
      ),
    },
    {
      title: 'Manage my data',
      description: (
        <>
          <p>
            ID.me is committed to providing members with control over their
            data, including requesting that the data be either deleted or
            deactivated from use and securely retained.
          </p>
          <p>
            Any ID.me member can close their ID.me account at any point in time,
            and in doing so directs ID.me to either delete or deactivate their
            personal information. For more information about ID.me's data
            retention requirements and your Privacy Rights please see our{' '}
            <a href="https://www.id.me/privacy">Privacy Policy</a>.
          </p>
        </>
      ),
      button: (
        <idme-button
          variant="secondary"
          text="Remove my data"
          type="button"
          size="small"
          onClick={() => setIsRemoveDataModalOpen(true)}
        />
      ),
    },
  ];

  return (
    <Card title="My Data Rights" icon={<img alt="" src={dataRightsIcon} />}>
      <p className={style.description}>
        Most of the personal data that ID.me has about you is accessible through
        the My Account application <Link to="/overview">account.id.me</Link>{' '}
        (e.g. search queries, location data, etc). For more information, see
        ID.me's{' '}
        <a href="https://network.id.me/privacy-bill-of-rights/">
          Privacy Bill of Rights
        </a>
        . If you have any questions or concerns about your personal data
        contained in your downloadable file, please contact us.
      </p>
      <section className={style.choices}>
        {choices.map((choice) => {
          return (
            <div className={style.choice} key={choice.title}>
              <h4>{choice.title}</h4>
              <div className={style.description}>{choice.description}</div>

              {choice.button}
            </div>
          );
        })}
      </section>

      <RequestDataModal
        isOpen={isRequestDataModalOpen}
        closeModal={() => setIsRequestDataModalOpen(false)}
      />
      <RemoveDataModal
        isOpen={isRemoveDataModalOpen}
        closeModal={() => setIsRemoveDataModalOpen(false)}
      />
    </Card>
  );
};

export default DataRights;
