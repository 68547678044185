import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import routes from '../routing/routes';
import style from '../style/components/NavTabs.module.scss';
import Nav from './Nav.jsx';
import { useSelector } from 'react-redux';

const isActive = (fullPath, parentRoute) => {
  // we want subroutes to show parent route as active
  if (fullPath.indexOf(parentRoute) === 0) {
    return true;
  }
  return false;
};

const NavTabs = ({ isLoggedIn, location }) => {
  const linkedAccountsPageEnabled = useSelector(
    (state) => state.user.can_link_accounts,
  );

  if (!isLoggedIn) {
    // not logged in, divider on mobile
    return <section className={style.spacer}>&nbsp;</section>;
  }

  const routesDom = routes
    .filter(
      (route) =>
        route.navTabs &&
        (route.requireLinkedAccounts ? linkedAccountsPageEnabled : true),
    )
    .map((route) => (
      <li key={route.path} className={style.navTabItem}>
        <NavLink
          to={route.to || route.path}
          activeClassName={style.active}
          aria-current="page"
          isActive={(match, currentLocation) =>
            match || isActive(currentLocation.pathname, route.path)
          }
        >
          {route.name}
        </NavLink>
      </li>
    ));

  const currentPage = routes
    .filter((route) => route.navTabs)
    .find((route) => isActive(location.pathname, route.path)) || {
    name: 'Home',
  };

  return <Nav currentPage={currentPage.name}>{routesDom}</Nav>;
};

NavTabs.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default withRouter(NavTabs);
