// found on stack overflow, guaranteed to work
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validEmail = (email) => {
  if (!email || typeof email !== 'string') {
    return false;
  }

  return EMAIL_REGEX.test(email.toLocaleLowerCase());
};

export default validEmail;
