import axios from 'axios';
import { URL_ADDRESS_CHANGE } from '../constants/urls';
import { addErrorMessage } from './errors';

export const FETCH_UPDATE_ADDRESS_BEGIN = 'FETCH_UPDATE_ADDRESS_BEGIN';
export const FETCH_UPDATE_ADDRESS_SUCCESS = 'FETCH_UPDATE_ADDRESS_SUCCESS';
export const FETCH_UPDATE_ADDRESS_FAILURE = 'FETCH_UPDATE_ADDRESS_FAILURE';
export const FETCH_UPDATE_ADDRESS_CLEAR = 'FETCH_UPDATE_ADDRESS_CLEAR';

export const clearUpdateAddress = () => (dispatch) => {
  dispatch({ type: FETCH_UPDATE_ADDRESS_CLEAR });
};

// NOTE: this actually calls a "create" endpoint to add a new primary address
export const updateAddress =
  ({ street, street2, city, state, zip }) =>
  async (dispatch) => {
    dispatch({ type: FETCH_UPDATE_ADDRESS_BEGIN });
    try {
      const payload = {
        city,
        state,
        street,
        street2,
        zip,
      };
      const response = await axios.post(URL_ADDRESS_CHANGE, payload);

      dispatch({
        payload: response.data,
        type: FETCH_UPDATE_ADDRESS_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error,
        type: FETCH_UPDATE_ADDRESS_FAILURE,
      });
      dispatch(addErrorMessage());
    }
  };
