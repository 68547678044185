import React from 'react';
import PropTypes from 'prop-types';
import Counter from '../Counter';
import IdList from './IdList';
import IdProps from '../../proptypes/id';
import IdIcon from '../../img/icons/id.svg';
import style from '../../style/components/overview/Ids.module.scss';

const Ids = ({ active, expired }) => (
  <article className={style.ids}>
    <div className={style.title}>
      <h3>
        <img alt="ID icon" src={IdIcon} />
        My IDs
      </h3>
      <Counter>{active.length} Active</Counter>
    </div>
    <IdList active={active} expired={expired} />
  </article>
);

Ids.propTypes = {
  active: PropTypes.arrayOf(PropTypes.shape(IdProps)),
  expired: PropTypes.arrayOf(PropTypes.shape(IdProps)),
};

Ids.defaultProps = {
  active: [],
  expired: [],
};

export default Ids;
