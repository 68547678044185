import React, { useCallback, useEffect, useState } from 'react';
import style from '../../style/components/signin/RequestDataModal.module.scss';
import LabelSelect from '../LabelSelect';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDataDownloadFile,
  fetchDataDownloadsStatus,
} from '../../actions/dataDownloads';
import IndicatesRequiredField from '../IndicatesRequiredField';
import StyledModal from '../StyledModal';
import { fetchCountries } from '../../actions/countries';
import { fetchStates } from '../../actions/states';
import { isEmpty } from '../../utils/isEmpty';

function initialFormData() {
  return {
    country: '',
    state: '',
  };
}

function validate(formData) {
  const errors = {};

  if (isEmpty(formData.country)) {
    errors.country = 'Country is required';
  }

  // state required for US country
  if (formData.country === 'US' && isEmpty(formData.state)) {
    errors.state = 'State is required';
  }

  return errors;
}

const RequestDataModal = ({ closeModal, isOpen }) => {
  const dispatch = useDispatch();
  const dataDownloads = useSelector((state) => state.dataDownloads);
  const countryData = useSelector((state) => state.countries);
  const stateData = useSelector((state) => state.states);

  useEffect(() => {
    if (
      isOpen &&
      !countryData.error &&
      !countryData.isFetching &&
      !countryData.countries.length
    ) {
      dispatch(fetchCountries());
    }
  }, [isOpen, dispatch, countryData]);

  const [errors, setErrors] = useState(false);
  const [formData, setFormData] = useState(initialFormData());

  useEffect(() => {
    // Only fetch states for US country selection
    if (
      isOpen &&
      !stateData.error &&
      !stateData.isFetching &&
      !stateData.states.length &&
      formData.country === 'US'
    ) {
      dispatch(fetchStates());
    }
  }, [isOpen, dispatch, stateData, formData.country]);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const newErrors = validate(formData);
      setErrors(newErrors);

      if (!Object.keys(newErrors).length) {
        dispatch(fetchDataDownloadFile(formData));
      }
    },
    [dispatch, formData, setErrors],
  );

  useEffect(() => {
    // when successful response: close modal and fetch status
    if (isOpen && dataDownloads.isRequestSuccess) {
      dispatch(fetchDataDownloadsStatus());
      closeModal();
    }
  }, [isOpen, dataDownloads, dispatch, closeModal]);

  const submitting = dataDownloads.isFetching;

  return (
    <StyledModal
      isOpen={isOpen}
      closeModal={closeModal}
      submitting={submitting}
      title="Where do you currently live?"
      primaryButton={{
        text: 'Download my data',
        submittingText: 'Downloading...',
      }}
      wrapper={(children) => (
        <form onSubmit={handleSubmit} noValidate={true}>
          {children}
        </form>
      )}
    >
      <p>
        Share your country and state to download a copy of your personal data.
      </p>
      <IndicatesRequiredField />

      <div className={style.inputGroup}>
        <div className={style.fullWidth}>
          <LabelSelect
            label="Country"
            name="country"
            placeholder="Select a country"
            error={errors.country}
            value={formData.country}
            onChange={handleChange}
            options={countryData.countries.map((c) => ({
              label: c.name,
              value: c.code,
            }))}
            disabled={submitting || null}
            noMargin
            required
          />
        </div>
      </div>
      {formData.country === 'US' ? (
        <div className={style.inputGroup}>
          <div className={style.fullWidth}>
            <LabelSelect
              label="State"
              name="state"
              placeholder="Select a state"
              error={errors.state}
              value={formData.state}
              onChange={handleChange}
              options={stateData.states.map((s) => ({
                label: s.name,
                value: s.code,
              }))}
              disabled={submitting || null}
              noMargin
              required
            />
          </div>
        </div>
      ) : null}
    </StyledModal>
  );
};

export default RequestDataModal;
