import React from 'react';
import Modal from 'react-modal';
import ButtonSecondary from '../ButtonSecondary';
import ButtonErrorSolid from '../ButtonErrorSolid';
import DeleteIcon from '../../img/icons/website-delete.svg';
import style from '../../style/components/signin/ConsentDeleteModal.module.scss';

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

const ConsentDeleteModal = ({ org, handleCancel, handleConfirm, isOpen }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={handleCancel}
    className={style.modal}
    overlayClassName={style.overlay}
  >
    <h3 className={style.header}>
      <img alt="Remove Website" src={DeleteIcon} />
    </h3>
    <div className={style.body}>
      <h4>{`Revoke ${org}?`}</h4>
      <p>
        Are you sure you want to revoke access to your data? To receive benefits
        or offers from <strong>{org}</strong> in the future you will need to
        grant access again.
      </p>
      <div className={style.buttons}>
        <ButtonSecondary onClick={handleCancel}>Cancel</ButtonSecondary>
        <ButtonErrorSolid solid="true" onClick={handleConfirm}>
          Revoke
        </ButtonErrorSolid>
      </div>
    </div>
  </Modal>
);

export default ConsentDeleteModal;
