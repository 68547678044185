import React, { useState } from 'react';
import ChangeRequestsModal from './changeRequests/ChangeRequestsModal';

const UpdateNameButton = () => {
  const [isUpdateNameModalOpen, setIsUpdateNameModalOpen] = useState(false);

  return (
    <>
      <idme-button
        variant="tertiary"
        text="Update"
        size="small"
        onClick={() => setIsUpdateNameModalOpen(true)}
      />
      <ChangeRequestsModal
        isOpen={isUpdateNameModalOpen}
        closeModal={() => setIsUpdateNameModalOpen(false)}
      />
    </>
  );
};

export default UpdateNameButton;
