import React from 'react';
import PropTypes from 'prop-types';
import style from '../style/components/RadioButton.module.scss';

const RadioButton = ({ currentValue, children, onChange, name, value }) => (
  <label className={style.label} htmlFor={`${name}-${value}`}>
    <input
      type="radio"
      id={`${name}-${value}`}
      value={value}
      name={name}
      checked={currentValue === value}
      onChange={onChange}
    />
    <span className={style.radio} aria-hidden="true" />
    <span className={style.children}>{children}</span>
  </label>
);

RadioButton.propTypes = {
  children: PropTypes.node.isRequired,
  currentValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

RadioButton.defaultProps = {
  currentValue: '',
};

export default RadioButton;
