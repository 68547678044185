import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../ButtonLink';
import style from '../../style/components/signin/WebsiteInfoModal.module.scss';

const WebsiteInfoModal = ({
  handleModalClose,
  imgSrc,
  organization,
  properties,
}) => (
  <div className={style.body}>
    <ButtonLink onClick={handleModalClose}>X Close</ButtonLink>
    <h3>
      {imgSrc && <img alt="logo" src={imgSrc} />}
      {organization}
    </h3>
    <h4>Data You&#39;ve Shared:</h4>
    <p>
      You&#39;ve shared the following information with&nbsp;
      <strong>{organization}</strong>
    </p>
    <ul>
      {properties.map((prop) => (
        <li key={prop.handle}>{prop.name}</li>
      ))}
    </ul>
  </div>
);

WebsiteInfoModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  imgSrc: PropTypes.string,
  organization: PropTypes.string,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      handle: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

WebsiteInfoModal.defaultProps = {
  imgSrc: '',
  organization: '',
  properties: [],
};

export default WebsiteInfoModal;
