import React from 'react';
import style from '../style/components/FormInputs.module.scss';

const LabelSelect = ({
  error,
  label,
  name,
  options,
  required,
  placeholder,
  noMargin,
  ...rest
}) => (
  <label
    htmlFor={name}
    className={`${style.wrapper} ${noMargin ? style.noMargin : ''}`}
  >
    <div className={style.label}>
      {label}
      {required && <span className={style.required}>&nbsp;*</span>}
    </div>
    <select
      className={error && style.errorInput}
      id={name}
      name={name}
      required={required}
      {...rest}
    >
      {placeholder && (
        <option value="" disabled hidden>
          {placeholder}
        </option>
      )}
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    {error && (
      <div className={style.error} role="alert">
        <strong>Error: </strong> {error}
      </div>
    )}
    <idme-icon
      class={style.caret}
      color="slate"
      name="chevron-down"
      size="medium"
    />
  </label>
);

export default LabelSelect;
