import React, { useCallback } from 'react';
import StyledModal from '../StyledModal';
import { useDispatch } from 'react-redux';
import { revokeConsent } from '../../actions/linkedAccounts';

const RevokeWebsiteModal = ({ closeModal, isOpen, account, site }) => {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      dispatch(
        revokeConsent({
          id: site.id,
        }),
      );

      closeModal();
    },
    [dispatch, site, closeModal],
  );

  return (
    <StyledModal
      isOpen={isOpen}
      closeModal={closeModal}
      title={
        'Revoke ' + account.name + "'s access to " + site.organization + '?'
      }
      primaryButton={{
        text: 'Revoke',
        action: handleSubmit,
      }}
    >
      <p>
        For {account.name} ({account.email}) to receive benefits or offers from{' '}
        <b>{site.organization}</b> in the future, they will need to request
        access on the ID.me partner's site.
      </p>
    </StyledModal>
  );
};

export default RevokeWebsiteModal;
