import {
  FETCH_UPDATE_PASSWORD_BEGIN,
  FETCH_UPDATE_PASSWORD_SUCCESS,
  FETCH_UPDATE_PASSWORD_FAILURE,
  PASSWORD_CLEAR_ERROR,
} from '../actions/password';

export const initialState = {
  error: [],
  instruction: 'Choose a unique password to protect your account',
  isFetching: false,
};

const password = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_UPDATE_PASSWORD_BEGIN:
      return {
        ...state,
        error: [],
        isFetching: true,
      };
    case FETCH_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        error: [],
        instruction: 'Password updated successfully',
        isFetching: false,
      };
    case FETCH_UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
        instruction: 'Password update failed',
        isFetching: false,
      };
    case PASSWORD_CLEAR_ERROR:
      return {
        ...state,
        error: [],
        instruction: 'Choose a unique password to protect your account',
      };
    default:
      return state;
  }
};

export default password;
