import React, { useCallback } from 'react';

const LoginInactive = ({ alt, className, handleAdd, logo, name, url }) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      handleAdd(url);
    },
    [handleAdd, url],
  );

  return (
    <a className={className} href={url} onClick={handleClick}>
      <img alt="" src={logo} />
      <span>{name}</span>
    </a>
  );
};

export default LoginInactive;
