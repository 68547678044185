import axios from 'axios';
import { URL_REGION_COUNTRIES } from '../constants/urls';

export const FETCH_COUNTRIES_BEGIN = 'FETCH_COUNTRIES_BEGIN';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE';

export const fetchCountries = () => async (dispatch) => {
  dispatch({ type: FETCH_COUNTRIES_BEGIN });

  try {
    const response = await axios.get(URL_REGION_COUNTRIES);

    dispatch({
      payload: response.data,
      type: FETCH_COUNTRIES_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error && error.response && error.response.statusText,
      type: FETCH_COUNTRIES_FAILURE,
    });
  }
};
