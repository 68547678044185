import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchFactors } from '../actions/twofa';
import { DOMAIN } from '../constants';
import style from '../style/components/signin/TwoFArow.module.scss';

export class TwoFAManager extends Component {
  componentDidMount() {
    const { actionFetchFactors } = this.props;
    actionFetchFactors();

    window.addEventListener('message', this.handleMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
  }

  handleMessage = (e) => {
    const { actionFetchFactors } = this.props;
    if (e && e.data && e.data.status === 'complete') {
      actionFetchFactors();
      try {
        e.source &&
          e.source.postMessage &&
          e.source.postMessage({ status: 'close' }, DOMAIN);
      } catch (e) {
        // fail silently
        // TODO: log or handle error
      }
    }
  };

  render() {
    const { totalEnrolled } = this.props;

    return (
      !!totalEnrolled && (
        <h4 className={style.pre}>Your Multi-Factor Sign in Method</h4>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  totalEnrolled: state.twofa.totalEnrolled,
});

const mapDispatchToProps = {
  actionFetchFactors: fetchFactors,
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFAManager);
