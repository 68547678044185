import React from 'react';
import style from '../../style/components/profile/UpdateWorkProfileModal.module.scss';
import InputWithError from '../InputWithError';
import LabelSelect from '../LabelSelect';
import Checkbox from '../Checkbox';
import PhoneNumberInput from '../PhoneNumberInput';
import { LINETYPES, PERSONAL, WORK } from '../../constants';
import { useSelector } from 'react-redux';
import { sortedEmails } from '../../utils/email';
import ToastNotification from '../ToastNotification';

const ContactInformationFormSection = ({
  formData,
  setFormData,
  errors,
  submitting,
  isWork,
  setIsNumberValid,
  handleChange,
}) => {
  const emails = useSelector((state) => state.emails.emails);

  const emailOptions = sortedEmails(emails)
    .filter((email) => email.isVerified)
    .map((email) => ({ value: email.id, label: email.address }));

  const selectedEmail = emails.find(
    (email) => email.id.toString() === formData.email_id,
  );

  return (
    <section>
      <h4>Contact Information</h4>
      <LabelSelect
        label="Work Email Address"
        name="email_id"
        placeholder="Select a work email"
        error={errors.email_id}
        value={formData.email_id}
        onChange={handleChange}
        options={emailOptions}
        required
        disabled={submitting}
      />

      {selectedEmail && !selectedEmail.emailTypes.includes(WORK) ? (
        <ToastNotification type="info">
          <span className={style.semibold}>{selectedEmail.address}</span>'s
          email type will be changed to{' '}
          {selectedEmail.emailTypes.includes(PERSONAL)
            ? '"Personal" and "Work"'
            : '"Work"'}
        </ToastNotification>
      ) : null}

      <PhoneNumberInput
        initialValue={formData.number}
        label={isWork ? 'Work Phone Number' : 'Phone Number'}
        onChangeNumber={(number) => {
          setFormData({ ...formData, number: number });
        }}
        onChangeValidity={(valid) => {
          setIsNumberValid(valid);
        }}
        error={errors.number}
        disabled={submitting}
      />

      <Checkbox
        id="hasExtension"
        onChange={handleChange}
        checked={formData.hasExtension}
        name="hasExtension"
      >
        This phone number has an extension
      </Checkbox>

      {formData.hasExtension && (
        <div className={style.secondaryInput}>
          <InputWithError
            name="extension"
            label="Extension"
            placeholder="Enter extension"
            error={errors.extension}
            value={formData.extension}
            onChange={handleChange}
            type="number"
            required
            disabled={submitting}
          />
        </div>
      )}

      <div className={style.secondaryInput}>
        <LabelSelect
          label="Type"
          name="linetype"
          placeholder="Select a type"
          error={errors.linetype}
          value={formData.linetype}
          onChange={handleChange}
          options={LINETYPES}
          noMargin
          disabled={submitting}
        />
      </div>
    </section>
  );
};

export default ContactInformationFormSection;
