/**
 * Formats a phone number string into a specific format.
 * @param {string} phoneString - The phone number string to format.
 * @returns {string} The formatted phone number.
 */
export const formatPhone = (originalPhoneString) => {
  if (!originalPhoneString) {
    return '';
  }

  // remove all non-numeric characters
  const parsedPhoneString = originalPhoneString.replace(/\D/g, '');

  if (parsedPhoneString.length === 11) {
    // assumes US 11 digit phone number
    const startsWithOne = parsedPhoneString[0] === '1';
    if (startsWithOne) {
      const first = parsedPhoneString.substring(1, 4);
      const middle = parsedPhoneString.substring(4, 7);
      const last = parsedPhoneString.substring(7);
      return `+1 (${first}) ${middle}-${last}`;
    }
  }
  if (parsedPhoneString.length === 10) {
    // assumes US 10 digit phone number
    const first = parsedPhoneString.substring(0, 3);
    const middle = parsedPhoneString.substring(3, 6);
    const last = parsedPhoneString.substring(6);
    return `(${first}) ${middle}-${last}`;
  }
  return originalPhoneString;
};

/**
 * Formats a line type string by capitalizing the first letter.
 * @param {string} lineType - The line type string to format.
 * @returns {string} The formatted line type.
 */
export const lineType = (lineType) => {
  // guard for null or undefined
  if (!lineType) {
    return '';
  }

  // return line type with first letter capitalized
  return lineType.charAt(0).toUpperCase() + lineType.slice(1);
};

/**
 * Checks if a phone number is valid.
 * @param {string} phoneNumber - The phone number to validate.
 * @returns {boolean} Returns true if the phone number is valid, otherwise false.
 */
export const isValidPhoneNumber = (phoneNumber) => {
  return /^\+\d{10,13}$/.test(phoneNumber);
};

/**
 * Checks if an extension is valid.
 * @param {string} value - The extension to validate.
 * @returns {boolean} Returns true if the extension is valid, otherwise false.
 */
export const isValidExtension = (value) => {
  // ensure positive number
  return /^\d{1,10}$/.test(value);
};
