import React from 'react';
import PropTypes from 'prop-types';
import StyledModal from '../StyledModal';

const AccountDeleteConfirmModal = ({ isOpen, closeModal }) => {
  return (
    <StyledModal
      isOpen={isOpen}
      closeModal={() => {
        closeModal();

        // go to root page to cause user to be signed out
        window.location.href = '/';
      }}
      title="Account closed"
      cancelButton={{ text: 'Close' }}
    >
      <p>
        Your request to deactivate your account was received and will be
        processed shortly.
      </p>
    </StyledModal>
  );
};

AccountDeleteConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default AccountDeleteConfirmModal;
