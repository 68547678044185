import {
  FETCH_SELFIE_STATUS_BEGIN,
  FETCH_SELFIE_STATUS_SUCCESS,
  FETCH_SELFIE_STATUS_FAILURE,
  DELETE_SELFIE_BEGIN,
  DELETE_SELFIE_SUCCESS,
  DELETE_SELFIE_FAILURE,
} from '../actions/dataRequests';

export const initialState = {
  isFetching: false,
  selfie: false,
  selfieDeleteSuccess: null,
};

const dataRequests = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_SELFIE_STATUS_BEGIN:
    case DELETE_SELFIE_BEGIN:
      return {
        ...state,
        isFetching: true,
        selfieDeleteSuccess: null,
      };
    case FETCH_SELFIE_STATUS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        isFetching: false,
      };
    case DELETE_SELFIE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        isFetching: false,
        selfieDeleteSuccess: true,
      };
    case FETCH_SELFIE_STATUS_FAILURE:
    case DELETE_SELFIE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        selfieDeleteSuccess: false,
      };
    default:
      return state;
  }
};

export default dataRequests;
