import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { IdH3 } from '../IdHead';
import { popup } from '../../utils/popup';
import style from '../../style/components/wallet/Inactive.module.scss';

const Inactive = ({ isMinor, color, credential_icon_url, title, url }) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      popup('id', url);
    },
    [url],
  );

  return (
    <div className={style.id}>
      <IdH3 color={color} iconUrl={credential_icon_url} title={title} />
      {isMinor ? (
        <div className={style.idBody}>Unavailable</div>
      ) : (
        <div className={style.idBody}>
          <a href={url} onClick={handleClick}>
            Add
          </a>
        </div>
      )}
    </div>
  );
};

Inactive.propTypes = {
  type: PropTypes.string.isRequired,
};

export default memo(Inactive);
