import axios from 'axios';
import { URL_PROFILE } from '../constants/urls';

export const FETCH_USER_BEGIN = 'FETCH_USER_BEGIN';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const fetchUser = () => async (dispatch) => {
  dispatch({ type: FETCH_USER_BEGIN });
  try {
    const response = await axios.get(URL_PROFILE);

    // Set User UUID for Qualtrics
    window._qUUID = response.data.uuid;

    dispatch({
      payload: response.data,
      type: FETCH_USER_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_USER_FAILURE,
    });
  }
};
