import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserProps from '../proptypes/user';
import { MMMMYYYY } from '../utils/time';

const User = ({ hideLink, style, user }) => (
  <div className={style.right}>
    <div className={style.bigFirstName}>{user.fname}</div>
    <div className={style.bigLastName}>{user.lname}</div>
    <div className={style.memberSince}>
      Member Since&nbsp;
      {MMMMYYYY(user.date)}
    </div>
    <div className={style.email}>
      {user.email}
      {!hideLink && <Link to="/profile">Manage Profile</Link>}
    </div>
  </div>
);

User.propTypes = {
  hideLink: PropTypes.bool,
  style: PropTypes.shape({
    bigFirstName: PropTypes.string,
    bigLastName: PropTypes.string,
    email: PropTypes.string,
    memberSince: PropTypes.string,
    right: PropTypes.string,
  }).isRequired,
  user: UserProps.isRequired,
};

User.defaultProps = {
  hideLink: false,
};

export default memo(User);
