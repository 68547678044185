import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchDataLockStatus } from '../actions/california';
import DataLocked from '../components/profile/DataLocked';

class ProfileDataLocked extends Component {
  componentDidMount() {
    const { actionFetchDataLockStatus } = this.props;
    actionFetchDataLockStatus();
  }

  render() {
    const { isLocked } = this.props;
    if (isLocked) {
      return <DataLocked />;
    }
    return null;
  }
}

const mapStateToProps = (state) => ({ ...state.california });

const mapDispatchToProps = {
  actionFetchDataLockStatus: fetchDataLockStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDataLocked);
