import React, { useCallback, useEffect, useState } from 'react';
import style from '../../style/components/profile/EditEmailModal.module.scss';
import ErrorAlertContainer from '../ErrorAlertContainer';
import IndicatesRequiredField from '../IndicatesRequiredField';
import StyledModal from '../StyledModal';
import CheckboxGroup from '../CheckboxGroup';
import { useDispatch, useSelector } from 'react-redux';
import { resetEmailState, updateEmail } from '../../actions/emails';

function validate(emailTypes) {
  const errors = {};

  // ensure at least one emailType is active
  if (!emailTypes.some((emailType) => emailType.active)) {
    errors.types = 'Email type is required';
  }

  return errors;
}

function initialEmailTypes(emailTypes) {
  return [
    { value: 'personal', label: 'Personal', active: false },
    { value: 'work', label: 'Work', active: false },
  ].map((emailType) => {
    if (emailTypes.includes(emailType.value)) {
      return { ...emailType, active: true };
    }
    return emailType;
  });
}

const EditEmailModal = ({ email, closeModal, isOpen }) => {
  const dispatch = useDispatch();

  const emails = useSelector((state) => state.emails);

  const [errors, setErrors] = useState({});
  const [emailTypes, setEmailTypes] = useState(
    initialEmailTypes(email.emailTypes),
  );

  const toggleEmailType = (type) => {
    setEmailTypes(
      emailTypes.map((emailType) => {
        if (emailType.value === type) {
          return { ...emailType, active: !emailType.active };
        }
        return emailType;
      }),
    );
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const newErrors = validate(emailTypes);
      setErrors(newErrors);

      if (!Object.keys(newErrors).length) {
        const resultTypes = emailTypes
          .filter((emailType) => emailType.active)
          .map((emailType) => emailType.value);

        dispatch(updateEmail(email, resultTypes));
      }
    },
    [dispatch, email, emailTypes, setErrors],
  );

  useEffect(() => {
    // when successful response: close modal and refresh data
    if (emails.isSuccess) {
      dispatch(resetEmailState());
      closeModal();
    }
  }, [emails, dispatch, closeModal]);

  const submitting = emails.isFetching;

  return (
    <StyledModal
      isOpen={isOpen}
      closeModal={closeModal}
      submitting={submitting}
      title={`Editing ${email.address}`}
      primaryButton={{
        hidden: false,
        text: 'Save',
        submittingText: 'Saving...',
      }}
      wrapper={(children) => (
        <form
          onSubmit={handleSubmit}
          noValidate={true}
          className={style.wrapper}
        >
          {children}
        </form>
      )}
    >
      <IndicatesRequiredField />
      <CheckboxGroup
        label="Email Type"
        name="types"
        options={emailTypes}
        toggleCheckbox={toggleEmailType}
        error={errors['types']}
        required
      />
      <ErrorAlertContainer />
    </StyledModal>
  );
};

export default EditEmailModal;
