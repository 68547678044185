import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import DarkCancelIcon from '../img/icons/cancel-dark.svg';
import logo from '../img/my-account.svg';
import DarkHamburgerIcon from '../img/icons/hamburger-dark.svg';
import style from '../style/components/NavTabs.module.scss';

class MobileNav extends React.Component {
  state = {
    isOpen: false,
  };

  buttonRef = React.createRef();

  componentDidMount() {
    document.addEventListener('click', this.handleClickAnywhere);
    document.addEventListener('touchmove', this.handleScroll, {
      passive: false,
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickAnywhere);
    document.removeEventListener('touchmove', this.handleScroll, {
      passive: false,
    });
  }

  toggleMenu = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleClickAnywhere = (event) => {
    const { isOpen } = this.state;
    if (event && isOpen && !this.buttonRef.current.contains(event.target)) {
      event.preventDefault();
      this.setState({ isOpen: false });
    }
  };

  handleScroll = (event) => {
    const { isOpen } = this.state;
    if (isOpen) {
      event.preventDefault();
    }
  };

  render() {
    const { children, currentPage } = this.props;
    const { isOpen } = this.state;
    const navClass = isOpen ? style.navOpen : style.navClosed;

    return (
      <nav className={`${navClass}`}>
        <h1 className={style.accountHeading}>
          <NavLink to="/overview" exact className={style.brand}>
            <img src={logo} alt="My Account" />
          </NavLink>
        </h1>
        <h2 aria-level={1} className={style.mobileTitle}>
          {currentPage}
          <button onClick={this.toggleMenu} type="button" ref={this.buttonRef}>
            {isOpen ? (
              <img src={DarkCancelIcon} alt="close" />
            ) : (
              <img src={DarkHamburgerIcon} alt="open menu" />
            )}
          </button>
        </h2>
        <ul className={isOpen ? style.open : undefined}>{children}</ul>
      </nav>
    );
  }
}

MobileNav.propTypes = {
  children: PropTypes.node.isRequired,
  currentPage: PropTypes.string.isRequired,
};

export default MobileNav;
