import {
  FETCH_LINKED_ACCOUNTS_BEGIN,
  FETCH_LINKED_ACCOUNTS_SUCCESS,
  FETCH_LINKED_ACCOUNTS_FAILURE,
} from '../actions/linkedAccounts';

export const initialState = {
  accounts: [],
  error: null,
  isFetching: false,
  linkageRequests: [],
};

const linkedAccounts = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_LINKED_ACCOUNTS_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_LINKED_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: action.payload.linked_accounts,
        error: null,
        isFetching: false,
        linkageRequests: action.payload.linkage_requests,
      };
    case FETCH_LINKED_ACCOUNTS_FAILURE:
      return {
        ...state,
        accounts: [],
        linkageRequests: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default linkedAccounts;
