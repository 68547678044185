import React from 'react';
import Emails from '../containers/ProfileEmails';
import StyledModal from './StyledModal';

const EmailsModal = ({ isOpen, handleCancel }) => (
  <StyledModal
    isOpen={isOpen}
    closeModal={handleCancel}
    title="Update your email address"
    wideModal={true}
  >
    <Emails />
  </StyledModal>
);
export default EmailsModal;
