import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DEFAULT_ACTIVITY_TYPE, fetchActivities } from '../actions/activities';
import AccountActivities from '../components/signin/AccountActivities';
import useOnScreen from '../hooks/useOnScreen';

const SigninPrivacyActivity = () => {
  const [activeTab, setActiveTab] = useState({
    key: DEFAULT_ACTIVITY_TYPE,
    name: 'All',
  });
  const dispatch = useDispatch();

  const activitiesState = useSelector((state) => state.activities);
  const { complete } = activitiesState;

  // Ref for the element we want to observe
  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);

  const [hasRequestedActivity, setHasRequestedActivity] = useState(false);

  useEffect(() => {
    // Fetch all activities when the component is in view and we haven't requested activities yet
    if (isOnScreen && !hasRequestedActivity) {
      setHasRequestedActivity(true);
      dispatch(fetchActivities(DEFAULT_ACTIVITY_TYPE));
    }
  }, [dispatch, hasRequestedActivity, setHasRequestedActivity, isOnScreen]);

  const handleLoadMore = async () => {
    if (!activitiesState.isFetching) {
      const type = activeTab.key;
      dispatch(fetchActivities(type));
    }
  };

  const handleTabChange = async (tab) => {
    setActiveTab(tab);
    const type = tab.key;

    // when changing tabs, fetch activities if we haven't fetched any for that tab
    if (activitiesState.page[type] === 0) {
      dispatch(fetchActivities(type));
    }
  };

  const filteredActivities = activeTab ? activitiesState[activeTab.key] : [];

  return (
    <div ref={elementRef}>
      <AccountActivities
        activeTab={activeTab}
        activities={filteredActivities}
        complete={complete[activeTab.key]}
        handleLoadMore={handleLoadMore}
        handleTabChange={handleTabChange}
        isFetching={activitiesState.isFetching}
      />
    </div>
  );
};

SigninPrivacyActivity.propTypes = {};

SigninPrivacyActivity.defaultProps = {};

export default withRouter(SigninPrivacyActivity);
