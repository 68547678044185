import axios from 'axios';
import { URL_CHANGE_REQUESTS } from '../constants/urls';

export const FETCH_CHANGE_REQUESTS_BEGIN = 'FETCH_CHANGE_REQUESTS_BEGIN';
export const FETCH_CHANGE_REQUESTS_SUCCESS = 'FETCH_CHANGE_REQUESTS_SUCCESS';
export const FETCH_CHANGE_REQUESTS_FAILURE = 'FETCH_CHANGE_REQUESTS_FAILURE';

export const fetchChangeRequests = () => async (dispatch) => {
  dispatch({ type: FETCH_CHANGE_REQUESTS_BEGIN });

  try {
    const response = await axios.get(URL_CHANGE_REQUESTS);
    let data = response.data || {};

    dispatch({
      payload: data,
      type: FETCH_CHANGE_REQUESTS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_CHANGE_REQUESTS_FAILURE,
    });
  }
};
