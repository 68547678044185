import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import NavTabs from './NavTabs';
import GlobalNav from './GlobalNav';
import UserProps from '../proptypes/user';
import Alerts from '../containers/Alerts';

const NavBar = ({ isLoggedIn, user }) => {
  return (
    <Fragment>
      {!!isLoggedIn && <Alerts />}
      <GlobalNav user={user} />
      <NavTabs isLoggedIn={isLoggedIn} />
    </Fragment>
  );
};

NavBar.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  user: UserProps.isRequired,
};

export default NavBar;
