import {
  FETCH_SUPPORT_REQUEST_FIELDS_BEGIN,
  FETCH_SUPPORT_REQUEST_FIELDS_SUCCESS,
  FETCH_SUPPORT_REQUEST_FIELDS_FAILURE,
  FETCH_HELP_BEGIN,
  FETCH_HELP_SUCCESS,
  FETCH_HELP_FAILURE,
  FETCH_CREATE_HELP_BEGIN,
  FETCH_CREATE_HELP_SUCCESS,
  FETCH_CREATE_HELP_FAILURE,
} from '../actions/help';

export const initialState = {
  error: null,
  helpRequests: [],
  isFetching: false,
  needed: [],
  ticketFields: {},
};

const help = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_SUPPORT_REQUEST_FIELDS_BEGIN:
    case FETCH_HELP_BEGIN:
    case FETCH_CREATE_HELP_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_SUPPORT_REQUEST_FIELDS_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        ticketFields: action.payload,
      };
    case FETCH_HELP_SUCCESS:
      return {
        ...state,
        error: null,
        helpRequests: action.payload,
        isFetching: false,
      };
    case FETCH_CREATE_HELP_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        isFetching: false,
      };
    case FETCH_SUPPORT_REQUEST_FIELDS_FAILURE:
    case FETCH_HELP_FAILURE:
    case FETCH_CREATE_HELP_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default help;
