import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import HelpRequests from '../containers/HelpRequests';
import HelpRequestNew from '../containers/HelpRequestNew';
import Four04 from './Four04';
import SideNav from '../components/SideNav';
import style from '../style/pages/Help.module.scss';
import ErrorAlertContainer from '../components/ErrorAlertContainer';
import { getReactEnv } from '../constants';

const routes = [
  { children: 'Request Help', to: '/help/contact' },
  { children: 'My Open Tickets', to: '/help/tickets' },
];

const Help = ({ location = {} }) => {
  useEffect(() => {
    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  return (
    <div className={style.wrapper}>
      <SideNav routes={routes} current={location.pathname} />
      <main className={style.main}>
        <ErrorAlertContainer />
        <Switch>
          <Route path="/help/contact" component={HelpRequestNew} />
          <Route path="/help/tickets" exact component={HelpRequests} />
          <Route component={Four04} />
        </Switch>
      </main>
    </div>
  );
};

export default Help;
