import React from 'react';
import style from '../style/components/FormInputs.module.scss';

const LabelTextArea = ({ error, label, name, required, ...rest }) => (
  <label htmlFor={name} className={style.wrapper}>
    <div className={style.label}>
      {label}
      {required && <span className={style.required}>&nbsp;*</span>}
    </div>

    <textarea
      className={error && style.errorInput}
      id={name}
      name={name}
      {...rest}
    />
    {error && (
      <div className={style.error} role="alert">
        <strong>Error: </strong>
        {error}
      </div>
    )}
  </label>
);

export default LabelTextArea;
