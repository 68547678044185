import axios from 'axios';
import { URL_WORK_PROFILE } from '../constants/urls';

export const FETCH_WORK_PROFILE_BEGIN = 'FETCH_WORK_PROFILE_BEGIN';
export const FETCH_WORK_PROFILE_SUCCESS = 'FETCH_WORK_PROFILE_SUCCESS';
export const FETCH_WORK_PROFILE_FAILURE = 'FETCH_WORK_PROFILE_FAILURE';

export const UPDATE_WORK_PROFILE_BEGIN = 'UPDATE_WORK_PROFILE_BEGIN';
export const UPDATE_WORK_PROFILE_SUCCESS = 'UPDATE_WORK_PROFILE_SUCCESS';
export const UPDATE_WORK_PROFILE_FAILURE = 'UPDATE_WORK_PROFILE_FAILURE';
export const UPDATE_WORK_PROFILE_CLEAR = 'UPDATE_WORK_PROFILE_CLEAR';

export const clearUpdateWorkProfile = () => (dispatch) => {
  dispatch({ type: UPDATE_WORK_PROFILE_CLEAR });
};

export const fetchWorkProfiles = () => async (dispatch) => {
  dispatch({ type: FETCH_WORK_PROFILE_BEGIN });

  try {
    const response = await axios.get(URL_WORK_PROFILE);
    let data = response.data || {};

    dispatch({
      payload: data,
      type: FETCH_WORK_PROFILE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error,
      type: FETCH_WORK_PROFILE_FAILURE,
    });
  }
};

export const updateWorkProfile = (id, attributes) => async (dispatch) => {
  dispatch({ type: UPDATE_WORK_PROFILE_BEGIN });

  try {
    const response = await axios.put(`${URL_WORK_PROFILE}/${id}`, attributes);
    let data = response.data || {};

    dispatch({
      payload: data,
      type: UPDATE_WORK_PROFILE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error,
      type: UPDATE_WORK_PROFILE_FAILURE,
    });
  }
};
