import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Email from './Email';
import style from '../../style/components/profile/Emails.module.scss';
import { useSelector } from 'react-redux';
import Card from '../Card';
import { sortedEmails } from '../../utils/email';
import AddEmailModal from './AddEmailModal';

const Emails = ({
  emails,
  handleDelete,
  handlePrimary,
  handleResend,
  isFetching,
}) => {
  const emailTypeEnabled = useSelector(
    (state) => state.featureFlags.emailTypeEnabled,
  );

  const workProfiles = useSelector((state) => state.workProfile.profiles);

  const [isAddEmailModalOpen, setIsAddEmailModalOpen] = useState(false);

  return (
    <Card
      title="Email"
      icon={<idme-icon name="mail" color="stone" size="large" />}
      className={style.wrapper}
      id="emailSection"
    >
      <ul>
        {sortedEmails(emails).map((email, index) => (
          <Email
            key={email.address}
            index={index}
            email={email}
            workProfiles={workProfiles.filter(
              (workProfile) => workProfile.email?.id === email.id,
            )}
            emailTypeEnabled={emailTypeEnabled}
            handlePrimary={handlePrimary}
            handleDelete={handleDelete}
            handleResend={handleResend}
            isFetching={isFetching}
          />
        ))}
      </ul>

      <div className={style.add}>
        <idme-button
          variant="secondary"
          size="small"
          text="Add an email"
          layout="content"
          type="button"
          onClick={() => setIsAddEmailModalOpen(true)}
          disabled={isFetching || null}
        ></idme-button>
      </div>

      {isAddEmailModalOpen && (
        <AddEmailModal
          closeModal={() => setIsAddEmailModalOpen(false)}
          isOpen={isAddEmailModalOpen}
        />
      )}
    </Card>
  );
};

Emails.propTypes = {
  emails: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
    }),
  ),
  handleDelete: PropTypes.func.isRequired,
  handlePrimary: PropTypes.func.isRequired,
  handleResend: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

Emails.defaultProps = {
  emails: [],
};

export default Emails;
