import {
  FETCH_WORK_PROFILE_BEGIN,
  FETCH_WORK_PROFILE_FAILURE,
  FETCH_WORK_PROFILE_SUCCESS,
  UPDATE_WORK_PROFILE_BEGIN,
  UPDATE_WORK_PROFILE_CLEAR,
  UPDATE_WORK_PROFILE_FAILURE,
  UPDATE_WORK_PROFILE_SUCCESS,
} from '../actions/workProfile';

export const initialState = {
  error: null,
  isFetching: false,
  profiles: [],
};

const workProfile = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_WORK_PROFILE_BEGIN:
    case UPDATE_WORK_PROFILE_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
        isUpdateSuccess: null,
      };
    case FETCH_WORK_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        profiles: action.payload,
      };
    case UPDATE_WORK_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        profiles: action.payload,
        isUpdateSuccess: true,
      };
    case FETCH_WORK_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case UPDATE_WORK_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        isUpdateSuccess: false,
      };
    case UPDATE_WORK_PROFILE_CLEAR:
      return {
        ...state,
        error: null,
        isFetching: false,
        isUpdateSuccess: null,
      };
    default:
      return state;
  }
};

export default workProfile;
