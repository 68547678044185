import React, { memo } from 'react';
import PropTypes from 'prop-types';
import style from '../style/components/Card.module.scss';

const Card = ({ children, title, icon, className, ...props }) => (
  <section className={`${style.card} ${className || ''}`} {...props}>
    {title ? (
      <h3 className={style.title}>
        <span className={style.icon}>{icon}</span>
        {title}
      </h3>
    ) : null}
    {children}
  </section>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.node,
};

export default memo(Card);
