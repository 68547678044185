import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { cameFromRedirect } from '../utils/redirect';
import style from '../style/pages/Home.module.scss';
import i18n from '../i18n';
import { getReactEnv } from '../constants';

const Home = ({ isLoggedIn, history }) => {
  useEffect(() => {
    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  useEffect(() => {
    let url = cameFromRedirect();

    if (url === '/' || url === '/' + i18n.language) {
      url = null;
    }
    history.replace(url || '/overview');
  }, [isLoggedIn, history]);

  return (
    <main className={style.main}>
      <LoadingSpinner />
    </main>
  );
};

export const mapStateToProps = (state) => ({ ...state.session });

export default connect(mapStateToProps)(Home);
