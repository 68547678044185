import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFocus from '../../hooks/useFocus';
import style from '../../style/components/changeRequests/UpdateAddressModal.module.scss';
import InputWithError from '../InputWithError';
import LabelSelect from '../LabelSelect';
import { updateAddress, clearUpdateAddress } from '../../actions/addresses';
import { fetchUser } from '../../actions';
import ErrorAlertContainer from '../ErrorAlertContainer';
import IndicatesRequiredField from '../IndicatesRequiredField';
import SmartyStreetsAutocomplete from './SmartyStreetsAutocomplete';
import StyledModal from '../StyledModal';
import { fetchStates } from '../../actions/states';
import { isEmpty } from '../../utils/isEmpty';
import { isValidUSZip } from '../../utils/address';

function validate(formData) {
  const errors = {};

  if (isEmpty(formData.street)) {
    errors.street = 'Street address is required';
  }

  if (isEmpty(formData.city)) {
    errors.city = 'City is required';
  }

  if (isEmpty(formData.state)) {
    errors.state = 'State is required';
  }

  if (isEmpty(formData.zip)) {
    errors.zip = 'ZIP Code is required';
  } else if (!isValidUSZip(formData.zip)) {
    errors.zip = 'Invalid ZIP Code';
  }

  return errors;
}

function initialFormData() {
  return {
    city: '',
    state: '',
    street: '',
    street2: '',
    zip: '',
  };
}

const UpdateAddressModal = ({ closeModal, isOpen, openSuccessModal }) => {
  const dispatch = useDispatch();
  const addresses = useSelector((state) => state.addresses);

  const [errors, setErrors] = useState(false);
  const [formData, setFormData] = useState(initialFormData());

  const stateData = useSelector((state) => state.states);

  useEffect(() => {
    if (
      isOpen &&
      !stateData.error &&
      !stateData.isFetching &&
      !stateData.states.length
    ) {
      dispatch(fetchStates());
    }
  }, [isOpen, dispatch, stateData]);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const newErrors = validate(formData);
      setErrors(newErrors);

      if (!Object.keys(newErrors).length) {
        dispatch(updateAddress(formData));
      }
    },
    [dispatch, formData, setErrors],
  );

  useEffect(() => {
    // when successful response: close modal, refresh data, and open success modal
    if (addresses.isUpdateSuccess) {
      dispatch(fetchUser());
      dispatch(clearUpdateAddress());
      closeModal();
      openSuccessModal();
    }
  }, [addresses, dispatch, closeModal, openSuccessModal]);

  const [zipInputRef, setZipInputFocus] = useFocus();

  const submitting = addresses.isFetching;

  return (
    <StyledModal
      isOpen={isOpen}
      closeModal={() => {
        setFormData(initialFormData());
        closeModal();
      }}
      submitting={submitting}
      title="Edit your personal address"
      primaryButton={{
        hidden: false,
        text: 'Save changes',
        submittingText: 'Saving...',
      }}
      wrapper={(children) => (
        <form onSubmit={handleSubmit} noValidate={true}>
          {children}
        </form>
      )}
    >
      <p>The address must be associated with the name on this account.</p>
      <IndicatesRequiredField />

      <div className={style.inputGroup}>
        <div className={style.fullWidth}>
          <SmartyStreetsAutocomplete
            name="street"
            label="Address Line 1"
            placeholder="Enter street address"
            error={errors.street}
            value={formData.street}
            noMargin
            required
            handleChange={handleChange}
            handleSelectSuggestion={(selectedAddressInfo) => {
              // update form with selected suggestion
              setFormData(selectedAddressInfo);

              // focus on zip field which is the last one filled out
              setZipInputFocus();
            }}
          />
        </div>
      </div>
      <div className={style.inputGroup}>
        <div className={style.fullWidth}>
          <InputWithError
            name="street2"
            label="Address Line 2"
            placeholder="Enter apt, suite, unit, building"
            error={errors.street2}
            value={formData.street2}
            onChange={handleChange}
            noMargin
          />
        </div>
      </div>

      <div className={style.inputGroup}>
        <div className={style.fullWidth}>
          <InputWithError
            name="city"
            label="City"
            placeholder="Enter city name"
            error={errors.city}
            value={formData.city}
            onChange={handleChange}
            noMargin
            required
          />
        </div>
      </div>

      <div className={style.inputGroup}>
        <div className={style.halfWidth}>
          <LabelSelect
            label="State"
            name="state"
            placeholder="Select a state"
            error={errors.state}
            value={formData.state}
            onChange={handleChange}
            options={stateData.states.map((s) => ({
              label: s.name,
              value: s.code,
            }))}
            noMargin
            required
          />
        </div>
        <div className={style.halfWidth}>
          <InputWithError
            name="zip"
            label="ZIP Code"
            placeholder="Enter ZIP code"
            error={errors.zip}
            value={formData.zip}
            onChange={handleChange}
            inputRef={zipInputRef}
            noMargin
            required
          />
        </div>
      </div>

      <ErrorAlertContainer />
    </StyledModal>
  );
};

export default UpdateAddressModal;
