import React, { useState } from 'react';
import style from '../../style/components/linkages/WebsitesGrantedAccess.module.scss';
import WebsitesRow from './WebsitesRow';
import PropTypes from 'prop-types';
import LabelSelect from '../LabelSelect';

const WebsitesGrantedAccessTable = ({ accounts }) => {
  const [selected, setSelected] = useState(accounts[0]);

  const handleSelect = (e) => {
    const id = Number(e.target.value);

    const account = accounts.find((a) => a.id === id);

    setSelected(account);
  };

  return (
    <div>
      <LabelSelect
        name="account"
        label="Linked Accounts"
        placeholder="Select an account to view for closing your account"
        value={selected.id}
        onChange={handleSelect}
        options={accounts.map((a) => ({
          value: a.id,
          label: a.name + ' (' + a.email + ')',
        }))}
        disabled={null}
        noMargin
      />
      <div>
        {selected && (!selected.consents || selected.consents.length === 0) ? (
          <div className={style.body}>
            You have not approved any requests for this account to access
            partner services.
          </div>
        ) : (
          <table className={style.table}>
            <thead>
              <tr>
                <th>WEBSITE NAME</th>
                <th>AUTHORIZED</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {selected.consents.map((site) => (
                <WebsitesRow
                  key={site.id}
                  style={style}
                  site={site}
                  account={selected}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

WebsitesGrantedAccessTable.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})),
};

WebsitesGrantedAccessTable.defaultProps = {
  accounts: [],
};

export default WebsitesGrantedAccessTable;
