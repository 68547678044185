import aarp from '../img/icons/aarp.png';
import dslogon from '../img/icons/ds-logon.png';
import myhealthevet from '../img/icons/my-health-e-vet.png';
import google from '../img/icons/google.svg';
import apple from '../img/icons/apple.svg';
import facebook from '../img/icons/facebook.svg';
import linkedin from '../img/icons/linkedin.svg';
import paypal from '../img/icons/paypal.svg';
import email from '../img/icons/email.svg';
import placeholder from '../img/placeholder.png';
import { getReactEnv } from '../constants';

export const propMap = {};

// ??
propMap.idme = {
  email: 'idme_email',
  fname: 'idme_fname',
  lname: 'idme_lname',
};

// ??
propMap.amazon = {
  email: 'amazon_email',
  fname: 'amazon_fname',
  lname: 'amazon_lname',
};

// :dslogon        => %w[dslogon_fname dslogon_lname dslogon_birth_date],
propMap.dslogon = {
  fname: 'dslogon_fname',
  lname: 'dslogon_lname',
};

// :facebook       => %w[facebook_fname facebook_lname facebook_email],
propMap.facebook = {
  email: 'facebook_email',
  fname: 'facebook_fname',
  lname: 'facebook_lname',
};

// :google         => %w[google_fname google_lname google_email],
propMap.google = {
  email: 'google_email',
  fname: 'google_fname',
  lname: 'google_lname',
};

// :linkedin       => %w[linkedin_fname linkedin_lname linkedin_email],
propMap.linkedin = {
  email: 'linkedin_email',
  fname: 'linkedin_fname',
  lname: 'linkedin_lname',
};

// :paypal         => %w[paypal_fname paypal_lname paypal_email],
propMap.paypal = {
  email: 'paypal_email',
  fname: 'paypal_fname',
  lname: 'paypal_lname',
};

// :apple         => %w[apple_fname apple_lname apple_email],
propMap.apple = {
  email: 'apple_email',
  fname: 'apple_fname',
  lname: 'apple_lname',
};

// :rxflo          => %w[rxflo_email],
propMap.rxflo = {
  email: 'rxflo_email',
};

// :practicefusion => %w[practicefusion_email],
propMap.practicefusion = {
  email: 'practicefusion_email',
};

// :aarp           => %w[aarp_fname aarp_status aarp_expiration]
propMap.aarp = {
  fname: 'aarp_fname',
};

export const defaultPropMap = {
  email: 'email',
  fname: 'fname',
  lname: 'lname',
};

export const getLoginProp = (id, key) => {
  const { properties, handle } = id;
  const property = (propMap[handle] || defaultPropMap)[key];

  const match = properties.find((p) => p.handle === property);

  if (match) {
    return match.value;
  }

  if (getReactEnv() !== 'production') {
    /* eslint-disable-next-line no-console */
    console.warn(`Login: ${id.handle} has no defined property: "${key}".`);
  }
  return undefined;
};

const logos = {
  aarp,
  apple,
  dslogon,
  facebook,
  google,
  idme: email,
  linkedin,
  myhealthevet,
  paypal,
};

export const getLoginLogo = (handle) => logos[handle] || placeholder;
