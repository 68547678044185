import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../ButtonLink';
import { MMDDYYYY, HHMM } from '../../utils/time';

class WebsiteRow extends PureComponent {
  onClickView = () => {
    const { handleModalOpen, id } = this.props;
    handleModalOpen(id);
  };

  onClickRevoke = () => {
    const { handleRevoke, ...rest } = this.props;
    handleRevoke(rest);
  };

  formatTimestamp() {
    const { timestamp } = this.props;

    const date = MMDDYYYY(timestamp);
    const time = HHMM(timestamp);

    if (time && time.length) {
      return `${date} at ${time}`.trim();
    }
    return date;
  }

  render() {
    const { organization, style } = this.props;

    return (
      <tr>
        <td title="WEBSITE NAME:">
          <div>{organization}</div>
          <ButtonLink onClick={this.onClickView}>View data shared</ButtonLink>
        </td>
        <td title="AUTHORIZED:">{this.formatTimestamp()}</td>
        <td className={style.revoke}>
          <button type="button" onClick={this.onClickRevoke}>
            Revoke Access
          </button>
        </td>
      </tr>
    );
  }
}

WebsiteRow.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.string),
  handleModalOpen: PropTypes.func.isRequired,
  handleRevoke: PropTypes.func.isRequired,
  id: PropTypes.number,
  organization: PropTypes.string,
  style: PropTypes.shape({}).isRequired,
  timestamp: PropTypes.string,
};

WebsiteRow.defaultProps = {
  groups: [''],
  id: -1,
  organization: '',
  timestamp: '',
};

export default WebsiteRow;
