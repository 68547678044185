import React from 'react';
import ActivityRow from './ActivityRow';
import style from '../../style/components/StyledTable.module.scss';
import LoadingSpinner from '../LoadingSpinner';

const AccountActivitiesTable = ({ activities, activeTab, isFetching }) => (
  <table className={style.table}>
    <thead>
      <tr>
        <th>TIME STAMP</th>
        <th>ACTIVITY</th>
        <th>DETAILS</th>
        <th>DEVICE</th>
        <th>IP LOCATION</th>
      </tr>
    </thead>
    {activities.length ? (
      <tbody>
        {activities.map((activity, index) => (
          <ActivityRow
            key={`${activity.timestamp}-${activity.type}`}
            index={index}
            style={style}
            {...activity}
          />
        ))}
      </tbody>
    ) : (
      <tfoot>
        <tr>
          <td className={style.none} colSpan="6">
            {isFetching ? (
              <LoadingSpinner />
            ) : (
              <p>
                You have no activity to display from{' '}
                <strong>{activeTab.name}</strong>
              </p>
            )}
          </td>
        </tr>
      </tfoot>
    )}
  </table>
);

export default AccountActivitiesTable;
