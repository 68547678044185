import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Row2FA from '../components/signin/Row2FA';
import TwoFADeleteModal from '../components/signin/TwoFADeleteModal';
import { updateFactor, deleteFactor } from '../actions/twofa';
import { popup } from '../utils/popup';

export class TwoFARow extends Component {
  state = {
    factorToDelete: undefined,
    factorToUpdate: undefined,
    isDeleteModalOpen: false,
    isSubmittingDelete: false,
    isUpdateFormOpen: false,
    newName: '',
  };

  handleAdd = () => {
    const { mfaHandle, url } = this.props;
    popup(mfaHandle, url);
  };

  handleUpdate = (index) => {
    const { enrolled } = this.props;
    const factor = enrolled[index];

    this.setState({
      factorToUpdate: { index, ...factor },
      isUpdateFormOpen: true,
      newName: factor.name,
    });
  };

  handleChangeUpdate = (e) => {
    this.setState({
      newName: e.target.value,
    });
  };

  handleCancelUpdate = () => {
    this.setState({
      factorToUpdate: undefined,
      isUpdateFormOpen: false,
      newName: '',
    });
  };

  handleConfirmUpdate = async () => {
    const { factorToUpdate, newName } = this.state;
    const { actionUpdateFactor } = this.props;

    await actionUpdateFactor({
      id: factorToUpdate.id,
      name: newName,
    });
    this.handleCancelUpdate();
  };

  handleDelete = (index) => {
    const { enrolled } = this.props;
    const factor = enrolled[index];

    if (factor) {
      this.setState({
        factorToDelete: factor,
        isDeleteModalOpen: true,
      });
    }
  };

  handleConfirmDelete = async () => {
    const { factorToDelete } = this.state;
    const { actionDeleteFactor } = this.props;

    this.setState({
      isSubmittingDelete: true,
    });

    await actionDeleteFactor(factorToDelete.id);
    this.handleCancelDelete();
  };

  handleCancelDelete = () => {
    this.setState({
      factorToDelete: undefined,
      isDeleteModalOpen: false,
      isSubmittingDelete: false,
    });
  };

  render() {
    const { enrolled, name, totalEnrolled, mfaHandle, url } = this.props;

    const {
      factorToDelete,
      factorToUpdate,
      isDeleteModalOpen,
      isSubmittingDelete,
      isUpdateFormOpen,
      newName,
    } = this.state;

    if (!name) {
      return null;
    }

    const nameDelete = (factorToDelete && factorToDelete.name) || '';
    return (
      <Fragment>
        <Row2FA
          factors={enrolled}
          factorToUpdate={factorToUpdate}
          handleAdd={this.handleAdd}
          handleDelete={this.handleDelete}
          handleUpdate={this.handleUpdate}
          handleCancelUpdate={this.handleCancelUpdate}
          handleConfirmUpdate={this.handleConfirmUpdate}
          handleChange={this.handleChangeUpdate}
          isFormOpen={isUpdateFormOpen}
          newName={newName}
          title={name}
          totalEnrolled={totalEnrolled}
          mfaHandle={mfaHandle}
          url={url}
        />
        <TwoFADeleteModal
          isOpen={isDeleteModalOpen}
          name={nameDelete}
          handleConfirm={this.handleConfirmDelete}
          handleCancel={this.handleCancelDelete}
          mfaHandle={mfaHandle}
          isSubmitting={isSubmittingDelete}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  if (ownProps && ownProps.mfaHandle) {
    return {
      totalEnrolled: state.twofa.totalEnrolled,
      ...state.twofa[ownProps.mfaHandle],
    };
  }
};

const mapDispatchToProps = {
  actionDeleteFactor: deleteFactor,
  actionUpdateFactor: updateFactor,
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFARow);
