import React, { Component } from 'react';
import ErrorPage from './ErrorPage';
import PropTypes from 'prop-types';
import { sendToSentry } from '../utils/error';
import { getReactEnv } from '../constants';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: [],
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    if (getReactEnv() !== 'production') {
      // eslint-disable-next-line
      console.log(error);
    }
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    sendToSentry(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <ErrorPage />;
    }

    return children;
  }
}

export default ErrorBoundary;
