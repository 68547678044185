import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAgreements, updateAgreement } from '../actions/agreements';
import BiometricsModal from '../components/BiometricsModal';
import { BIOMETRIC_CONSENT } from '../constants/index';
import { getDocument } from '../utils/legalDocs';

class BiometricsModalContainer extends Component {
  state = {
    forceClose: false,
    isChecked: true,
  };

  componentDidMount() {
    const { actionFetchAgreements } = this.props;
    actionFetchAgreements();
  }

  handleConfirm = (e) => {
    const { actionUpdateAgreement, biometricNeeded } = this.props;
    const { id } = biometricNeeded;

    e && e.preventDefault && e.preventDefault();
    actionUpdateAgreement(id);
  };

  handleCancel = () => {
    this.setState({ forceClose: true });
  };

  handleChange = () => {
    this.setState((state) => ({
      isChecked: !state.isChecked,
    }));
  };

  render() {
    const { forceClose, isChecked } = this.state;
    const { isFetching, biometricNeeded } = this.props;

    const isOpen = biometricNeeded && !forceClose;

    return (
      <BiometricsModal
        isChecked={isChecked}
        isFetching={isFetching}
        isOpen={isOpen}
        handleCancel={this.handleCancel}
        handleChange={this.handleChange}
        handleConfirm={this.handleConfirm}
      />
    );
  }
}

const mapStateToProps = ({ agreements }) => ({
  biometricNeeded: getDocument(agreements.needed, BIOMETRIC_CONSENT),
});

const mapDispatchToProps = {
  actionFetchAgreements: fetchAgreements,
  actionUpdateAgreement: updateAgreement,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BiometricsModalContainer);
