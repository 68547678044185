import {
  FETCH_FACTORS_BEGIN,
  FETCH_FACTORS_SUCCESS,
  FETCH_FACTORS_FAILURE,
  FETCH_FACTOR_DELETE_BEGIN,
  FETCH_FACTOR_DELETE_SUCCESS,
  FETCH_FACTOR_DELETE_FAILURE,
  FETCH_FACTOR_UPDATE_BEGIN,
  FETCH_FACTOR_UPDATE_SUCCESS,
  FETCH_FACTOR_UPDATE_FAILURE,
} from '../actions/twofa';

export const initialState = {
  backupcodes: { enrolled: [] },
  devicekey: { enrolled: [] },
  fidokey: { enrolled: [] },
  generator: { enrolled: [] },
  isFetching: false,
  oldWallet: { enrolled: false },
  phone: { enrolled: [] },
  push: { enrolled: [] },
  totalEnrolled: 0,
};

const countEnrolled = (types) => {
  // Return count of enrolled MFAs excluding "recovery"
  let count = 0;
  for (const key in types) {
    if (key !== 'recovery') {
      count += types[key].enrolled?.length;
    }
  }
  return count;
};

const twofa = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_FACTORS_BEGIN:
    case FETCH_FACTOR_DELETE_BEGIN:
    case FETCH_FACTOR_UPDATE_BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_FACTORS_SUCCESS:
    case FETCH_FACTOR_DELETE_SUCCESS:
    case FETCH_FACTOR_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        totalEnrolled: countEnrolled(action.payload),
      };
    case FETCH_FACTORS_FAILURE:
    case FETCH_FACTOR_DELETE_FAILURE:
    case FETCH_FACTOR_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default twofa;
