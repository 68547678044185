import React from 'react';
import Checkbox from './Checkbox';
import style from '../style/components/BiometricsModal.module.scss';
import StyledModal from './StyledModal';

const BiometricsModal = ({
  isChecked,
  isFetching,
  isOpen,
  handleCancel,
  handleChange,
  handleConfirm,
}) => (
  <StyledModal
    isOpen={isOpen}
    closeModal={handleCancel}
    submitting={isFetching}
    title="Consent for ID.me to Collect Biometric Data"
    wrapper={(children) => (
      <form className={style.form} onSubmit={handleConfirm}>
        {children}
      </form>
    )}
    primaryButton={{
      text: 'Continue to my account',
      action: handleConfirm,
      disabled: !isChecked,
    }}
    cancelButton={{ hidden: true }}
    wideModal
  >
    <div className={style.body}>
      <p>
        By signing this Consent for ID.me to Collect Biometric Data you agree
        that you have been advised of and understand that ID.me collects,
        retains and uses biometric data for the purpose of identifying users and
        providing ID.me’s Services (as such term is defined in the ID.me
        Biometric Data Privacy Policy) to such users.
      </p>
      <p>
        Your biometric data collected by ID.me may include a photograph provided
        by you from a government identification card or passport which is used
        for facial geometry purposes, and solely in connection with live video
        conversations for identity proofing purposes, a voiceprint.
      </p>
      <p className={style.background}>
        * ID.me will not disclose or use your Biometric Data for any purpose
        other than identity proofing in connection with ID.me’s Services without
        your consent unless such disclosure is required by law or by valid legal
        process. If retained by ID.me, your biometric data will be deleted
        permanently from ID.me’s systems within 90 months of the closure or
        deactivation of you ID.me account.
      </p>
      <p>
        By signing below you acknowledge that you have received and read the
        ID.me Biometric Information Privacy Policy, a copy of which was provided
        to you electronically concurrently with this Consent and is posted
        online at Biometric Data Privacy Policy, and that you voluntarily
        consent to ID.me’s collection, storage and use of your biometric data
        solely to provide you with ID.me’s Services. You understand that you are
        free to decline to provide biometric data to ID.me and that you may
        revoke this consent at any time by notifying ID.me in writing as
        described in the ID.me{' '}
        <a
          href="https://www.id.me/biometric"
          rel="noopener noreferrer"
          target="_blank"
        >
          Biometric Data Privacy Policy
        </a>
        .
      </p>
    </div>

    <Checkbox id="biometric" onChange={handleChange} checked={isChecked}>
      I acknowledge that I have received and read the ID.me Biometric
      Information Privacy Policy
    </Checkbox>
  </StyledModal>
);
export default BiometricsModal;
