import {
  FETCH_SUBSCRIPTIONS_BEGIN,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_FAILURE,
  FETCH_DEFAULTHOMEPAGE_BEGIN,
  FETCH_DEFAULTHOMEPAGE_SUCCESS,
  FETCH_DEFAULTHOMEPAGE_FAILURE,
  SAVE_HOMEPAGE_BEGIN,
  SAVE_SUBSCRIPTIONS_BEGIN,
  SAVE_PREFERENCES_SUCCESS,
  SAVE_PREFERENCES_FAILURE,
  SUBSCRIPTIONS_TOGGLE,
  SUBSCRIPTIONS_TOGGLE_ALL,
  HOMEPAGE_SET,
} from '../actions/preferences';

export const initialState = {
  error: null,
  formChanged: false,
  homepage: null,
  isFetching: false,
  subscriptions: [],
};

const preferences = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_DEFAULTHOMEPAGE_BEGIN:
    case FETCH_SUBSCRIPTIONS_BEGIN:
    case SAVE_HOMEPAGE_BEGIN:
    case SAVE_SUBSCRIPTIONS_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        error: null,
        formChanged: false,
        isFetching: false,
        subscriptions: action.payload,
      };
    case FETCH_DEFAULTHOMEPAGE_SUCCESS:
      return {
        ...state,
        error: null,
        formChanged: false,
        homepage: action.payload,
        isFetching: false,
      };
    case SAVE_PREFERENCES_SUCCESS:
      return {
        ...state,
        error: null,
        formChanged: false,
        isFetching: false,
        isSuccess: true,
      };
    case FETCH_DEFAULTHOMEPAGE_FAILURE:
    case SAVE_PREFERENCES_FAILURE:
    case FETCH_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case SUBSCRIPTIONS_TOGGLE: {
      const index = action.payload;
      let { subscriptions } = state;
      const itemToToggle = { ...subscriptions[index] };
      itemToToggle.active = !itemToToggle.active;
      subscriptions = [
        ...subscriptions.slice(0, index),
        itemToToggle,
        ...subscriptions.slice(index + 1),
      ];
      return {
        ...state,
        formChanged: true,
        subscriptions,
      };
    }
    case SUBSCRIPTIONS_TOGGLE_ALL: {
      const allChecked = state.subscriptions.every((sub) => sub.active);
      return {
        ...state,
        formChanged: true,
        subscriptions: state.subscriptions.map((sub) => ({
          ...sub,
          active: !allChecked,
        })),
      };
    }
    case HOMEPAGE_SET:
      return {
        ...state,
        homepage: action.payload,
        formChanged: true,
      };

    default:
      return state;
  }
};

export default preferences;
