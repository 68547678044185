import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MMDDYYYY, HHMM } from '../../utils/time';
import loginPropTypes from '../../proptypes/login';
import RevokeLinkageButton from './RevokeLinkageButton';

const LinkedAccountsTableRow = ({ account, style }) => {
  return (
    <tr className={style.account}>
      <td title="ACCOUNT:">
        <div className={style.bold}>{account.name}</div>
        <div>{account.email}</div>
      </td>
      <td title="RELATIONSHIP:">
        <div className={style.bold}>Verified</div>
        <div>{account.relationship}</div>
      </td>
      <td title="DATE / TIME:" className={style.connected}>
        <div className={style.bold}>{MMDDYYYY(account.date)}</div>
        <div>{HHMM(account.date)}</div>
      </td>

      <td className={style.revoke}>
        <RevokeLinkageButton account={account} />
      </td>
    </tr>
  );
};

LinkedAccountsTableRow.propTypes = {
  account: loginPropTypes.isRequired,
  style: PropTypes.shape({}).isRequired,
};

export default memo(LinkedAccountsTableRow);
