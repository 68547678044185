import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import OldWallet2FA from '../components/signin/OldWallet2FA';
import { popup } from '../utils/popup';

export class TwoFAOldWallet extends Component {
  handleAddAuthenticator = () => {
    const { url } = this.props;
    popup('wallet', url);
  };

  render() {
    const { isEnrolled } = this.props;

    if (!isEnrolled) {
      return null;
    }

    return (
      <Fragment>
        <OldWallet2FA handleSetup={this.handleAddAuthenticator} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isEnrolled: state.twofa.oldWallet && state.twofa.oldWallet.enrolled,
  url: state.twofa.push.url,
});

export default connect(mapStateToProps)(TwoFAOldWallet);
