import React, { cloneElement, useState, useEffect, useRef } from 'react';
import style from '../style/components/DropdownButton.module.scss';

const DropdownButton = ({ ariaLabel, dropdownOptions, isFetching }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdown = useRef(null);
  const dropdownContainer = useRef(null);

  // Closes the dropdown options when clicking outside of the dropdown area, including
  // re-clicking the button. Use of onBlur doesn't work with Safari, so this has to be
  // used instead.
  useEffect(() => {
    const handleClick = (e) => {
      if (!e.target.closest(`.dropdown_option`) && isDropdownOpen) {
        setIsDropdownOpen(false);
      }
    };
    const handleKeyDown = (e) => {
      if (isDropdownOpen && e.key === 'Escape') {
        setIsDropdownOpen(false);
      }
    };
    const handleFocus = (e) => {
      if (
        dropdownContainer.current &&
        !dropdownContainer.current.contains(e.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClick);
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('focusin', handleFocus);
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('focusin', handleFocus);
    };
  }, [isDropdownOpen, setIsDropdownOpen]);

  const onClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dropdownOptionsWithClose = [];
  dropdownOptions.forEach((option) => {
    if (option != null) {
      dropdownOptionsWithClose.push(
        cloneElement(option, { setIsDropdownOpen: setIsDropdownOpen }),
      );
    }
  });

  return (
    <div className={style.dropdownArea} ref={dropdownContainer}>
      <div className="dropdownButton" ref={dropdown}>
        <button
          aria-label={ariaLabel}
          aria-expanded={isDropdownOpen}
          className={isDropdownOpen ? style.clicked : null}
          onClick={onClick}
          disabled={isFetching}
        >
          ...
        </button>
      </div>
      {isDropdownOpen && (
        <div className={style.dropdown}>
          <ul>{dropdownOptionsWithClose}</ul>
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
