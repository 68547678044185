import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import DataDownload from '../../img/icons/data-download.svg';
import style from '../../style/components/california/Request.module.scss';

const RequestData = ({ loading, success }) => {
  if (loading) {
    return <LoadingSpinner className={style.spinner} />;
  } else if (success) {
    return (
      <Fragment>
        <img className={style.image} src={DataDownload} alt="" />
        <h1>We've received a request to download data</h1>
        <p>
          We are preparing your data file. This can take up to{' '}
          <strong>45</strong> days to complete.
          <br />
          You'll receive an email when it is ready to download.
        </p>
        <Link className={style.link} to="/signin/privacy">
          Continue
        </Link>
      </Fragment>
    );
  }
  // what do I do here?
  return null;
};

export default RequestData;
