import React from 'react';
import RadioButton from '../../components/RadioButton';
import ButtonPrimary from '../../components/ButtonPrimary';
import style from '../../style/components/california/Form.module.scss';

const CaliforniaForm = ({
  currentValue = '',
  handleRadioChange,
  handleSubmit,
}) => (
  <form className={style.form} onSubmit={handleSubmit}>
    <h1>Are you a California resident?</h1>
    <RadioButton
      onChange={handleRadioChange}
      currentValue={currentValue}
      value="yes"
      name="california"
    >
      <strong>Yes</strong>, I am a California resident
    </RadioButton>
    <RadioButton
      onChange={handleRadioChange}
      currentValue={currentValue}
      value="no"
      name="california"
    >
      <strong>No</strong>, I am NOT a California resident
    </RadioButton>
    <ButtonPrimary onClick={handleSubmit}>Next</ButtonPrimary>
  </form>
);

export default CaliforniaForm;
