import React, { useState } from 'react';
import style from '../style/components/ErrorAlert.module.scss';
import circleX from '../img/icons/error-circle-x.svg';
import close from '../img/icons/close.svg';
import ButtonLink from './ButtonLink';

const ErrorAlert = ({ handleClose, children }) => {
  const [open, setOpen] = useState(true);

  return (
    open && (
      <section className={style.card}>
        <div className={style.iconLeft}>
          <img src={circleX} alt="x" />
        </div>

        <ButtonLink
          onClick={() => {
            handleClose ? handleClose() : setOpen(false);
          }}
        >
          <div className={style.iconRight}>
            <img src={close} alt="close" />
          </div>
        </ButtonLink>

        <div>{children}</div>
      </section>
    )
  );
};

export default ErrorAlert;
