import React from 'react';
import style from '../style/components/ErrorPage.module.scss';
import IdmeLogo from '../img/idme-logo.svg';

const ErrorPage = () => (
  <div>
    <div className={style.top}>
      <h1>
        <a href="https://id.me">
          <img alt="ID.me" src={IdmeLogo} />
        </a>
      </h1>
    </div>
    <div className={style.bottom}>
      <div className={style.content}>
        <h2>Something Went Wrong</h2>
        <p>
          We are unable to process your request. We have been notified of the
          problem and will do our best to make sure it doesn't happen again!
        </p>
        <p>
          If you believe you have received this message in error, please&nbsp;
          <a href={window.location.pathname}>try again</a>
          &nbsp;or visit our&nbsp;
          <a href="https://help.id.me/">customer support knowledge base.</a>
        </p>
      </div>
    </div>
  </div>
);

export default ErrorPage;
