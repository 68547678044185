import React, { memo, useEffect } from 'react';
import ReactGA from 'react-ga';
import TwoFAManager from '../../containers/TwoFAManager';
import TwoFAOldWallet from '../../containers/TwoFAOldWallet';
import TwoFARow from '../../containers/TwoFARow';
import shieldIcon from '../../img/icons/shield.svg';
import style from '../../style/components/signin/TwoFA.module.scss';
import {
  BACKUPCODES,
  DEVICEKEY,
  FIDOKEY,
  GENERATOR,
  PHONE,
  PUSH,
  YUBIKEY,
} from '../../constants/mfa';
import Card from '../Card';
import { getReactEnv } from '../../constants';

const TwoFA = () => {
  useEffect(() => {
    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  return (
    <Card
      title="Securing Your Account"
      icon={<img alt="shield" src={shieldIcon} />}
      className={style.wrapper}
    >
      <p>
        Set up at least one backup option to add an extra layer of security to
        your account.
        <br />
        With multi-factor authentication, even if someone guesses your password,
        they won&#39;t be able to sign in as you.
      </p>

      <TwoFAManager />
      <TwoFAOldWallet />
      <TwoFARow mfaHandle={DEVICEKEY} />
      <TwoFARow mfaHandle={PHONE} />
      <TwoFARow mfaHandle={PUSH} />
      <TwoFARow mfaHandle={GENERATOR} />
      <TwoFARow mfaHandle={FIDOKEY} />
      <TwoFARow mfaHandle={YUBIKEY} />
      <TwoFARow mfaHandle={BACKUPCODES} />
    </Card>
  );
};

export default memo(TwoFA);
