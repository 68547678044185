import {
  FETCH_CLOSE_ACCOUNT_BEGIN,
  FETCH_CLOSE_ACCOUNT_SUCCESS,
  FETCH_CLOSE_ACCOUNT_FAILURE,
  FETCH_CLOSE_ACCOUNT_CONFIRM_BEGIN,
  FETCH_CLOSE_ACCOUNT_CONFIRM_SUCCESS,
  FETCH_CLOSE_ACCOUNT_CONFIRM_FAILURE,
} from '../actions/account';

export const initialState = {
  deleted: false,
  error: null,
  isFetching: false,
  reasons: [],
};

const account = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_CLOSE_ACCOUNT_BEGIN:
    case FETCH_CLOSE_ACCOUNT_CONFIRM_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_CLOSE_ACCOUNT_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        reasons: action.payload,
      };
    case FETCH_CLOSE_ACCOUNT_CONFIRM_SUCCESS:
      return {
        ...state,
        deleted: true,
        error: null,
        isFetching: false,
      };
    case FETCH_CLOSE_ACCOUNT_FAILURE:
    case FETCH_CLOSE_ACCOUNT_CONFIRM_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default account;
