import React from 'react';
import style from '../style/components/FormInputs.module.scss';
import IntlTelInput from './IntlTelInput';

const PhoneNumberInput = ({
  initialValue,
  error,
  name,
  required,
  label,
  noMargin,
  onChangeNumber,
  onChangeValidity,
  disabled,
}) => {
  return (
    <label
      htmlFor={name}
      className={`${style.wrapper} ${noMargin ? style.noMargin : ''}`}
    >
      <div className={style.label}>
        {label}
        {required && <span className={style.required}>&nbsp;*</span>}
      </div>
      <IntlTelInput
        initialValue={initialValue}
        onChangeNumber={onChangeNumber}
        onChangeValidity={onChangeValidity}
        disabled={disabled}
        // onChangeErrorCode={handleChange}
        initOptions={{
          // autoPlaceholder: 'off',
          nationalMode: true,
          formatOnDisplay: true,
          separateDialCode: false,
          // NOTE: these lists come from idme-idp/app/assets/javascripts/multifactor/phone.js.coffee
          preferredCountries: ['us', 'gb', 'de', 'kr', 'ca', 'it', 'au', 'nz'],
          excludeCountries: ['er', 'pk'],
          containerClass: style.fullWidth,
        }}
      />
      {error && (
        <div className={style.error} role="alert">
          <strong>Error: </strong>
          {error}
        </div>
      )}
    </label>
  );
};

export default PhoneNumberInput;
