import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getLoginProp, getLoginLogo } from '../../utils/loginPropMap';
import { MMDDYYYY } from '../../utils/time';
import loginPropTypes from '../../proptypes/login';
import removeRedRed from '../../img/icons/delete-red.svg';

const LoginsTableRow = ({ handleDelete, login, style }) => {
  const email = getLoginProp(login, 'email');
  const fname = getLoginProp(login, 'fname');
  const lname = getLoginProp(login, 'lname');
  const showRemove = login && login.id !== -1;

  const onClickRemove = useCallback(() => {
    handleDelete(login);
  }, [handleDelete, login]);

  return (
    <tr>
      <th scope="row">
        <div>
          <img
            className={style[login.handle]}
            src={getLoginLogo(login.handle)}
            alt="logo"
          />
        </div>
      </th>
      <td title="USER LOGIN:" className={style.login}>
        <div>{login.name}</div>
        <div>{email}</div>
      </td>
      <td title="FIRST NAME:">{fname}</td>
      <td title="LAST NAME:">{lname}</td>
      <td title="CONNECTED:" className={style.connected}>
        <div>{MMDDYYYY(login.date)}</div>
      </td>
      <td className={showRemove ? style.remove : style.hide}>
        {showRemove && (
          <button type="button" onClick={onClickRemove}>
            <img alt="trashcan" src={removeRedRed} />
            Remove
          </button>
        )}
      </td>
    </tr>
  );
};

LoginsTableRow.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  login: loginPropTypes.isRequired,
  style: PropTypes.shape({}).isRequired,
};

export default memo(LoginsTableRow);
