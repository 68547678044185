import axios from 'axios';
import {
  URL_DATA_DOWNLOADS,
  URL_DATA_DOWNLOADS_STATUS,
} from '../constants/urls';

export const FETCH_DATA_DOWNLOADS_STATUS_BEGIN =
  'FETCH_DATA_DOWNLOADS_STATUS_BEGIN';
export const FETCH_DATA_DOWNLOADS_STATUS_SUCCESS =
  'FETCH_DATA_DOWNLOADS_STATUS_SUCCESS';
export const FETCH_DATA_DOWNLOADS_STATUS_FAILURE =
  'FETCH_DATA_DOWNLOADS_STATUS_FAILURE';

export const FETCH_DATA_DOWNLOAD_FILE_BEGIN = 'FETCH_DATA_DOWNLOAD_FILE_BEGIN';
export const FETCH_DATA_DOWNLOAD_FILE_SUCCESS =
  'FETCH_DATA_DOWNLOAD_FILE_SUCCESS';
export const FETCH_DATA_DOWNLOAD_FILE_FAILURE =
  'FETCH_DATA_DOWNLOAD_FILE_FAILURE';
export const FETCH_DATA_DOWNLOAD_FILE_CLEAR = 'FETCH_DATA_DOWNLOAD_FILE_CLEAR';

export const fetchDataDownloadsStatus = () => async (dispatch) => {
  dispatch({ type: FETCH_DATA_DOWNLOADS_STATUS_BEGIN });

  try {
    const response = await axios.get(URL_DATA_DOWNLOADS_STATUS);

    dispatch({
      payload: response.data,
      type: FETCH_DATA_DOWNLOADS_STATUS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error && error.response && error.response.statusText,
      type: FETCH_DATA_DOWNLOADS_STATUS_FAILURE,
    });
  }
};

export const fetchDataDownloadFile = (formData) => async (dispatch) => {
  dispatch({ type: FETCH_DATA_DOWNLOAD_FILE_BEGIN });

  try {
    const response = await axios.post(URL_DATA_DOWNLOADS, formData, {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data-request.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    dispatch({
      type: FETCH_DATA_DOWNLOAD_FILE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error && error.response && error.response.statusText,
      type: FETCH_DATA_DOWNLOAD_FILE_FAILURE,
    });
  }
};
