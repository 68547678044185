import React, { memo } from 'react';
import WorkProfileProps from '../../proptypes/workProfile';
import style from '../../style/components/profile/WorkProfile.module.scss';
import LinkedinLogo from '../../img/icons/linkedin-new.svg';
import Card from '../Card';
import { addressLine1, addressLine2 } from '../../utils/address';
import UpdateWorkProfileButton from './UpdateWorkProfileButton';
import { formatPhone, lineType } from '../../utils/phone';

const WorkProfile = ({ workProfile }) => {
  const workEmail = workProfile.email;
  const workPhone = workProfile.phone;

  return (
    <Card
      title="Work Profile"
      icon={<idme-icon name="briefcase" color="stone" size="large" />}
      className={style.card}
    >
      <section>
        <div>
          <h4 className={style.subtitle}>Business</h4>
          <p className={style.noMarginBottom}>{workProfile.business_name}</p>
          <p className={style.noMarginTop}>{workProfile.title}</p>

          {workProfile.address ? (
            <>
              <p className={style.noMarginBottom}>
                {addressLine1(
                  workProfile.address.street,
                  workProfile.address.street2,
                )}
              </p>
              <p className={style.noMarginTop}>
                {addressLine2(
                  workProfile.address.city,
                  workProfile.address.state,
                  workProfile.address.zip,
                )}
              </p>
            </>
          ) : null}

          <div className={style.links}>
            {workProfile.linkedin && (
              <a
                href={workProfile.linkedin}
                target="_blank"
                className={style.linkedIn}
                rel="noreferrer"
              >
                <img
                  className={style.iconLinkedin}
                  src={LinkedinLogo}
                  alt="Linkedin Logo"
                />
                Profile
              </a>
            )}
          </div>
        </div>
        <div className={style.editButton}>
          <UpdateWorkProfileButton workProfile={workProfile} />
        </div>
      </section>
      <div className={style.separator} />
      <section>
        <div>
          <h4 className={style.subtitle}>Email</h4>
          {workEmail ? (
            <div className={style.emailDiv}>
              <span className={style.email}>{workEmail.address}</span>
            </div>
          ) : (
            <p>No work email</p>
          )}
        </div>
      </section>
      <div className={style.separator} />
      <section>
        <div>
          <h4 className={style.subtitle}>Phone Number</h4>
          <p className={style.phoneWrapper}>
            {!workPhone ? (
              'No work phone'
            ) : (
              <>
                {workPhone.linetype ? (
                  <>
                    <span className={style.lineType}>
                      {lineType(workPhone.linetype)}
                    </span>{' '}
                  </>
                ) : null}
                <span className={style.extWrapper}>
                  <span>{formatPhone(workPhone.number)}</span>
                  {workPhone.extension && (
                    <span>Ext. {workPhone.extension}</span>
                  )}
                </span>
              </>
            )}
          </p>
        </div>
      </section>
    </Card>
  );
};
WorkProfile.propTypes = {
  workProfile: WorkProfileProps,
};

export default memo(WorkProfile);
