import React, { useState } from 'react';
import StyledModal from '../StyledModal';
import style from '../../style/components/signin/CloseAccountModal.module.scss';
import { useSelector } from 'react-redux';
import AccountDeleteModal from './AccountDeleteModal';

const CloseAccountWarning = () => {
  return (
    <div className={style.warning}>
      <idme-icon name="alert-triangle" />
      <div>
        <h3>Government benefit applicants</h3>
        <p>
          If you are using ID.me to verify your identity as part of a government
          benefits claim, you <strong>SHOULD NOT DELETE</strong> your ID.me
          account. Closing a verified ID.me account and creating a new ID.me
          account will not help you proceed with a claim.
        </p>
      </div>
    </div>
  );
};

const CloseAccountModal = ({ closeModal, isOpen }) => {
  const hasStateWorkforceCredential = useSelector(
    (state) => state.user.workforce_credential,
  );

  const [isAccountDeleteModalOpen, setIsAccountDeleteModalOpen] =
    useState(false);

  return (
    <>
      <StyledModal
        isOpen={isOpen}
        closeModal={closeModal}
        title="Close my account"
        primaryButton={{
          text: 'Continue',
          action: () => {
            setIsAccountDeleteModalOpen(true);
            closeModal();
          },
        }}
      >
        <p>
          ID.me will retain a portion of your account's attributes on file to
          comply with applicable laws and help us prevent fraud. Please see our{' '}
          <a href="https://www.id.me/privacy">Privacy Policy</a> and{' '}
          <a href="https://www.id.me/terms">Terms of Service</a> for details,
          and{' '}
          <a href="https://help.id.me/hc/en-us/articles/203532014">
            learn about account closure
          </a>
          .
        </p>

        {hasStateWorkforceCredential && <CloseAccountWarning />}

        <div className={style.background}>
          <strong>Need help with your account?</strong>
          <p>
            If you are experiencing difficulty completing verification or have
            questions concerning your account, please{' '}
            <a href="https://help.id.me/hc/en-us/requests/new">
              contact our Support Team
            </a>{' '}
            for assistance before closing your account.
          </p>
        </div>
      </StyledModal>
      <AccountDeleteModal
        isOpen={isAccountDeleteModalOpen}
        closeModal={() => setIsAccountDeleteModalOpen(false)}
      />
    </>
  );
};

export default CloseAccountModal;
