import React, { memo } from 'react';
import PropTypes from 'prop-types';
import style from '../../style/components/changeRequests/AvailableRequestTile.module.scss';

const AvailableRequestTile = ({ description, title, icon, uri, onClick }) => {
  return (
    <div className={style.tile}>
      <div className={style.tileBody}>
        <img className={style.icon} src={icon} alt="" />
        <div className={style.content}>
          <h4 className={style.heading}>{title}</h4>
          <p className={style.body}>{description}</p>
        </div>
      </div>
      <a className={style.cta} href={uri} onClick={onClick}>
        Select
      </a>
    </div>
  );
};

AvailableRequestTile.propTypes = {
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  uri: PropTypes.string,
};

AvailableRequestTile.defaultProps = {};

export default memo(AvailableRequestTile);
