import React from 'react';
import Modal from 'react-modal';
import style from '../style/components/StyledModal.module.scss';
import ConditionalWrapper from './ConditionalWrapper';

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

const StyledModal = ({
  isOpen,
  closeModal,
  title,
  children,
  wrapper = null,
  submitting = false,
  wideModal = false,
  cancelButton = { hidden: false, text: 'Cancel' },
  primaryButton = {
    action: () => {},
    disabled: null,
    hidden: true,
    submittingText: 'Loading...',
    text: 'Submit',
  },
}) => {
  const headingId = title.replace(/\s+/g, '-').toLowerCase();

  const closeFunction = submitting ? null : closeModal;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeFunction}
      className={`${style.modal} ${wideModal ? style.wideModal : ''}`}
      overlayClassName={style.overlay}
      aria={{ labelledby: headingId }}
    >
      <ConditionalWrapper condition={!!wrapper} wrapper={wrapper}>
        <h3 id={headingId} className={style.header}>
          {title}
        </h3>
        <div
          className={`${style.closeIcon} ${
            submitting ? style.disabledIcon : ''
          }`}
          onClick={closeFunction}
        >
          <idme-icon name="x" size="large" />
        </div>

        <div className={style.body}>{children}</div>

        <ConditionalWrapper
          condition={!primaryButton.hidden}
          wrapper={(children) => (
            <idme-action-group nopadding>{children}</idme-action-group>
          )}
        >
          {primaryButton.hidden ? null : (
            <idme-button
              slot="primary"
              variant="primary"
              text={
                submitting && primaryButton.submittingText
                  ? primaryButton.submittingText
                  : primaryButton.text
              }
              layout="fill"
              type="submit"
              onClick={primaryButton.action}
              disabled={primaryButton.disabled || submitting || null}
            />
          )}
          {cancelButton.hidden ? null : (
            <idme-button
              slot="secondary"
              variant="secondary"
              text={cancelButton.text}
              layout="fill"
              type="button"
              onClick={closeFunction}
              disabled={submitting || null}
            />
          )}
        </ConditionalWrapper>
      </ConditionalWrapper>
    </Modal>
  );
};
export default StyledModal;
