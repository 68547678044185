import { hasStorage } from './storage';

export const redirect = (url, to) => {
  if (hasStorage) {
    try {
      window.sessionStorage.setItem('redirect', to);
    } catch (e) {
      // do nothing.
    }
  }
  if (url) {
    window.location.assign(url);
  }
};

export const cameFromRedirect = () => {
  if (hasStorage) {
    try {
      const url = window.sessionStorage.getItem('redirect');

      // always remove it in case we get in a weird state, we don't want to do an infinite redirect
      window.sessionStorage.removeItem('redirect');

      return url;
    } catch (e) {
      // do nothing.
    }
  }
  return false;
};
