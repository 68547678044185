import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Ids from '../components/overview/Ids';
import { fetchIds } from '../actions/ids';
import IdProps from '../proptypes/id';

export class OverviewIds extends Component {
  static propTypes = {
    actionFetchIds: PropTypes.func.isRequired,
    active: PropTypes.arrayOf(
      PropTypes.shape({
        ...IdProps,
      }),
    ),
    expired: PropTypes.arrayOf(
      PropTypes.shape({
        ...IdProps,
      }),
    ),
    isFetching: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    active: [],
    expired: [],
  };

  componentDidMount() {
    const { actionFetchIds, isFetching } = this.props;
    if (!isFetching) {
      actionFetchIds();
    }
  }

  render() {
    const { active, expired } = this.props;
    return <Ids active={active} expired={expired} />;
  }
}

export const mapStateToProps = (state) => ({
  ...state.ids,
});

const mapDispatchToProps = {
  actionFetchIds: fetchIds,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OverviewIds),
);
