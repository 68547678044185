import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import CaliforniaFlowContainer from '../containers/CaliforniaFlow';
import style from '../style/pages/California.module.scss';
import { getReactEnv } from '../constants';

const CaliforniaFlowPage = () => {
  useEffect(() => {
    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }, []);
  return (
    <div className={style.page}>
      <div className={style.content}>
        <CaliforniaFlowContainer />
      </div>
    </div>
  );
};

export default CaliforniaFlowPage;
