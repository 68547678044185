export const URL_NEW_SESSION = '/session/new';
export const URL_SESSION = '/session';

export const URL_HOMEPAGE = '/homepage';

export const URL_SUBSCRIPTIONS = '/subscriptions';

export const URL_IDS = '/v3/credentials';

export const URL_PROFILE = '/profile';
export const URL_ADDRESS_CHANGE = '/profile/addresses';
export const URL_WORK_PROFILE = '/profile/work';

export const URL_CHANGE_REQUESTS = '/profile/change_requests';

export const URL_LINKED_ACCOUNTS = '/linkages';

export const URL_EMAILS = '/emails';
export const URL_EMAIL_PRIMARY = ['/emails/', '/primary'];
export const URL_EMAIL_RESEND = ['/emails/', '/resend'];

export const URL_LOGINS = '/logins';

export const URL_PASSWORD = '/passwords';

export const URL_WEBSITES = '/consents';

export const URL_ACTIVITIES = '/activities';

export const URL_CLOSE_ACCOUNT = '/account_close';

export const URL_EMAIL_CONFIRMATION = '/confirmations/';

export const URL_TWOFA = '/factors';

export const URL_CASHBACK = '/cashback';

export const URL_CCPA_LEGAL = '/agreements/ccpa';

export const URL_DATA = '/data_requests';
/**
 * @deprecated
 */
export const URL_DATA_RESIDENCY = '/data_requests/residency';
export const URL_DATA_STATUS = '/data_requests/status';

export const URL_DATA_DOWNLOADS = '/data_requests/downloads';
export const URL_DATA_DOWNLOADS_STATUS = '/data_requests/downloads/status';

export const URL_AGREEMENTS = '/agreements';

export const URL_ALERTS = '/alerts';

export const URL_SUPPORT_REQUESTS = '/support_tickets';
export const URL_SUPPORT_REQUEST_FIELDS = '/support_tickets/fields';

export const URL_FEATURE_FLAGS = '/feature_flags';

export const URL_REGION_COUNTRIES = '/regions/countries';
export const URL_REGION_STATES = '/regions/states';
