import axios from 'axios';
import { sendToSentry } from '../utils/error';
import {
  URL_EMAILS,
  URL_EMAIL_PRIMARY,
  URL_EMAIL_RESEND,
} from '../constants/urls';
import { checkResponseToBeArray } from '../utils/axios';
import { fetchUser } from './user';

export const FETCH_EMAILS_BEGIN = 'FETCH_EMAILS_BEGIN';
export const FETCH_EMAILS_SUCCESS = 'FETCH_EMAILS_SUCCESS';
export const FETCH_EMAILS_FAILURE = 'FETCH_EMAILS_FAILURE';

export const FETCH_EMAIL_ADD_BEGIN = 'FETCH_EMAIL_ADD_BEGIN';
export const FETCH_EMAIL_ADD_SUCCESS = 'FETCH_EMAIL_ADD_SUCCESS';
export const FETCH_EMAIL_ADD_FAILURE = 'FETCH_EMAIL_ADD_FAILURE';

export const FETCH_EMAIL_UPDATE_BEGIN = 'FETCH_EMAIL_UPDATE_BEGIN';
export const FETCH_EMAIL_UPDATE_SUCCESS = 'FETCH_EMAIL_UPDATE_SUCCESS';
export const FETCH_EMAIL_UPDATE_FAILURE = 'FETCH_EMAIL_UPDATE_FAILURE';

export const FETCH_EMAIL_PRIMARY_BEGIN = 'FETCH_EMAIL_PRIMARY_BEGIN';
export const FETCH_EMAIL_PRIMARY_SUCCESS = 'FETCH_EMAIL_PRIMARY_SUCCESS';
export const FETCH_EMAIL_PRIMARY_FAILURE = 'FETCH_EMAIL_PRIMARY_FAILURE';

export const FETCH_EMAIL_RESEND_BEGIN = 'FETCH_EMAIL_RESEND_BEGIN';
export const FETCH_EMAIL_RESEND_SUCCESS = 'FETCH_EMAIL_RESEND_SUCCESS';
export const FETCH_EMAIL_RESEND_FAILURE = 'FETCH_EMAIL_RESEND_FAILURE';

export const FETCH_EMAIL_DELETE_BEGIN = 'FETCH_EMAIL_DELETE_BEGIN';
export const FETCH_EMAIL_DELETE_SUCCESS = 'FETCH_EMAIL_DELETE_SUCCESS';
export const FETCH_EMAIL_DELETE_FAILURE = 'FETCH_EMAIL_DELETE_FAILURE';

export const RESET_EMAIL_STATE = 'RESET_EMAIL_STATE';

export const fetchEmails = () => async (dispatch) => {
  dispatch({ type: FETCH_EMAILS_BEGIN });

  try {
    const response = await axios.get(URL_EMAILS);

    // this throws if the response is not an array.
    checkResponseToBeArray(response);

    dispatch({
      payload: response.data,
      type: FETCH_EMAILS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_EMAILS_FAILURE,
    });
    if (error instanceof TypeError) {
      sendToSentry(error);
    }
  }
};

export const addEmail = (email, kinds) => async (dispatch) => {
  dispatch({ type: FETCH_EMAIL_ADD_BEGIN });

  try {
    const response = await axios.post(URL_EMAILS, {
      email: email,
      kinds: kinds,
    });

    // all emails come back from successful post
    checkResponseToBeArray(response);

    dispatch({
      payload: response.data,
      type: FETCH_EMAIL_ADD_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_EMAIL_ADD_FAILURE,
    });
    if (error instanceof TypeError) {
      sendToSentry(error);
    }
  }
};

export const makePrimary = (email) => async (dispatch) => {
  dispatch({ type: FETCH_EMAIL_PRIMARY_BEGIN });

  try {
    // all emails come back from successful post
    const response = await axios.post(URL_EMAIL_PRIMARY.join(email.id));

    checkResponseToBeArray(response);

    dispatch({
      payload: response.data,
      type: FETCH_EMAIL_PRIMARY_SUCCESS,
    });
    dispatch(fetchUser());
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_EMAIL_PRIMARY_FAILURE,
    });
    if (error instanceof TypeError) {
      sendToSentry(error);
    }
  }
};

export const updateEmail = (email, kinds) => async (dispatch) => {
  dispatch({ type: FETCH_EMAIL_UPDATE_BEGIN });

  try {
    // all emails come back from successful post
    const response = await axios.put(`${URL_EMAILS}/${email.id}`, {
      email: email,
      kinds: kinds,
    });

    checkResponseToBeArray(response);

    dispatch({
      payload: response.data,
      type: FETCH_EMAIL_UPDATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_EMAIL_UPDATE_FAILURE,
    });
    if (error instanceof TypeError) {
      sendToSentry(error);
    }
  }
};

export const resendConfirmation = (email) => async (dispatch) => {
  dispatch({ type: FETCH_EMAIL_RESEND_BEGIN });

  try {
    const response = await axios.post(URL_EMAIL_RESEND.join(email.id), {
      data: email.id,
    });

    // all emails come back from successful confirm
    checkResponseToBeArray(response);

    dispatch({
      payload: response.data,
      type: FETCH_EMAIL_RESEND_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_EMAIL_RESEND_FAILURE,
    });
    if (error instanceof TypeError) {
      sendToSentry(error);
    }
  }
};

export const deleteEmail = (email) => async (dispatch) => {
  dispatch({ type: FETCH_EMAIL_DELETE_BEGIN });

  try {
    // all emails come back from successful delete
    const response = await axios.delete(`${URL_EMAILS}/${email.id}`, {
      body: { email },
    });

    checkResponseToBeArray(response);

    dispatch({
      payload: response.data,
      type: FETCH_EMAIL_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_EMAIL_DELETE_FAILURE,
    });
    if (error instanceof TypeError) {
      sendToSentry(error);
    }
  }
};

export const resetEmailState = () => ({
  type: RESET_EMAIL_STATE,
});
