import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import style from '../style/pages/Privacy.module.scss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import { Trans, useTranslation } from 'react-i18next';
import LabelSelect from '../components/LabelSelect';
import { getReactEnv } from '../constants';

const Privacy = () => {
  useEffect(() => {
    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  const featureFlags = useSelector((state) => state.featureFlags);

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    if (lang && i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  if (!featureFlags.available && !featureFlags.error) {
    return <LoadingSpinner />;
  }

  const privacyPolicyLink = (
    <a href="https://www.id.me/privacy">Privacy Policy</a>
  );
  const billOfRightsLink = (
    <a href="https://network.id.me/privacy-bill-of-rights/">
      Privacy Bill of Rights
    </a>
  );
  const biometricLink = (
    <a href="https://www.id.me/biometric">Biometric Information</a>
  );
  const emailLink = <a href="mailto:privacy@id.me">Privacy@ID.me</a>;

  return (
    <div className={style.page}>
      <div className={style.content}>
        <div className={style.languageButton}>
          <LabelSelect
            label="Language"
            name="language"
            value={lang}
            placeholder="Select a language"
            onChange={(event) => setLang(event.target.value)}
            options={[
              { label: 'English', value: 'en' },
              { label: 'Spanish', value: 'es' },
              { label: 'Arabic', value: 'ar' },
              { label: 'Armenian', value: 'hy' },
              { label: 'Bengali', value: 'bn' },
              { label: 'French', value: 'fr_CA' },
              { label: 'Farsi', value: 'fa' },
              { label: 'Hindi', value: 'hi' },
              { label: 'Haitian Creole', value: 'ht' },
              { label: 'Italian', value: 'it' },
              { label: 'Japanese', value: 'ja' },
              { label: 'Khmer', value: 'km' },
              { label: 'Korean', value: 'ko' },
              { label: 'Punjabi', value: 'pa' },
              { label: 'Russian', value: 'ru' },
              { label: 'Thai', value: 'th' },
              { label: 'Tagalog/Filipino', value: 'tl' },
              { label: 'Vietnamese', value: 'vi' },
              { label: 'Yiddish', value: 'yi' },
              { label: 'Simplified Chinese', value: 'zh_Hans' },
              { label: 'Traditional Chinese', value: 'zh' },
            ]}
          />
        </div>
        <br />
        <br />

        <h2>{t('privacy.mainTitle')}</h2>
        <p>{t('privacy.mainDescription1')}</p>
        <p>
          <Trans
            i18nKey="privacy.mainDescription2"
            components={{ privacyPolicyLink, billOfRightsLink }}
          />
        </p>
        <section>
          <h3>{t('privacy.personalInformationTitle')}</h3>
          <p>
            <Trans
              i18nKey="privacy.personalInformationDescription"
              components={{ privacyPolicyLink }}
            />
          </p>
        </section>

        <section>
          <h3>{t('privacy.biometricTitle')}</h3>
          <p>
            <Trans
              i18nKey="privacy.biometricDescription"
              components={{ biometricLink }}
            />
          </p>
        </section>
        <section>
          <h3>{t('privacy.privacyRightsTitle')}</h3>
          <p>{t('privacy.privacyRightsDescription1')}</p>
          <p>
            <Trans
              i18nKey="privacy.privacyRightsDescription2"
              components={{ privacyPolicyLink, emailLink }}
            />
          </p>
          <idme-button
            variant="secondary"
            text={t('privacy.myDataRights')}
            type="button"
            size="small"
            onClick={() => history.push('/signin/privacy')}
          />
        </section>
      </div>
    </div>
  );
};

export default Privacy;
