import React, { PureComponent } from 'react';
import ButtonLink from '../ButtonLink';
import { IdH3 } from '../IdHead';
import IdProps from '../../proptypes/id';
import { MMDDYYYY } from '../../utils/time';
import { popup } from '../../utils/popup';
import Expand from '../../img/icons/expand-bar.svg';
import style from '../../style/components/wallet/Id.module.scss';

class Expired extends PureComponent {
  static propTypes = {
    ...IdProps,
  };

  state = {
    collapsed: true,
  };

  toggleCollapse = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  handleReverify = (e) => {
    e.preventDefault();
    const { url } = this.props;
    popup('id', url);
  };

  renderCollapsed() {
    const { color, credential_icon_url, expires, stars, subtitle, title } =
      this.props;

    return (
      <button
        className={style.idCollapsed}
        onClick={this.toggleCollapse}
        aria-expanded="false"
      >
        <IdH3
          color={color}
          iconUrl={credential_icon_url}
          stars={stars}
          title={title}
        />
        <div className={style.idBody}>
          <div className={style.left}>{subtitle}</div>
          <div className={style.expired}>
            <div>EXPIRED</div>
            <div>{MMDDYYYY(expires)}</div>
          </div>
        </div>
        <div className={style.idExpandBar}>
          <img src={Expand} alt="Expand content bar" />
        </div>
      </button>
    );
  }

  renderExpanded() {
    const {
      color,
      credential_icon_url,
      expires,
      stars,
      subtitle,
      title,
      verified,
      url,
    } = this.props;

    return (
      <div className={style.idExpanded}>
        <IdH3
          color={color}
          iconUrl={credential_icon_url}
          stars={stars}
          title={title}
        />
        <div className={style.idBody}>
          <div className={style.left}>{subtitle}</div>
          <div className={style.expired}>
            <div>EXPIRED</div>
            <div>{MMDDYYYY(expires)}</div>
            <div>VERIFIED</div>
            <div>{MMDDYYYY(verified)}</div>
          </div>
        </div>
        {url && (
          <div className={style.expiredDetails}>
            <p>
              Some of the information you&#39;ve provided has gone out of date.
              Please reverify your identification to keep your ID current
            </p>
            <a href={url} onClick={this.handleReverify}>
              Reverify ID
            </a>
          </div>
        )}
        <div className={style.button}>
          <ButtonLink onClick={this.toggleCollapse}>collapse</ButtonLink>
        </div>
      </div>
    );
  }

  render() {
    const { collapsed } = this.state;

    return (
      <div className={style.idOuter}>
        {collapsed ? this.renderCollapsed() : this.renderExpanded()}
      </div>
    );
  }
}

export default Expired;
