import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import EmailConfirm from '../containers/EmailConfirm';
import style from '../style/pages/EmailConfirm.module.scss';
import { getReactEnv } from '../constants';

const ConfirmationPage = ({ match = { params: {} } }) => {
  useEffect(() => {
    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  return (
    <div className={style.content}>
      <EmailConfirm token={match.params.id} />
    </div>
  );
};

export default ConfirmationPage;
