import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import style from '../style/pages/Home.module.scss';
import { getReactEnv } from '../constants';

const Four04 = () => {
  useEffect(() => {
    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  return (
    <main className={style.main}>
      <h1>404 - Page not found</h1>
      <p>The page you are looking for does not exist.</p>
      <Link to="/">Take me back to home</Link>
    </main>
  );
};

export default Four04;
