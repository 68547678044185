export const addressLine1 = (street1, street2) => {
  // Replace null or undefined with an empty string
  const safeStreet1 = street1 || '';
  const safeStreet2 = street2 || '';

  return `${safeStreet1}${
    safeStreet1 && safeStreet2 ? ', ' : ''
  }${safeStreet2}`;
};

export const addressLine2 = (city, state, zip) => {
  // Replace null or undefined with an empty string
  const safeCity = city || '';
  const safeState = state || '';
  const safeZip = zip || '';

  return `${safeCity}${
    safeCity && safeState ? ', ' : ''
  }${safeState} ${safeZip}`;
};

export const isValidUSZip = (sZip) => {
  return /^\d{5}(-\d{4})?$/.test(sZip);
};
