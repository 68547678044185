import axios from 'axios';
import { sendToSentry } from '../utils/error';
import { URL_FEATURE_FLAGS } from '../constants/urls';

export const FETCH_FEATURE_FLAGS_BEGIN = 'FETCH_FEATURE_FLAGS_BEGIN';
export const FETCH_FEATURE_FLAGS_SUCCESS = 'FETCH_FEATURE_FLAGS_SUCCESS';
export const FETCH_FEATURE_FLAGS_FAILURE = 'FETCH_FEATURE_FLAGS_FAILURE';

export const fetchFeatureFlags = () => async (dispatch) => {
  dispatch({ type: FETCH_FEATURE_FLAGS_BEGIN });

  try {
    const response = await axios.get(URL_FEATURE_FLAGS);

    dispatch({
      payload: response.data,
      type: FETCH_FEATURE_FLAGS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_FEATURE_FLAGS_FAILURE,
    });
    if (error instanceof TypeError) {
      sendToSentry(error);
    }
  }
};
