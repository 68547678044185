import React from 'react';
import StyledModal from './StyledModal';

const ConfirmationModal = ({ handleClose, isOpen, fname }) => {
  const thanks = `Thank you, ${fname}.`;

  return (
    <StyledModal
      isOpen={isOpen}
      closeModal={handleClose}
      title={thanks}
      cancelButton={{ text: 'Close' }}
    >
      <p>
        We’ve received your information and will be following up with the state
        workforce agency again on your behalf.
      </p>
    </StyledModal>
  );
};

export default ConfirmationModal;
