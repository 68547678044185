import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fetchLogins } from '../actions/logins';
import Logins from '../components/overview/Logins';

export class OverviewLogins extends Component {
  static propTypes = {
    actionFetchLogins: PropTypes.func.isRequired,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    logins: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    error: '',
    isFetching: false,
    logins: [],
  };

  componentDidMount() {
    const { actionFetchLogins, isFetching } = this.props;
    if (!isFetching) {
      actionFetchLogins();
    }
  }

  render() {
    const { error, isFetching, logins } = this.props;
    return <Logins isFetching={isFetching} logins={logins} error={error} />;
  }
}

export const mapStateToProps = (state) => ({ ...state.logins });

const mapDispatchToProps = {
  actionFetchLogins: fetchLogins,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OverviewLogins),
);
