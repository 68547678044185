import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserProps from '../proptypes/user';
import { fetchSupportRequests } from '../actions/help';
import SuccessCard from '../components/SuccessCard';
import SupportRequests from '../components/help/SupportRequests';

export class HelpRequestsContainer extends Component {
  static propTypes = {
    actionFetchSupportRequests: PropTypes.func.isRequired,
    user: UserProps.isRequired,
  };

  static defaultProps = {};

  componentDidMount() {
    const { actionFetchSupportRequests, user } = this.props;
    if (!user.isFetching) {
      actionFetchSupportRequests();
    }
  }

  componentDidUpdate(prevProps) {
    const { actionFetchSupportRequests, user } = this.props;

    // if user was fetching but is not anymore, fetch requests
    if (prevProps.user.isFetching && !user.isFetching) {
      actionFetchSupportRequests();
    }
  }

  render() {
    const { helpRequests, complete, isFetching, user, location } = this.props;

    const showSuccessCard = new URLSearchParams(location.search).has('success');

    return (
      <>
        {showSuccessCard && (
          <SuccessCard>
            <h2>Message received!</h2>
            <p>
              Our support team will review your ticket and be in touch soon.
            </p>
          </SuccessCard>
        )}
        {user && (
          <SupportRequests
            requests={helpRequests}
            complete={complete}
            isFetching={isFetching}
            user={user}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  helpRequests: state.help.helpRequests,
  isFetching: state.help.isFetching,
  successId: state.help.id,
  user: state.user,
});

const mapDispatchToProps = {
  actionFetchSupportRequests: fetchSupportRequests,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HelpRequestsContainer);
