import React, { memo } from 'react';
import PropTypes from 'prop-types';
import WebsitesSharedDataButton from './WebsitesSharedDataButton';
import RevokeWebsiteButton from './RevokeWebsiteButton';

import { MMDDYYYY, HHMM } from '../../utils/time';
import loginPropTypes from '../../proptypes/login';

const WebsitesRow = ({ site, style, account }) => {
  return (
    <tr>
      <td title="WEBSITE NAME:">
        <div className={style.bold}>{site.organization}</div>
      </td>
      <td title="AUTHORIZED:">
        <div className={style.bold}>{MMDDYYYY(site.timestamp)}</div>
        <div>{HHMM(site.timestamp)}</div>
      </td>
      <td title="ACTIONS:" className={style.actions}>
        <WebsitesSharedDataButton site={site} />
        <RevokeWebsiteButton site={site} account={account} />
      </td>
    </tr>
  );
};

WebsitesRow.propTypes = {
  account: loginPropTypes.isRequired,
  site: loginPropTypes.isRequired,
  style: PropTypes.shape({}).isRequired,
};

export default memo(WebsitesRow);
