import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import setupAxios from './utils/axios';
import ErrorBoundary from './components/ErrorBoundary';
import SentryConfig from './components/SentryConfig';
import App from './App';
import { GA4_TRACKING_ID, getReactEnv } from './constants';
import configureStore from './redux';
import ReactGA4 from 'react-ga4';
import 'normalize.css';
import './style/base.scss';

if (GA4_TRACKING_ID) {
  ReactGA4.initialize([{ trackingId: GA4_TRACKING_ID }]);
}

// TODO: remove old GA when it is not used anymore
if (getReactEnv() === 'production') {
  import('react-ga').then((ReactGA) => {
    ReactGA.initialize('UA-40672673-1');
  });
}

const store = configureStore();

setupAxios(store);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <App />
        <SentryConfig />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
