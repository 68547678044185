import React, { useEffect } from 'react';
import { sessionCheck, sessionEnd } from '../actions/session';
import { useDispatch, useSelector } from 'react-redux';
import LoadingPage from '../components/LoadingPage';

const Logout = () => {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);

  useEffect(() => {
    if (!session.isFetching) {
      if (session.isLoggedIn) {
        dispatch(sessionEnd());
      } else {
        dispatch(sessionCheck());
      }
    }
  }, [dispatch, session]);

  return <LoadingPage />;
};

export default Logout;
