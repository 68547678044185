import axios from 'axios';
import { URL_ACTIVITIES } from '../constants/urls';

export const FETCH_ACCOUNT_ACTIVITIES_BEGIN = 'FETCH_ACCOUNT_ACTIVITIES_BEGIN';
export const FETCH_ACCOUNT_ACTIVITIES_SUCCESS =
  'FETCH_ACCOUNT_ACTIVITIES_SUCCESS';
export const FETCH_ACCOUNT_ACTIVITIES_FAILURE =
  'FETCH_ACCOUNT_ACTIVITIES_FAILURE';

export const DEFAULT_ACTIVITY_TYPE = '*';

export const fetchActivities =
  (type = DEFAULT_ACTIVITY_TYPE) =>
  async (dispatch, getState) => {
    dispatch({
      type: FETCH_ACCOUNT_ACTIVITIES_BEGIN,
    });

    const page = getState().activities.page[type];

    try {
      let url = URL_ACTIVITIES;
      if (page) {
        url = `${URL_ACTIVITIES}?page=${page}&type=${type}`;
      } else {
        url = `${URL_ACTIVITIES}?type=${type}`;
      }

      const response = await axios.get(url);

      dispatch({
        complete: response.data.length === 0,
        key: type,
        payload: response.data,
        type: FETCH_ACCOUNT_ACTIVITIES_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.message,
        type: FETCH_ACCOUNT_ACTIVITIES_FAILURE,
      });
    }
  };
