import {
  FETCH_WEBSITES_BEGIN,
  FETCH_WEBSITES_SUCCESS,
  FETCH_WEBSITES_FAILURE,
  FETCH_DELETE_WEBSITE_BEGIN,
  FETCH_DELETE_WEBSITE_SUCCESS,
  FETCH_DELETE_WEBSITE_FAILURE,
} from '../actions/websites';

export const initialState = {
  error: null,
  isFetching: false,
  websites: [],
};

const logins = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_WEBSITES_BEGIN:
    case FETCH_DELETE_WEBSITE_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_WEBSITES_SUCCESS:
    case FETCH_DELETE_WEBSITE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        websites: action.payload,
      };
    case FETCH_WEBSITES_FAILURE:
    case FETCH_DELETE_WEBSITE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default logins;
