import React, { Component } from 'react';
import CaliforniaForm from '../components/california/Form';
import NotCalifornia from '../components/california/NotCalifornia';
import LinkToSignIn from '../components/california/LinkToSignIn';

class CaliforniaFlowContainer extends Component {
  state = {
    radio: '',
    step: '0',
  };

  componentDidMount() {
    const step = window.sessionStorage.getItem('california-step');
    this.setState({ step });
  }

  updateSessionStorage = () => {
    const { step } = this.state;
    window.sessionStorage.setItem('california-step', step);
  };

  handleRadioChange = (e) => {
    this.setState({ radio: e.target.value });
  };

  handleSubmit = (e) => {
    e && e.preventDefault && e.preventDefault();
    const { radio } = this.state;

    if (radio === 'no') {
      this.setState({ step: '1' }, this.updateSessionStorage);
    } else if (radio === 'yes') {
      this.setState({ step: '2' }, this.updateSessionStorage);
    }
  };

  render() {
    const { radio, step } = this.state;
    if (step === '0') {
      return (
        <CaliforniaForm
          currentValue={radio}
          handleRadioChange={this.handleRadioChange}
          handleSubmit={this.handleSubmit}
        />
      );
    }
    if (step === '1') {
      return <NotCalifornia />;
    }
    if (step === '2') {
      return <LinkToSignIn />;
    }
    return null;
  }
}

export default CaliforniaFlowContainer;
