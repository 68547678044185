import React from 'react';
import StyledModal from '../StyledModal';
import style from '../../style/components/linkages/WebsiteInfoModal.module.scss';

const WebsitesSharedModal = ({ closeModal, isOpen, site }) => {
  return (
    <StyledModal
      isOpen={isOpen}
      closeModal={closeModal}
      title={site.organization}
      cancelButton={{
        text: 'Close window',
      }}
    >
      <div className={style.body}>
        <p>
          You&#39;re sharing the following information with&nbsp;
          <strong>{site.organization}:</strong>
        </p>
        <ul>
          {site.properties.map((prop) => (
            <li key={prop.handle}>{prop.name}</li>
          ))}
        </ul>
      </div>
    </StyledModal>
  );
};

export default WebsitesSharedModal;
