import React from 'react';
import StyledModal from './StyledModal';

const SuccessModal = ({ text, title = 'Success!', closeModal, isOpen }) => {
  return (
    <StyledModal
      isOpen={isOpen}
      closeModal={closeModal}
      title={title}
      cancelButton={{ text: 'Close' }}
    >
      <p>{text}</p>
    </StyledModal>
  );
};

export default SuccessModal;
