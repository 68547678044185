import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LabelTextError from '../LabelTextError';
import LabelTextArea from '../LabelTextArea';
import ButtonPrimary from '../ButtonPrimary';
import ButtonSecondary from '../ButtonSecondary';
import QuestionTree from './QuestionTree';
import { fetchSupportRequestFields } from '../../actions/help';
import LoadingSpinner from '../LoadingSpinner';
import style from '../../style/components/help/HelpRequestForm.module.scss';
import InfoCallout from '../InfoCallout';
import EmailsModal from '../EmailsModal';
import ButtonLink from '../ButtonLink';

const FULL_NAME = 'full_name';
const DESCRIPTION = 'description';

const initialValues = {
  full_name: '',
};
function getInitialValues({ full_name }) {
  const values = { ...initialValues };
  if (full_name) {
    values.full_name = full_name;
  }
  return values;
}

function isValid(d) {
  return !!d;
}

function isDescription(d) {
  return !!d;
}

function validate(formData) {
  const errors = {};

  for (const [key, value] of Object.entries(formData)) {
    if (key === FULL_NAME) {
      if (!isValid(value)) {
        errors[key] = 'Name is required';
      }
    } else if (key === DESCRIPTION) {
      if (!isDescription(value)) {
        errors[key] = 'Description is required';
      }
    } else {
      if (!value) {
        errors[key] = 'Please select an option';
      }
    }
  }

  return errors;
}

const HelpRequestForm = ({ createHelpRequest, handleReset, user }) => {
  const formEl = useRef(null);

  const [values, setValues] = useState(getInitialValues(user));
  const handleValueChange = useCallback(
    (e) => {
      const { target } = e;

      setValues({ ...values, [target.name]: target.value });
    },
    [setValues, values],
  );

  const [description, setDescription] = useState('');
  const handleDescriptionChange = useCallback(
    (e) => {
      setDescription(e.target.value);
    },
    [setDescription],
  );

  const [errors, setErrors] = useState(false);
  const checkValidation = useCallback(
    (e) => {
      const { target } = e;
      const newErrors = validate({ [target.name]: target.value });

      if (!newErrors[target.name]) {
        delete errors[target.name];
        setErrors({ ...errors });
      }
    },
    [errors, setErrors],
  );

  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      // get all the data out of the question tree
      const formData = Object.fromEntries(new FormData(formEl.current));
      const newErrors = validate(formData);
      setErrors(newErrors);
      if (!Object.keys(newErrors).length) {
        setSubmitting(true);

        await createHelpRequest(formData);
        setSubmitting(false);
      }
    },
    [createHelpRequest, formEl, setErrors],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSupportRequestFields());
  }, [dispatch]);

  const ticketFields = useSelector((state) => state.help.ticketFields);

  const [isEmailsModalOpen, setIsEmailsModalOpen] = useState(false);

  return ticketFields?.root_fields ? (
    <form ref={formEl} onSubmit={handleSubmit}>
      <EmailsModal
        isOpen={isEmailsModalOpen}
        handleCancel={() => setIsEmailsModalOpen(false)}
      />
      <div className={style.card}>
        <h1>How can we help you?</h1>
        <InfoCallout>
          <p>
            <strong>
              Did you check out our <a href="https://help.id.me">Help Center</a>
              ? Our support agent's favorite tips are there.
            </strong>
          </p>
          <p>
            If you can't find what you need, carefully fill out this form. We'll
            use your answers to route your ticket to the best team.
          </p>
        </InfoCallout>
        {!(user.fname || user.lname) && (
          <LabelTextError
            name={FULL_NAME}
            label="Full Name"
            value={values[FULL_NAME]}
            error={errors[FULL_NAME]}
            onChange={handleValueChange}
            onBlur={checkValidation}
            required
          />
        )}
        {/* NOTE: Email is not actually sent as part of the request. It is based on the user's primary email */}
        <LabelTextError value={user.email} label="Email Address" readOnly />
        <p className={style.fieldDetailText}>
          Not the best email to reach you?{' '}
          <ButtonLink onClick={() => setIsEmailsModalOpen(true)}>
            Update your primary email
          </ButtonLink>
          .
        </p>

        {ticketFields.root_fields.map((field) => (
          <QuestionTree
            key={field.id}
            id={field.id}
            label={field.label}
            options={field.options}
            fieldConditions={ticketFields.field_conditions}
            errors={errors}
            onBlur={checkValidation}
          />
        ))}

        <LabelTextArea
          name={DESCRIPTION}
          label="What do you need help with?"
          value={description}
          error={errors[DESCRIPTION]}
          onChange={handleDescriptionChange}
          onBlur={checkValidation}
          required
        />
        <p className={style.fieldDetailText}>
          Please describe your issue in as much detail as possible, including
          the state or organization you are verifying for. A member of our
          support staff will respond to you soon.
        </p>
        <div className={style.buttons}>
          <ButtonSecondary onClick={handleReset} disabled={submitting}>
            Reset
          </ButtonSecondary>
          <ButtonPrimary type="submit" disabled={submitting}>
            Submit
          </ButtonPrimary>
        </div>
      </div>
    </form>
  ) : (
    <LoadingSpinner className={style.spinner} />
  );
};

export default HelpRequestForm;
