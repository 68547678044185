import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toFixed } from '../../utils/number';
import cashbackIcon from '../../img/icons/cashback.svg';
import { SHOP_URL } from '../../constants';
import style from '../../style/components/overview/Cashback.module.scss';

const Cashback = ({ cashBackLifetime }) => {
  const { t } = useTranslation();

  return (
    <article className={style.cashback}>
      <h3 className={style.title}>
        <img src={cashbackIcon} alt="" />
        {t('overview.myCashback')}
      </h3>
      {cashBackLifetime > 0 && (
        <div className={style.total}>
          <div className={style.subtitle}>{t('overview.lifetimeCashback')}</div>
          <div className={style.money}>${toFixed(cashBackLifetime)}</div>
        </div>
      )}
      <section className={style.manage}>
        <a href={`${SHOP_URL}/cash-back/purchases`}>
          {t('overview.viewCashback')}
        </a>
      </section>
    </article>
  );
};

Cashback.propTypes = {
  cashBackLifetime: PropTypes.number.isRequired,
};

Cashback.defaultProps = {
  cashBackLifetime: 0,
};

export default Cashback;
