import React, { memo } from 'react';
import PropTypes from 'prop-types';
import style from '../style/components/Slider.module.scss';

const Slider = ({ checked, handleClick, id, label }) => (
  <label id={id} className={style.label}>
    <span>{label}</span>
    <button
      className={style.slider}
      onClick={handleClick}
      type="button"
      role="switch"
      aria-checked={checked}
      aria-labelledby={id}
    >
      <span className={style.span} />
    </button>
  </label>
);

Slider.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default memo(Slider);
