import React, { memo } from 'react';
import PropTypes from 'prop-types';
import style from '../style/components/SearchBar.module.scss';

const preventDefault = (e) => e.preventDefault();

const SearchBar = ({ handleChange, handleSearch, search }) => (
  <form className={style.search} onSubmit={handleSearch || preventDefault}>
    <label htmlFor="search">
      <span className={style.srOnly}>Search</span>
      <input
        id="search"
        type="text"
        onChange={handleChange}
        value={search}
        placeholder="Search Keywords, URLs"
      />
    </label>
    {handleSearch ? (
      <button type="button" onClick={handleSearch}>
        <span aria-label="search" role="img">
          &#128269;
        </span>
      </button>
    ) : (
      <div>
        <span aria-label="search" role="img">
          &#128269;
        </span>
      </div>
    )}
  </form>
);

SearchBar.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  search: PropTypes.string.isRequired,
};

SearchBar.defaultProps = {
  handleSearch: false,
};

export default memo(SearchBar);
