import React, { Fragment } from 'react';
import IDmeLogo from '../../img/idme-white.svg';
import style from '../../style/components/california/LinkToSignIn.module.scss';

const LinkToSignIn = () => (
  <Fragment>
    <h1>Verify your identity</h1>
    <p>
      Please sign in to your ID.me account. You'll need to verify your personal
      information in order to continue with the request. We do not hold
      information on users who do not have an account with ID.me. For additional
      questions, <a href="mailto:privacy@id.me?subject='CCPA'">contact us</a>.
    </p>
    <a className={style.link} href="/manage-data">
      <span>Sign In to</span>
      <img src={IDmeLogo} alt="ID.me" />
    </a>
  </Fragment>
);

export default LinkToSignIn;
