import axios from 'axios';
import { URL_AGREEMENTS } from '../constants/urls';

export const FETCH_AGREEMENTS_BEGIN = 'FETCH_AGREEMENTS_BEGIN';
export const FETCH_AGREEMENTS_SUCCESS = 'FETCH_AGREEMENTS_SUCCESS';
export const FETCH_AGREEMENTS_FAILURE = 'FETCH_AGREEMENTS_FAILURE';
export const FETCH_CONFIRM_AGREEMENT_BEGIN = 'FETCH_CONFIRM_AGREEMENT_BEGIN';
export const FETCH_CONFIRM_AGREEMENT_SUCCESS =
  'FETCH_CONFIRM_AGREEMENT_SUCCESS';
export const FETCH_CONFIRM_AGREEMENT_FAILURE =
  'FETCH_CONFIRM_AGREEMENT_FAILURE';

export const fetchAgreements = () => async (dispatch) => {
  dispatch({ type: FETCH_AGREEMENTS_BEGIN });
  try {
    const response = await axios.get(URL_AGREEMENTS);

    dispatch({
      payload: response.data,
      type: FETCH_AGREEMENTS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error,
      type: FETCH_AGREEMENTS_FAILURE,
    });
  }
};

export const updateAgreement = (id) => async (dispatch) => {
  dispatch({ type: FETCH_CONFIRM_AGREEMENT_BEGIN });
  try {
    const response = await axios.post(URL_AGREEMENTS, { id });

    dispatch({
      payload: response.data,
      type: FETCH_CONFIRM_AGREEMENT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error,
      type: FETCH_CONFIRM_AGREEMENT_FAILURE,
    });
  }
};
