import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import LabelSelect from '../LabelSelect';

const QuestionTree = ({
  errors,
  label,
  onBlur,
  options,
  id,
  fieldConditions,
}) => {
  const [value, setValue] = useState('');
  const handleChange = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const fieldConditionKey = `${id}-${value}`;

  return (
    options && (
      <>
        <LabelSelect
          label={label}
          name={id}
          value={value}
          error={errors[id]}
          onChange={handleChange}
          onBlur={onBlur}
          options={options}
          required
          placeholder="Select an option"
        />
        {fieldConditions[fieldConditionKey]?.map((child) => (
          <QuestionTree
            key={child.id}
            id={child.id}
            label={child.label}
            options={child.options}
            fieldConditions={fieldConditions}
            errors={errors}
            onBlur={onBlur}
          />
        ))}
      </>
    )
  );
};

QuestionTree.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default QuestionTree;
