import {
  FETCH_STATES_BEGIN,
  FETCH_STATES_SUCCESS,
  FETCH_STATES_FAILURE,
} from '../actions/states';

export const initialState = {
  states: [],
  error: null,
  isFetching: false,
};

const states = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_STATES_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_STATES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        isFetching: false,
      };
    case FETCH_STATES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default states;
