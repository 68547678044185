import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fetchLinkedAccounts } from '../actions/linkedAccounts';
import LinkedAccounts from '../components/linkages/LinkedAccounts';
import AccessRequests from '../components/linkages/AccessRequests';
import LinkedAccountsWebsites from './LinkedAccountsWebsites';
import UserProps from '../proptypes/user';
import LoadingSpinner from '../components/LoadingSpinner';
import { Redirect } from 'react-router-dom';

export class LinkedAccountsAccounts extends Component {
  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.shape({})),
    actionFetchLinkedAccounts: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    linkageRequests: PropTypes.arrayOf(PropTypes.shape({})),
    user: UserProps.isRequired,
  };

  static defaultProps = {
    accounts: [],
    linkageRequests: [],
  };

  componentDidMount() {
    const { isFetching, actionFetchLinkedAccounts } = this.props;
    if (!isFetching) {
      actionFetchLinkedAccounts();
    }
  }

  render() {
    const { isFetching, accounts, linkageRequests, user } = this.props;

    if (isFetching) {
      return <LoadingSpinner />;
    }

    if (!user.can_link_accounts && !user.isFetching) {
      return <Redirect to="/overview" />;
    }

    return (
      <Fragment>
        <LinkedAccounts accounts={accounts} isFetching={isFetching} />
        <AccessRequests
          linkageRequests={linkageRequests}
          isFetching={isFetching}
        />
        <LinkedAccountsWebsites accounts={accounts} isFetching={isFetching} />
      </Fragment>
    );
  }
}

export const mapStateToProps = (state) => ({
  user: state.user,
  ...state.linkedAccounts,
});

const mapDispatchToProps = {
  actionFetchLinkedAccounts: fetchLinkedAccounts,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LinkedAccountsAccounts),
);
