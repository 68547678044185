import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Alert from '../img/icons/notice.svg';
import style from '../style/components/overview/DataDownload.module.scss';

const DataDownloadReady = () => {
  return (
    <div className={style.data}>
      <div>
        <h2>Ready for download</h2>
        <div className={style.description}>
          <img src={Alert} alt="" />
          <span>
            <strong>Please note:</strong> Your data file can only be downloaded
            once.
          </span>
        </div>
      </div>
      <Link className={style.button} to="signin/privacy#data-request">
        Go To Download
      </Link>
    </div>
  );
};

export default DataDownloadReady;
