/**
 * Combines strings with comma, undefined values and empty strings are ignored
 *
 * @param {Array} stringList - Contains a list of strings to be combined, can contain undefined entries
 * @returns A string when at least one string is defined, undefined otherwise
 */
const combineStrings = (stringList) => {
  if (!stringList) {
    return;
  }

  // Remove any undefined elements and empty strings
  const filteredList = stringList.filter((element) => element);

  // Return undefined when there aren't any strings to combine
  if (!filteredList.length) {
    return;
  }

  return filteredList.join(', ');
};

export default combineStrings;
