import axios from 'axios';
import { URL_ALERTS } from '../constants/urls';

export const FETCH_ALERTS_BEGIN = 'FETCH_ALERTS_BEGIN';
export const FETCH_ALERTS_SUCCESS = 'FETCH_ALERTS_SUCCESS';
export const FETCH_ALERTS_FAILURE = 'FETCH_ALERTS_FAILURE';

export const CONFIRM_ALERTS_BEGIN = 'CONFIRM_ALERTS_BEGIN';
export const CONFIRM_ALERTS_SUCCESS = 'CONFIRM_ALERTS_SUCCESS';
export const CONFIRM_ALERTS_FAILURE = 'CONFIRM_ALERTS_FAILURE';

export const fetchAlerts = () => async (dispatch) => {
  dispatch({ type: FETCH_ALERTS_BEGIN });
  try {
    const response = await axios.get(URL_ALERTS);

    dispatch({
      payload: response.data,
      type: FETCH_ALERTS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_ALERTS_FAILURE,
    });
  }
};

export const confirmAlerts = () => async (dispatch) => {
  dispatch({ type: CONFIRM_ALERTS_BEGIN });
  try {
    const response = await axios.post(URL_ALERTS);

    dispatch({
      payload: response.data,
      type: CONFIRM_ALERTS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: CONFIRM_ALERTS_FAILURE,
    });
  }
};
