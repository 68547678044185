import axios from 'axios';
import { URL_WEBSITES } from '../constants/urls';
import { checkResponseToBeArray } from '../utils/axios';

export const FETCH_WEBSITES_BEGIN = 'FETCH_WEBSITES_BEGIN';
export const FETCH_WEBSITES_SUCCESS = 'FETCH_WEBSITES_SUCCESS';
export const FETCH_WEBSITES_FAILURE = 'FETCH_WEBSITES_FAILURE';

export const FETCH_DELETE_WEBSITE_BEGIN = 'FETCH_DELETE_WEBSITE_BEGIN';
export const FETCH_DELETE_WEBSITE_SUCCESS = 'FETCH_DELETE_WEBSITE_SUCCESS';
export const FETCH_DELETE_WEBSITE_FAILURE = 'FETCH_DELETE_WEBSITE_FAILURE';

export const fetchWebsites = () => async (dispatch) => {
  dispatch({ type: FETCH_WEBSITES_BEGIN });

  try {
    const response = await axios.get(URL_WEBSITES);

    checkResponseToBeArray(response);

    dispatch({
      payload: response.data,
      type: FETCH_WEBSITES_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_WEBSITES_FAILURE,
    });
  }
};

export const deleteWebsite = (id) => async (dispatch) => {
  dispatch({ type: FETCH_DELETE_WEBSITE_BEGIN });

  try {
    const response = await axios.delete(`${URL_WEBSITES}/${id}`);

    checkResponseToBeArray(response);

    dispatch({
      payload: response.data,
      type: FETCH_DELETE_WEBSITE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_DELETE_WEBSITE_FAILURE,
    });
  }
};
