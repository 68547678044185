import React, { memo } from 'react';
import PropTypes from 'prop-types';
import AccountActivitiesTable from './AccountActivitiesTable';
import ButtonSecondary from '../ButtonSecondary';
import activityIcon from '../../img/icons/activities.svg';
import style from '../../style/components/signin/AccountActivities.module.scss';
import Card from '../Card';

const tabs = [
  { key: '*', name: 'All' },
  { key: 'signin', name: 'Sign ins' },
  { key: 'connection', name: 'Connect' },
  { key: 'disconnect', name: 'Disconnect' },
  { key: 'verification', name: 'Verifications' },
];

const AccountActivities = ({
  activeTab,
  activities,
  complete,
  handleLoadMore,
  handleTabChange,
  isFetching,
}) => (
  <Card
    className={style.card}
    title="Account Activities"
    icon={<img alt="activities" src={activityIcon} />}
  >
    <div className={style.tabs}>
      {tabs.map((tab) => (
        <button
          key={tab.name}
          type="button"
          onClick={() => handleTabChange(tab)}
          className={activeTab.key === tab.key ? style.active : ''}
        >
          {tab.name}
        </button>
      ))}
    </div>
    <AccountActivitiesTable
      activities={activities}
      activeTab={activeTab}
      isFetching={isFetching}
    />
    {complete && activities.length > 0 && (
      <p>You've reached the end of your account activity.</p>
    )}
    {!complete && !isFetching && (
      <ButtonSecondary onClick={handleLoadMore} disabled={isFetching}>
        Load More
      </ButtonSecondary>
    )}
  </Card>
);

AccountActivities.propTypes = {
  activeTab: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      authorized: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  complete: PropTypes.bool.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  handleTabChange: PropTypes.func.isRequired,
};

export default memo(AccountActivities);
