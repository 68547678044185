import React from 'react';
import style from '../style/components/FormInputs.module.scss';

const InputWithError = ({
  error,
  name,
  inputRef,
  required,
  label,
  noMargin,
  inputMode = 'text',
  ...rest
}) => (
  <label
    htmlFor={name}
    className={`${style.wrapper} ${noMargin ? style.noMargin : ''}`}
  >
    <div className={style.label}>
      {label}
      {required && <span className={style.required}>&nbsp;*</span>}
    </div>
    <input
      id={name}
      inputMode={inputMode}
      name={name}
      type="text"
      ref={inputRef}
      {...rest}
    />
    {error && (
      <div className={style.error} role="alert">
        <strong>Error: </strong>
        {error}
      </div>
    )}
  </label>
);

export default InputWithError;
