import React, { Fragment, useState } from 'react';
import RevokeLinkageModal from './RevokeLinkageModal';

const RevokeLinkageButton = ({ account }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Fragment>
      <idme-button
        variant="secondary"
        size="small"
        text="Revoke"
        layout="content"
        onClick={() => setIsModalOpen(true)}
      />
      <RevokeLinkageModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        account={account}
      />
    </Fragment>
  );
};

export default RevokeLinkageButton;
