import React, { useRef, useEffect, useCallback } from 'react';
import style from '../style/components/DropdownOption.module.scss';

const DropdownOption = ({ text, onClick, setIsDropdownOpen }) => {
  const optionRef = useRef(null);

  const onClickWithCloseDropdown = useCallback(() => {
    onClick();
    setIsDropdownOpen(false);
  }, [onClick, setIsDropdownOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.key === 'Enter' &&
        document.activeElement === optionRef.current
      ) {
        onClickWithCloseDropdown();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClickWithCloseDropdown]);

  return (
    <li
      ref={optionRef}
      role="button"
      className={style.dropdownOption}
      onClick={onClickWithCloseDropdown}
      tabIndex={0}
    >
      {text}
    </li>
  );
};

export default DropdownOption;
