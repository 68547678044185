import { formatDistanceStrict, format, parse } from 'date-fns';
export { addDays } from 'date-fns';

export const now = () => new Date();

export const getElapsedTime = (timeString) => {
  if (!timeString) {
    return null;
  }
  return `${formatDistanceStrict(now(), new Date(timeString))} ago`;
};

export const MMDDYYYY = (timeString) => {
  if (!timeString) {
    return null;
  }
  return format(new Date(timeString), 'MM/dd/yyyy');
};

export const MMMMYYYY = (timeString) => {
  if (!timeString) {
    return null;
  }
  return format(new Date(timeString), 'MMMM yyyy');
};

export const HHMM = (timeString) => {
  if (!timeString) {
    return null;
  }
  return format(new Date(timeString), 'h:mm a');
};

export const MMMDYYYY = (timeString) => {
  if (!timeString) {
    return null;
  }
  return format(new Date(timeString), 'MMM d, yyyy');
};

export const YYYYMMDD = (timeString) => {
  if (!timeString) {
    return null;
  }
  return format(new Date(timeString), 'yyyy-MM-dd');
};

export const parseMMDDYYYY = (input) => {
  if (!input) {
    return null;
  }

  return parse(input, 'MM/dd/yyyy', now());
};
