import React, { useCallback, useState } from 'react';
import * as SmartySDK from 'smartystreets-javascript-sdk';
import debounce from 'lodash.debounce';
import style from '../../style/components/changeRequests/SmartyStreetsAutocomplete.module.scss';
import InputWithError from '../InputWithError';
import { SMARTYSTREETS_KEY } from '../../constants';

const MAX_SUGGESTIONS = 5;
const SmartyCore = SmartySDK.core;
const smartySharedCredentials = new SmartyCore.SharedCredentials(
  SMARTYSTREETS_KEY,
);
const autoCompleteClientBuilder = new SmartyCore.ClientBuilder(
  smartySharedCredentials,
).withLicenses(['us-autocomplete-pro-cloud']);
const autoCompleteClient =
  autoCompleteClientBuilder.buildUsAutocompleteProClient();

const SmartyStreetsAutocomplete = ({
  handleSelectSuggestion,
  handleChange,
  ...rest
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [showSuggestions, setShowSuggestions] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSmartyStreetsAPI = useCallback(
    debounce((query) => {
      // if the field is empty, do not query
      if (!query) {
        return;
      }

      const lookup = new SmartySDK.usAutocompletePro.Lookup(query);
      lookup.maxResults = MAX_SUGGESTIONS;

      autoCompleteClient
        .send(lookup)
        .then((results) => {
          // Items with multiple entries are intended to be followed up with a request to get apartment or suite numbers.
          // For now, we are ignoring those.
          setSuggestions(results.result.filter((r) => r.entries === 0));
        })
        .catch(console.warn);
    }, 500),
    [setSuggestions],
  );

  const cycleHighlightedItem = (up) => {
    // handle wrapping around using the arrow keys
    // -1 is used to indicate the input/nothing is highlighted
    if (highlightedIndex < 0 && up) {
      setHighlightedIndex(suggestions.length - 1);
    } else if (highlightedIndex >= suggestions.length - 1 && !up) {
      setHighlightedIndex(-1);
    } else {
      setHighlightedIndex(up ? highlightedIndex - 1 : highlightedIndex + 1);
    }
  };

  const selectSuggestionByIndex = useCallback(
    (index) => {
      if (index < 0 || index > suggestions.length) {
        return;
      }

      const {
        streetLine: street,
        secondary: street2,
        city,
        state,
        zipcode: zip,
      } = suggestions[index];
      handleSelectSuggestion({ city, state, street, street2, zip });
    },
    [suggestions, handleSelectSuggestion],
  );

  const onKeyDown = (e) => {
    const KEYCODES = {
      DOWN: 'ArrowDown',
      ENTER: 'Enter',
      TAB: 'Tab',
      UP: 'ArrowUp',
    };

    switch (e.code) {
      case KEYCODES.UP:
      case KEYCODES.DOWN:
        e.preventDefault();

        cycleHighlightedItem(e.code === KEYCODES.UP);
        break;
      case KEYCODES.ENTER:
        e.preventDefault();
        selectSuggestionByIndex(highlightedIndex);
        break;
      default:
      // do nothing
    }
  };

  return (
    <>
      <InputWithError
        onChange={(e) => {
          handleChange(e);
          debouncedSmartyStreetsAPI(e.target.value);
        }}
        onKeyDown={onKeyDown}
        onFocus={() => setShowSuggestions(true)}
        onBlur={() => setShowSuggestions(false)}
        {...rest}
      />
      {showSuggestions && suggestions.length ? (
        <ul className={style.addressSuggestions}>
          {suggestions.map((obj, i) => {
            return (
              <li
                key={'suggestion' + i}
                className={i === highlightedIndex ? style.highlighted : null}
                onMouseDown={() => selectSuggestionByIndex(i)}
              >
                <span>{obj.streetLine}</span> <span>{obj.secondary}</span>{' '}
                <span className={style.suggestionArea}>
                  {obj.city}, {obj.state} {obj.zipcode}
                </span>
              </li>
            );
          })}
        </ul>
      ) : null}
    </>
  );
};

export default SmartyStreetsAutocomplete;
