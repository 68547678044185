import React, { useEffect, useState } from 'react';
import style from '../../style/components/signin/RemoveDataModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import SelfieDeletionModal from './SelfieDeletionModal';
import CloseAccountModal from './CloseAccountModal';
import { EXISTS, PENDING, SELFIE_DELETE } from '../../constants';
import { fetchDataRequestByType } from '../../actions/dataRequests';
import StyledModal from '../StyledModal';

const RemoveDataModal = ({ closeModal, isOpen }) => {
  const [isSelfieDeletionModalOpen, setIsSelfieDeletionModalOpen] =
    useState(false);
  const [isCloseAccountModalOpen, setIsCloseAccountModalOpen] = useState(false);

  const selfieStatus = useSelector((state) => state.dataRequests.selfie);

  const dispatch = useDispatch();
  useEffect(() => {
    // fetch selfie status upon modal open
    if (isOpen) {
      dispatch(fetchDataRequestByType(SELFIE_DELETE));
    }
  }, [isOpen, dispatch]);

  const REQUESTS = [];

  if (selfieStatus === EXISTS) {
    REQUESTS.push({
      description:
        'Removing a selfie from your account may make account recovery more difficult.',
      title: 'Delete my selfie',
      onClick: () => {
        setIsSelfieDeletionModalOpen(true);
        closeModal();
      },
    });
  } else if (selfieStatus === PENDING) {
    REQUESTS.push({
      description:
        'We are in the process of deleting your selfie. It may take up to seven days to be removed.',
      disabled: true,
      title: 'Delete my selfie (pending)',
    });
  } else {
    REQUESTS.push({
      description:
        'Removing a selfie from your account may make account recovery more difficult.',
      disabled: true,
      more_info: (
        <p>
          <strong>No selfie on file.</strong>
        </p>
      ),
      title: 'Delete my selfie',
    });
  }

  REQUESTS.push({
    description:
      'Within seven days, your account information will be removed. Once complete, you may use the same email address to create a new ID.me account.',
    title: 'Close my account',
    onClick: () => {
      setIsCloseAccountModalOpen(true);
      closeModal();
    },
  });

  return (
    <>
      <StyledModal
        isOpen={isOpen}
        closeModal={closeModal}
        title="What would you like to remove?"
      >
        <p>
          Please note that ID.me will retain a portion of your account's
          attributes on file to comply with applicable laws and help us prevent
          fraud.
        </p>

        {REQUESTS.map((request) => {
          return (
            <div
              key={request.title}
              className={`${style.tile} ${
                request.disabled ? style.disabledTile : ''
              }`}
              onClick={request.onClick}
            >
              <div className={style.tileBody}>
                <div className={style.content}>
                  <h4
                    className={`${style.heading} ${
                      request.disabled ? style.disabledHeading : ''
                    }`}
                  >
                    {request.title}
                  </h4>
                  <div>
                    <p>{request.description}</p>
                    {request.more_info}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </StyledModal>
      <SelfieDeletionModal
        isOpen={isSelfieDeletionModalOpen}
        closeModal={() => setIsSelfieDeletionModalOpen(false)}
      />
      <CloseAccountModal
        isOpen={isCloseAccountModalOpen}
        closeModal={() => setIsCloseAccountModalOpen(false)}
      />
    </>
  );
};

export default RemoveDataModal;
