import React from 'react';
import style from '../style/components/AlertBanner.module.scss';

const CONFIRM = 'confirm';

const AlertBanner = ({
  cta,
  ctas,
  handleClick,
  iconUrl,
  loading,
  text,
  type,
  url,
  visible,
  customAlertName,
}) => {
  if (!visible) {
    return null;
  }

  let ctaElement;

  if (type === CONFIRM && cta && handleClick) {
    ctaElement = (
      <button className={style.cta} onClick={handleClick} disabled={loading}>
        {cta}
      </button>
    );
  } else if (cta && url) {
    ctaElement = (
      <a className={style.cta} href={url}>
        {cta}
      </a>
    );
  } else if (customAlertName === 'policies_and_promise') {
    ctaElement = (
      <div className={style.ctas}>
        <div className={style.policy}>
          <span className={style.new}> NEW! </span> Read our updated{' '}
          <a href="http://id.me/privacy">Privacy Policy</a> and{' '}
          <a href="http://id.me/biometric">Biometrics Policy</a>
        </div>
        <div className={style.sep} />
        <div className={style.promise}>
          Our Promise: Providing Secure, Equitable Access.{' '}
          <a href="https://network.id.me/id-me-our-promise/">Learn More</a>
        </div>
      </div>
    );
  }

  return (
    <div className={style.alert}>
      {iconUrl && <img src={iconUrl} alt="" />}
      {ctaElement}
    </div>
  );
};

export default AlertBanner;
