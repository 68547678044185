import React, { useCallback, useEffect, useState } from 'react';
import style from '../../style/components/profile/AddEmailModal.module.scss';
import ErrorAlertContainer from '../ErrorAlertContainer';
import IndicatesRequiredField from '../IndicatesRequiredField';
import StyledModal from '../StyledModal';
import { isEmpty } from '../../utils/isEmpty';
import { addEmail } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import InputWithError from '../InputWithError';
import { resetEmailState } from '../../actions/emails';
import validEmail from '../../utils/validEmail';
import EmailAddError from './EmailAddError';
import CheckboxGroup from '../CheckboxGroup';

function validate(formData, emailTypes, emailList) {
  const errors = {};

  if (isEmpty(formData.address)) {
    errors.address = 'Email address is required';
  } else if (!validEmail(formData.address)) {
    errors.address = 'Invalid email address';
  } else if (emailList.find((email) => email.address === formData.address)) {
    errors.address = 'Email already added to account';
  }

  // ensure at least one emailType is active
  if (!emailTypes.some((emailType) => emailType.active)) {
    errors.types = 'Email type is required';
  }

  return errors;
}

function initialFormData() {
  return {
    address: '',
  };
}

function initialEmailTypes() {
  return [
    { value: 'personal', label: 'Personal', active: false },
    { value: 'work', label: 'Work', active: false },
  ];
}

const AddEmailModal = ({ closeModal, isOpen }) => {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(initialFormData());
  const [emailTypes, setEmailTypes] = useState(initialEmailTypes());

  const toggleEmailType = (type) => {
    setEmailTypes(
      emailTypes.map((emailType) => {
        if (emailType.value === type) {
          return { ...emailType, active: !emailType.active };
        }
        return emailType;
      }),
    );
  };

  const emails = useSelector((state) => state.emails);

  const handleChange = (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setFormData({ ...formData, [event.target.name]: value });
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const newErrors = validate(formData, emailTypes, emails.emails);
      setErrors(newErrors);

      if (!Object.keys(newErrors).length) {
        const resultTypes = emailTypes
          .filter((emailType) => emailType.active)
          .map((emailType) => emailType.value);

        dispatch(addEmail(formData.address, resultTypes));
      }
    },
    [dispatch, emails, formData, emailTypes, setErrors],
  );

  useEffect(() => {
    // when successful response: close modal and refresh data
    if (emails.isSuccess) {
      dispatch(resetEmailState());
      closeModal();
    }
  }, [emails, dispatch, closeModal]);

  const submitting = emails.isFetching;

  return (
    <StyledModal
      isOpen={isOpen}
      closeModal={() => {
        dispatch(resetEmailState());
        closeModal();
      }}
      submitting={submitting}
      title="Add an email to your account"
      primaryButton={{
        hidden: false,
        text: 'Send confirmation',
        submittingText: 'Saving...',
      }}
      wrapper={(children) => (
        <form
          onSubmit={handleSubmit}
          noValidate={true}
          className={style.wrapper}
        >
          {children}
        </form>
      )}
    >
      <IndicatesRequiredField />
      <InputWithError
        name="address"
        placeholder="Enter your email"
        label="Email Address"
        value={formData.address}
        onChange={handleChange}
        error={errors['address']}
        type="email"
        required
      />
      <CheckboxGroup
        label="Email Type"
        name="types"
        options={emailTypes}
        toggleCheckbox={toggleEmailType}
        error={errors['types']}
        required
      />

      {emails.emailAddError && <EmailAddError style={style} />}
      <ErrorAlertContainer />
    </StyledModal>
  );
};

export default AddEmailModal;
