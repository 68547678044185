import {
  FETCH_CASHBACK_BEGIN,
  FETCH_CASHBACK_SUCCESS,
  FETCH_CASHBACK_FAILURE,
} from '../actions/cashback';

export const initialState = {
  cashBackLifetime: 0,
  hideCashback: true,
  isFetching: false,
};

const cashback = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_CASHBACK_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_CASHBACK_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    case FETCH_CASHBACK_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default cashback;
