import React, { Fragment } from 'react';
import style from '../../style/components/california/NotCalifornia.module.scss';

const NotCalifornia = () => (
  <Fragment>
    <h1>Are you a California resident?</h1>
    <p className={style.error}>
      This request applies only to Personal Information that we collect about
      California residents when we act as a “business” as defined under the
      California Consumer Privacy Act of 2018 (“CCPA”). ID.me does not rent,
      sell or share your personal information without your consent. You can
      review ID.me’s <a href="https://www.id.me/terms">Terms of Service</a> and{' '}
      <a href="https://www.id.me/privacy">Privacy Policy</a> for more
      information.
    </p>
  </Fragment>
);

export default NotCalifornia;
