import React, { memo } from 'react';
import PropTypes from 'prop-types';
import LinkedAccountsTableRow from './LinkedAccountsTableRow';
import style from '../../style/components/linkages/LinkedAccountsTable.module.scss';

const LinkedAccountsTable = ({ accounts }) => (
  <table className={style.table}>
    <thead>
      <tr>
        <th>ACCOUNT</th>
        <th>RELATIONSHIP</th>
        <th>DATE / TIME</th>
        <th>ACTIONS</th>
        <th />
      </tr>
    </thead>
    {accounts.length > 0 ? (
      <tbody>
        {accounts.map((account) => (
          <LinkedAccountsTableRow
            key={account.linkage_id}
            account={account}
            style={style}
          />
        ))}
      </tbody>
    ) : (
      <tfoot>
        <tr>
          <td className={style.none} colSpan="5">
            <p>Your account is not currently linked to another account.</p>
          </td>
        </tr>
      </tfoot>
    )}
  </table>
);

LinkedAccountsTable.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})),
};

LinkedAccountsTable.defaultProps = {
  accounts: [],
};

export default memo(LinkedAccountsTable);
