import axios from 'axios';
import { FETCH_SESSION_FAILURE } from '../actions/session';
import { getReactEnv } from '../constants';

export const checkResponseToBeArray = (response) => {
  if (!Array.isArray(response.data)) {
    throw new TypeError(`response was ${typeof response.data}, expected array`);
  }
};

const setupAxios = (store) => {
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  if (getReactEnv() === 'development') {
    // check if current url contains idme.test:8081
    if (window.location.href.includes('idme.test:8081')) {
      axios.defaults.baseURL = 'http://account-api.idme.test:8081/api';
      axios.defaults.headers.common['Access-Control-Allow-Origin'] =
        'http://account-api.idme.test:8081';
    } else {
      axios.defaults.baseURL = 'https://account-api.idme.test/api';
      axios.defaults.headers.common['Access-Control-Allow-Origin'] =
        'https://account-api.idme.test';
    }
  } else {
    axios.defaults.baseURL = '/api';
  }

  axios.interceptors.request.use((config) => {
    const { csrfToken } = store.getState().session;

    if (!csrfToken) {
      return config;
    }

    config.headers['X-CSRF-TOKEN'] = csrfToken;
    return config;
  });

  axios.interceptors.response.use(undefined, (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch({
        type: FETCH_SESSION_FAILURE,
      });
    }

    return Promise.reject(error);
  });
};

export default setupAxios;
