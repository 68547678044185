import PropTypes from 'prop-types';

export default PropTypes.shape({
  account: PropTypes.string,
  connection: PropTypes.string,
  date: PropTypes.string,
  email: PropTypes.string,
  fname: PropTypes.string,
  icon: PropTypes.string,
  lname: PropTypes.string,
});
