import axios from 'axios';
import {
  URL_SUPPORT_REQUESTS,
  URL_SUPPORT_REQUEST_FIELDS,
} from '../constants/urls';
import { addErrorMessage } from './errors';

export const FETCH_SUPPORT_REQUEST_FIELDS_BEGIN =
  'FETCH_SUPPORT_REQUEST_FIELDS_BEGIN';
export const FETCH_SUPPORT_REQUEST_FIELDS_SUCCESS =
  'FETCH_SUPPORT_REQUEST_FIELDS_SUCCESS';
export const FETCH_SUPPORT_REQUEST_FIELDS_FAILURE =
  'FETCH_SUPPORT_REQUEST_FIELDS_FAILURE';
export const FETCH_HELP_BEGIN = 'FETCH_HELP_BEGIN';
export const FETCH_HELP_SUCCESS = 'FETCH_HELP_SUCCESS';
export const FETCH_HELP_FAILURE = 'FETCH_HELP_FAILURE';
export const FETCH_CREATE_HELP_BEGIN = 'FETCH_CREATE_HELP_BEGIN';
export const FETCH_CREATE_HELP_SUCCESS = 'FETCH_CREATE_HELP_SUCCESS';
export const FETCH_CREATE_HELP_FAILURE = 'FETCH_CREATE_HELP_FAILURE';

export const fetchSupportRequestFields = () => async (dispatch) => {
  dispatch({ type: FETCH_SUPPORT_REQUEST_FIELDS_BEGIN });
  try {
    const response = await axios.get(URL_SUPPORT_REQUEST_FIELDS);

    dispatch({
      payload: response.data,
      type: FETCH_SUPPORT_REQUEST_FIELDS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error,
      type: FETCH_SUPPORT_REQUEST_FIELDS_FAILURE,
    });
    dispatch(addErrorMessage());
  }
};

export const fetchSupportRequests = () => async (dispatch) => {
  dispatch({ type: FETCH_HELP_BEGIN });
  try {
    const response = await axios.get(URL_SUPPORT_REQUESTS);

    dispatch({
      payload: response.data,
      type: FETCH_HELP_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error,
      type: FETCH_HELP_FAILURE,
    });
    dispatch(addErrorMessage());
  }
};

export const createHelp =
  ({ answers, description, full_name }) =>
  async (dispatch) => {
    dispatch({ type: FETCH_CREATE_HELP_BEGIN });
    try {
      const payload = {
        custom_fields: Object.entries(answers).map(([id, value]) => ({
          id,
          value,
        })),
        description,
      };
      if (full_name) {
        payload.full_name = full_name;
      }
      const response = await axios.post(URL_SUPPORT_REQUESTS, payload);

      dispatch({
        payload: response.data,
        type: FETCH_CREATE_HELP_SUCCESS,
      });

      return true;
    } catch (error) {
      dispatch({
        payload: error,
        type: FETCH_CREATE_HELP_FAILURE,
      });
      dispatch(addErrorMessage());
    }
  };
