import React, { memo } from 'react';
import PropTypes from 'prop-types';
import RadioButton from '../RadioButton';
import { ACCOUNT, SHOP } from '../../constants';

const DefaultHomepage = ({ style, defaultHomepage, handleRadioChange }) => (
  <div className={style.homepageWrapper}>
    <div className={style.homepageTitle}>Default Homepage</div>
    <div className={style.homepage}>
      <div className={style.radios}>
        <RadioButton
          currentValue={defaultHomepage}
          name="default-homepage"
          value={ACCOUNT} // TODO: change this to WALLET after account-api is updated to accept it
          onChange={handleRadioChange}
        >
          ID.me Government
        </RadioButton>
        <RadioButton
          currentValue={defaultHomepage}
          name="default-homepage"
          value={SHOP}
          onChange={handleRadioChange}
        >
          ID.me Shop
        </RadioButton>
      </div>
    </div>
  </div>
);

DefaultHomepage.propTypes = {
  defaultHomepage: PropTypes.string,
  handleRadioChange: PropTypes.func.isRequired,
  style: PropTypes.shape({}).isRequired,
};

DefaultHomepage.defaultProps = {
  defaultHomepage: '',
};

export default memo(DefaultHomepage);
