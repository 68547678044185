import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserProps from '../proptypes/user';
import { fetchUser } from '../actions/user';
import HelpRequestForm from '../components/help/HelpRequestForm';
import { createHelp } from '../actions/help';

export class HelpRequestNewContainer extends Component {
  static propTypes = {
    actionFetchUser: PropTypes.func.isRequired,
    user: UserProps.isRequired,
  };

  state = { formKey: 0 };

  componentDidMount() {
    const { actionFetchUser, user } = this.props;
    if (!user.isFetching) {
      actionFetchUser();
    }
  }

  handleCreateHelpRequest = async ({
    description,
    email,
    full_name,
    ...answers
  }) => {
    const { actionCreateHelp } = this.props;
    const success = await actionCreateHelp({
      answers,
      description,
      full_name,
    });

    if (success) {
      this.setState({ redirect: true });
    }
  };

  handleReset = () => {
    this.setState((prevState) => ({ formKey: prevState.formKey + 1 }));
  };

  render() {
    const { user } = this.props;
    const { redirect, formKey } = this.state;

    if (redirect) {
      // NOTE: must redirect link this for Qualtrics survey to show up
      window.location.href = '/help/tickets?success=true';
    }

    return (
      <HelpRequestForm
        key={formKey}
        createHelpRequest={this.handleCreateHelpRequest}
        handleReset={this.handleReset}
        user={user}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  actionCreateHelp: createHelp,
  actionFetchUser: fetchUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HelpRequestNewContainer);
