import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MMDDYYYY, HHMM } from '../../utils/time';
import loginPropTypes from '../../proptypes/login';
import { useSelector } from 'react-redux';
import CancelAccessRequestButton from './CancelAccessRequestButton';

const AccessRequestsTableRow = ({ request, style }) => {
  const isMinor = useSelector((state) => state.user.is_minor);

  const linkText = isMinor ? 'Resend' : 'Review';

  return (
    <tr className={style.account}>
      <td title="ACCOUNT:">
        <div className={style.bold}>{request.name}</div>
        <div>{request.email}</div>
      </td>
      <td title="RELATIONSHIP:">
        <div className={style.bold}>{request.linkage_status}</div>
        <div>{request.relationship}</div>
      </td>
      <td title="WEBSITE NAME:">
        <div className={style.bold}>{request.website_name}</div>
        <div>Access to services</div>
      </td>
      <td title="DATE / TIME:" className={style.connected}>
        <div className={style.bold}>{MMDDYYYY(request.date)}</div>
        <div>{HHMM(request.date)}</div>
      </td>
      <td title="ACTIONS:" className={style.buttons}>
        <idme-link variant="cta" href={request.partner_url} text={linkText}>
          <idme-icon
            slot="leadingIcon"
            color="sapphire"
            name="external-link"
          ></idme-icon>
        </idme-link>
        {isMinor ? <CancelAccessRequestButton request={request} /> : null}
      </td>
    </tr>
  );
};

AccessRequestsTableRow.propTypes = {
  request: loginPropTypes.isRequired,
  style: PropTypes.shape({}).isRequired,
};

export default memo(AccessRequestsTableRow);
