import React, { memo } from 'react';
import PropTypes from 'prop-types';
import LoginsTableRow from './LoginsTableRow';
import loginPropTypes from '../../proptypes/login';
import style from '../../style/components/signin/LoginsActiveTable.module.scss';

const LoginsActiveTable = ({ logins, handleDelete }) => (
  <table className={style.table}>
    <thead>
      <tr>
        <th>ACCOUNT</th>
        <th>USER LOGIN</th>
        <th>FIRST NAME</th>
        <th>LAST NAME</th>
        <th>CONNECTED</th>
        <th />
      </tr>
    </thead>
    {logins.length > 0 ? (
      <tbody>
        {logins.map((login) => (
          <LoginsTableRow
            key={login.handle}
            handleDelete={handleDelete}
            login={login}
            style={style}
          />
        ))}
      </tbody>
    ) : (
      <tfoot>
        <tr>
          <td className={style.none} colSpan="5">
            <p>No Current Logins</p>
          </td>
        </tr>
      </tfoot>
    )}
  </table>
);

LoginsActiveTable.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  logins: PropTypes.arrayOf(loginPropTypes),
};

LoginsActiveTable.defaultProps = {
  logins: [],
};

export default memo(LoginsActiveTable);
