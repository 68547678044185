import * as Sentry from '@sentry/react';
import { SENTRY_ENABLED } from '../constants';

export const sendToSentry = (error, errorInfo) => {
  if (SENTRY_ENABLED) {
    Sentry.setExtras(errorInfo);
    Sentry.captureException(error);
  } else {
    // eslint-disable-next-line
    console.error(error, errorInfo);
  }
};
