import React from 'react';
import PropTypes from 'prop-types';
import style from '../style/components/ToastNotification.module.scss';

const ToastNotification = ({ type, children }) => {
  const toastStyle = type === 'info' ? style.info : style.warning;
  return (
    <div className={`${style.toast} ${toastStyle}`}>
      {type === 'info' && <idme-icon name="info" />}
      {type === 'warning' && <idme-icon name="alert-triangle" />}
      <div className={style.content}>{children}</div>
    </div>
  );
};

ToastNotification.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ToastNotification;
