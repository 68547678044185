import React, { memo } from 'react';
import PropTypes from 'prop-types';
import style from '../style/components/ButtonLink.module.scss';

const ButtonLink = ({ children, ...props }) => (
  <button className={style.buttonLink} type="button" {...props}>
    {children}
  </button>
);

ButtonLink.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(ButtonLink);
