import PropTypes from 'prop-types';

const id = {
  expires: PropTypes.string,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      handle: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.array,
      ]),
    }),
  ),
  status: PropTypes.string,
  type: PropTypes.string,
  verified: PropTypes.string,
};

export const defaultProps = {
  expires: undefined,
  properties: [],
  status: undefined,
  type: undefined,
  verified: undefined,
};

export default id;
