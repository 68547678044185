import PropTypes from 'prop-types';

export default PropTypes.shape({
  business_name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  linkedin: PropTypes.string,
  address: PropTypes.shape({
    street: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
  email: PropTypes.shape({
    id: PropTypes.number.isRequired,
    address: PropTypes.string.isRequired,
  }),
});
