import axios from 'axios';
import { redirect } from '../utils/redirect';
import { URL_NEW_SESSION, URL_SESSION } from '../constants/urls';

export const FETCH_SESSION_NEW_REDIRECT = 'FETCH_SESSION_NEW_REDIRECT';
export const FETCH_SESSION_NEW_FAILURE = 'FETCH_SESSION_NEW_FAILURE';

export const FETCH_SESSION_END_BEGIN = 'FETCH_SESSION_END_BEGIN';
export const FETCH_SESSION_END_FAILURE = 'FETCH_SESSION_END_FAILURE';

export const FETCH_SESSION_BEGIN = 'FETCH_SESSION_BEGIN';
export const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS';
export const FETCH_SESSION_FAILURE = 'FETCH_SESSION_FAILURE';
export const FETCH_SESSION_ERROR = 'FETCH_SESSION_ERROR';

export const sessionNew = () => async (dispatch) => {
  dispatch({ type: FETCH_SESSION_NEW_REDIRECT });

  try {
    const response = await axios.get(URL_NEW_SESSION);

    const { url } = response.data;

    if (url) {
      redirect(url, window.location.pathname + window.location.search);
    } else {
      throw new Error('GET /session/new did not respond with a url');
    }
  } catch (error) {
    dispatch({ payload: error.message, type: FETCH_SESSION_NEW_FAILURE });
  }
};

export const sessionCheck = () => async (dispatch) => {
  dispatch({ type: FETCH_SESSION_BEGIN });

  try {
    const response = await axios.get(URL_SESSION);

    const { data } = response;
    dispatch({
      payload: data,
      type: FETCH_SESSION_SUCCESS,
    });
  } catch (error) {
    dispatch(sessionNew());
  }
};

export const sessionEnd = (userId) => async (dispatch) => {
  dispatch({ type: FETCH_SESSION_END_BEGIN, userId });

  try {
    const response = await axios.delete(URL_SESSION);
    const { url } = response.data;

    window.location.assign(url);
  } catch (error) {
    dispatch({ payload: error.message, type: FETCH_SESSION_END_FAILURE });
  }
};
