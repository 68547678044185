import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Password from '../components/signin/Password';
import { clearError, updatePassword } from '../actions/password';
import memoize from '../utils/memoize';
import { checkPassword, rules } from '../utils/checkPassword';
import { getReactEnv } from '../constants';

export class SigninSecurityPassword extends Component {
  static propTypes = {
    actionClearError: PropTypes.func.isRequired,
    actionUpdatePassword: PropTypes.func.isRequired,
    error: PropTypes.arrayOf(PropTypes.string),
    instruction: PropTypes.string.isRequired,
  };

  static defaultProps = {
    error: [],
  };

  state = {
    confirmation: '',
    current: '',
    newPass: '',
  };

  timer = null;

  componentDidMount() {
    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  validate = memoize(checkPassword);

  checkSaveDisabled = memoize(
    (current, newPass, confirmation) =>
      !(
        current &&
        current.length &&
        newPass &&
        newPass.length &&
        confirmation &&
        confirmation.length
      ) || newPass !== confirmation,
  );

  handleCancel = () => {
    const { actionClearError } = this.props;
    this.setState(
      {
        confirmation: '',
        current: '',
        newPass: '',
      },
      actionClearError,
    );
  };

  handleCurrent = (e) => {
    const { value } = e.target;
    this.setState({
      current: value,
    });
  };

  handleNew1 = (e) => {
    const { value } = e.target;
    this.setState({
      newPass: value,
    });
  };

  handleNew2 = (e) => {
    const { value } = e.target;
    this.setState({
      confirmation: value,
    });
  };

  handleSave = async (e) => {
    e.preventDefault();

    const { actionUpdatePassword } = this.props;
    const { current, newPass, confirmation } = this.state;

    const success = await actionUpdatePassword({
      confirmation,
      current,
      new: newPass,
    });

    this.setState({
      current: '',
      success,
    });

    if (success) {
      this.setState({
        confirmation: '',
        newPass: '',
      });

      this.timer = window.setTimeout(() => {
        this.setState({ success: false });
        this.timer = null;
      }, 3000);
    }
  };

  render() {
    const { error, instruction, isFetching } = this.props;
    const { confirmation, current, newPass, success } = this.state;

    const validated = this.validate(newPass);
    const invalid = newPass.length && !validated.every(Boolean);

    const isSaveDisabled =
      invalid ||
      isFetching ||
      this.checkSaveDisabled(current, newPass, confirmation);

    return (
      <Password
        confirmation={confirmation}
        current={current}
        error={error}
        handleCancel={this.handleCancel}
        handleCurrent={this.handleCurrent}
        handleNew1={this.handleNew1}
        handleNew2={this.handleNew2}
        handleSave={this.handleSave}
        isSaveDisabled={isSaveDisabled}
        instruction={instruction}
        newPass={newPass}
        invalid={invalid}
        rules={rules}
        success={success}
        validated={validated}
      />
    );
  }
}

const mapStateToProps = (state) => ({ ...state.password });

const mapDispatchToProps = {
  actionClearError: clearError,
  actionUpdatePassword: updatePassword,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SigninSecurityPassword),
);
