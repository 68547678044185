import React from 'react';
import style from '../../style/components/profile/EmailStatus.module.scss';
import Counter from '../Counter';

const EmailStatus = ({ isPrimary, isVerified }) => (
  <div>
    {isPrimary ? (
      <div className={style.emailStatus}>
        <Counter kind="success">Primary</Counter>
      </div>
    ) : null}
    {!isVerified ? (
      <div className={style.emailStatus}>
        <Counter kind="info">Not Confirmed</Counter>
      </div>
    ) : null}
  </div>
);

export default EmailStatus;
