import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import style from '../style/pages/Profile.module.scss';
import ProfileEmails from '../containers/ProfileEmails';
import DataLocked from '../containers/ProfileDataLocked';
import Preferences from '../containers/ProfilePreferences';
import ProfilesWrapper from '../components/profile/ProfilesWrapper';
import { getReactEnv } from '../constants';

const Profile = () => {
  useEffect(() => {
    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  return (
    <main className={style.content}>
      <ProfilesWrapper />
      <ProfileEmails />
      <Preferences />

      {/* TODO: remove this */}
      <DataLocked />
    </main>
  );
};

export default Profile;
