import React, { useState } from 'react';
import UpdateWorkProfileModal from './UpdateWorkProfileModal';
import SuccessModal from '../SuccessModal';

const UpdateWorkProfileButton = ({ workProfile }) => {
  const [isUpdateWorkProfileModalOpen, setIsUpdateWorkProfileModalOpen] =
    useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const { address, email, phone, ...workProfileBase } = workProfile;
  const initialData = {
    ...workProfileBase,
    ...address,
    ...phone,
    email_id: email ? `${email.id}` : null,
  };

  return (
    <>
      <idme-button
        variant="tertiary"
        text="Edit"
        size="small"
        onClick={() => setIsUpdateWorkProfileModalOpen(true)}
      />

      {isUpdateWorkProfileModalOpen && (
        <UpdateWorkProfileModal
          workProfileId={workProfile.id}
          isOpen={isUpdateWorkProfileModalOpen}
          closeModal={() => setIsUpdateWorkProfileModalOpen(false)}
          initialData={initialData}
          openSuccessModal={() => setIsSuccessModalOpen(true)}
        />
      )}

      {isSuccessModalOpen && (
        <SuccessModal
          text="Your work profile has been updated."
          isOpen={isSuccessModalOpen}
          closeModal={() => setIsSuccessModalOpen(false)}
        />
      )}
    </>
  );
};

export default UpdateWorkProfileButton;
