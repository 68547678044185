import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import RequestData from '../components/california/RequestData';
import { connect } from 'react-redux';
import { requestData, fetchIsCaliforniaResident } from '../actions/california';

export class RequestContainer extends Component {
  state = { redirect: false };

  async componentDidMount() {
    const {
      actionFetchIsCaliforniaResident,
      actionRequestData,
      isCaliforniaResident,
    } = this.props;

    if (isCaliforniaResident) {
      actionRequestData();
    } else {
      await actionFetchIsCaliforniaResident();

      const { isCaliforniaResident: isResident } = this.props;
      if (isResident) {
        actionRequestData();
      } else {
        this.setState({ redirect: true });
      }
    }
  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="manage-data" />;
    }

    const { isFetched, isFetching } = this.props;
    return <RequestData success={isFetched} loading={isFetching} />;
  }
}

const mapStateToProps = (state) => ({ ...state.california });

const mapDispatchToProps = {
  actionFetchIsCaliforniaResident: fetchIsCaliforniaResident,
  actionRequestData: requestData,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestContainer);
