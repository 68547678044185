export const ACCOUNT = 'account';
export const WALLET = 'wallet';
export const SHOP = 'shop';

export const ACTIVE = 'ACTIVE';
export const EXPIRED = 'EXPIRED';
export const INACTIVE = 'INACTIVE';

let base_domain,
  domain,
  idme_url,
  rx_url,
  shop_url,
  idp_url,
  ga4,
  sentry_whitelist;
let terms_url = 'https://www.id.me/terms';

const hostnameToEnv = {
  'account.id.me': 'production',
  'account-staging.idmeinc.net': 'staging',
  'account.idme.cc': 'gcp-staging',
  'account.idmelabs.com': 'sandbox',
  'account.labs.idme.co': 'internal-ei',
  'account.idme.co': 'internal-production',
};

export const getReactEnv = () => {
  return hostnameToEnv[window.location.hostname] || 'development';
};

switch (getReactEnv()) {
  case 'gcp-staging':
    base_domain = 'idme.cc';
    domain = 'https://account.idme.cc';
    idme_url = 'https://wallet.idme.cc';
    rx_url = 'https://rx.idme.cc';
    shop_url = 'https://shop.idme.cc';
    idp_url = 'https://api.idme.cc';
    ga4 = 'GTM-PL62HD9';
    sentry_whitelist = [/account.idme.cc/];
    break;
  case 'staging':
    base_domain = 'idmeinc.net';
    domain = 'https://account-staging.idmeinc.net';
    idme_url = 'https://wallet-staging.idmeinc.net';
    rx_url = 'https://rx.idme.cc';
    shop_url = 'https://shop.idme.cc';
    idp_url = 'https://api.idmeinc.net';
    ga4 = 'GTM-PL62HD9';
    sentry_whitelist = [/account-staging.idmeinc.net/];
    break;
  case 'group':
    base_domain = 'idmeinc.net';
    domain = 'https://account-group.idmeinc.net';
    idme_url = 'https://wallet-group.idmeinc.net';
    rx_url = 'https://rx.idme.cc';
    shop_url = 'https://shop.idme.cc'; // There is no shop-group
    idp_url = 'https://api-group.idmeinc.net';
    ga4 = 'GTM-PL62HD9';
    sentry_whitelist = [/account-group.idmeinc.net/];
    break;
  case 'internal-ei':
    base_domain = 'labs.idme.co';
    domain = 'https://account.labs.idme.co';
    idme_url = 'https://wallet.labs.idme.co';
    rx_url = 'https://rx.id.me'; // There is no rx sandbox
    shop_url = 'https://shop.id.me'; // There is no shop sandbox
    terms_url = 'https://developers.labs.idme.co/developer-terms';
    idp_url = 'https://api.labs.idme.co';
    ga4 = 'GTM-PL62HD9';
    sentry_whitelist = [/account.labs.idme.co/];
    break;
  case 'sandbox':
    base_domain = 'idmelabs.com';
    domain = 'https://account.idmelabs.com';
    idme_url = 'https://wallet.idmelabs.com';
    rx_url = 'https://rx.id.me'; // There is no rx sandbox
    shop_url = 'https://shop.id.me'; // There is no shop sandbox
    terms_url = 'https://developers.idmelabs.com/developer-terms';
    idp_url = 'https://api.idmelabs.com';
    ga4 = 'GTM-PL62HD9';
    sentry_whitelist = [/account.idmelabs.com/];
    break;
  case 'internal-production':
    base_domain = 'idme.co';
    domain = 'https://account.idme.co';
    idme_url = 'https://idme.co';
    rx_url = 'https://rx.idme.co';
    shop_url = 'https://shop.idme.co';
    idp_url = 'https://api.idme.co';
    ga4 = 'GTM-PL62HD9';
    sentry_whitelist = [/account.idme.co/];
    break;
  case 'production':
    base_domain = 'id.me';
    domain = 'https://account.id.me';
    idme_url = 'https://id.me';
    rx_url = 'https://rx.id.me';
    shop_url = 'https://shop.id.me';
    idp_url = 'https://api.id.me';
    ga4 = 'GTM-PL62HD9';
    sentry_whitelist = [/account.id.me/];
    break;
  default:
    // this is development
    base_domain = 'idme.test';
    domain = '*';
    idme_url = 'https://wallet.idme.test';
    rx_url = 'https://rx.idme.test';
    shop_url = 'https://shop.idme.test';
    idp_url = 'https://api.idme.test';
    // Sentry and GA4 are not enabled in development
    ga4 = null;
    sentry_whitelist = null;
}

// domain for postMessage
export const BASE_DOMAIN = base_domain;
export const DOMAIN = domain;
export const IDME_URL = idme_url;
export const RX_URL = rx_url;
export const SHOP_URL = shop_url;
export const TERMS_URL = terms_url;
export const IDP_URL = idp_url;

export const GA4_TRACKING_ID = ga4;
export const SENTRY_ENABLED = !!sentry_whitelist;
export const SENTRY_WHITELIST = sentry_whitelist;
export const SENTRY_DSN =
  'https://98241fdf8594450a8bf15e2e3ee09a32@sentry.io/1313106';

// CCPA Constants
export const CCPA_DOWNLOAD = 'download';
export const CCPA_LOCK = 'lock_data';

export const EXISTS = 'exists';
export const PENDING = 'pending';
export const PREPARED = 'prepared';
export const PROCESSING = 'processing';
export const COMPLETED = 'completed';
export const CALIFORNIA = 'CA';

export const BIOMETRIC_CONSENT = 'biometric_consent';

export const SELFIE_DELETE = 'selfie';

// TODO: remove this flag after translations are fully baked out
export const TRANSLATIONS_SUPPORTED = false;

// smartystreets
export const SMARTYSTREETS_KEY = '34189370593289257';

export const LINETYPES = [
  { label: 'Mobile', value: 'mobile' },
  { label: 'Office', value: 'office' },
  { label: 'Home', value: 'home' },
  { label: 'Other', value: 'other' },
];

export const WORK = 'work';
export const PERSONAL = 'personal';
