import React from 'react';
import { Route, Switch } from 'react-router-dom';
import WalletIds from '../containers/WalletIds';
import SideNav from '../components/SideNav';
import Four04 from './Four04';
import style from '../style/pages/Wallet.module.scss';
import { SHOP_URL } from '../constants';

const idRoute = { children: 'My IDs', to: '/wallet/ids' };

const Wallet = ({ location = {} }) => {
  const routes = [idRoute];

  return (
    <div className={style.wrapper}>
      <SideNav routes={routes} current={location.pathname} />
      <Switch>
        <Route path="/wallet/ids" component={WalletIds} />

        <Route
          exact
          path="/wallet/cashback"
          component={() => {
            window.location.href = `${SHOP_URL}/cash-back/purchases`;
            return null;
          }}
        />

        <Route component={Four04} />
      </Switch>
    </div>
  );
};

export default Wallet;
