import React, { memo } from 'react';
import PropTypes from 'prop-types';
import style from '../../style/components/signin/CloseAccount.module.scss';

const CloseAccountWarning = () => {
  return (
    <div className={style.warning}>
      <p>
        If you are applying for government benefits, you{' '}
        <strong>SHOULD NOT DELETE</strong> your ID.me account if you are using{' '}
        ID.me to verify your identity as part of your claim. Closing and
        deleting a verified ID.me account and creating a new ID.me account will
        not help you proceed with a claim.
      </p>
    </div>
  );
};

const CloseAccount = ({
  handleCloseAccount,
  hasStateWorkforceCredential,
  modalDisplay,
}) => (
  <main className={`${style.close} ${modalDisplay ? style.modalDisplay : ''}`}>
    {modalDisplay ? null : <h2 className={style.title}>Close Account</h2>}
    {hasStateWorkforceCredential && <CloseAccountWarning />}
    <h3>What happens when I close my ID.me account?</h3>
    <p>
      Within seven days after you close your account, all account information
      &mdash; credentials or pending verifications &mdash; will be deleted. Once
      deleted, you may use the same email address to create a new ID.me account,
      if you wish.{' '}
      <a href="https://help.id.me/hc/en-us/articles/203532014">
        Learn more about account closure
      </a>
      .
    </p>
    <h3>Safeguarding your identity</h3>
    <p>
      ID.me will retain a portion of your account's attributes on file to comply
      with applicable laws and help us prevent fraud. Please see our{' '}
      <a href="https://www.id.me/privacy">Privacy Policy</a> and{' '}
      <a href="https://www.id.me/terms">Terms of Service</a> for details.
    </p>
    <h3>Can we help?</h3>
    <p>
      If you are experiencing difficulty with the verification process, please{' '}
      <a href="https://help.id.me/hc/en-us/requests/new">
        contact our Support Team
      </a>{' '}
      for assistance before closing your account.
    </p>

    {modalDisplay ? (
      <idme-button
        variant="primary"
        text="Continue"
        layout="fill"
        onClick={handleCloseAccount}
      />
    ) : (
      <idme-button
        variant="secondary"
        size="small"
        text="I would like to close my account"
        layout="content"
        onClick={handleCloseAccount}
      />
    )}
  </main>
);

CloseAccount.propTypes = {
  handleCloseAccount: PropTypes.func.isRequired,
};

export default memo(CloseAccount);
