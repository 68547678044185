import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SigninSecurity from '../components/signin/TwoFA';
import SigninPassword from '../containers/SigninSecurityPassword';
import SigninPrivacy from '../components/signin/Privacy';
import SigninLogins from '../containers/SigninLogins';
import SigninClose from '../containers/SigninCloseAccount';
import Four04 from './Four04';
import SideNav from '../components/SideNav';
import style from '../style/pages/Signin.module.scss';

const routes = () => {
  return [
    { children: 'Security', to: '/signin/security' },
    { children: 'Password', to: '/signin/password' },
    { children: 'Privacy', to: '/signin/privacy' },
    { children: 'Logins', to: '/signin/logins' },
  ];
};

const Signin = ({ location = {} }) => {
  return (
    <div className={style.wrapper}>
      <SideNav routes={routes()} current={location.pathname} />
      <Switch>
        <Route path="/signin/security" component={SigninSecurity} />
        <Route path="/signin/password" component={SigninPassword} />
        <Route path="/signin/privacy" component={SigninPrivacy} />
        <Route path="/signin/logins" component={SigninLogins} />
        <Route path="/signin/close" component={SigninClose} />
        <Route component={Four04} />
      </Switch>
    </div>
  );
};

export default Signin;
