import React from 'react';
import style from '../../style/components/wallet/Qr.module.scss';

const QrCode = ({ logo, qrImage, text, title, url }) => (
  <figure className={style.qr}>
    <figcaption>
      {title} {logo && <img alt="" src={logo} />}
    </figcaption>
    <img alt="QR code" src={qrImage} />
    {url && <a href={url}>{text}</a>}
  </figure>
);
export default QrCode;
