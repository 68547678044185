import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MyActivity from '../components/overview/MyActivity';

export class OverviewActivities extends Component {
  static propTypes = {};

  static defaultProps = {};

  componentDidMount() {}

  render() {
    return <MyActivity />;
  }
}

export const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OverviewActivities),
);
