import React from 'react';
import { Link } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import style from '../style/components/EmailConfirm.module.scss';

const EmailConfirm = ({
  img,
  isSuccess,
  loading,
  message,
  message2,
  showLink,
}) => (
  <section className={style.emailConfirm}>
    {img && <img alt={img.alt} src={img.src} />}
    {loading && <LoadingSpinner />}
    <h2>
      {isSuccess
        ? 'Your Email Address Has Been Confirmed'
        : 'Confirm Your Email Address'}
    </h2>
    {message && <h3>{message}</h3>}
    {message2 && <p>{message2}</p>}
    {showLink && <Link to={'/profile'}>Go to Profile</Link>}
  </section>
);

export default EmailConfirm;
