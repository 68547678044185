import { ADD_ERROR_MESSAGE, DISMISS_ERROR_MESSAGE } from '../actions/errors';

export const initialState = {
  list: [],
};

const errors = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case ADD_ERROR_MESSAGE:
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    case DISMISS_ERROR_MESSAGE:
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.payload),
      };
    default:
      return state;
  }
};

export default errors;
