import React from 'react';
import ErrorAlert from './ErrorAlert';
import { useDispatch, useSelector } from 'react-redux';
import { dismissErrorMessage } from '../actions';

const ErrorAlertContainer = () => {
  const list = useSelector((state) => state.errors.list);
  const dispatch = useDispatch();

  const deleteAlert = (id) => {
    dispatch(dismissErrorMessage(id));
  };

  return (
    <div>
      {list.map((toast, i) => {
        return (
          <ErrorAlert key={i} handleClose={() => deleteAlert(toast.id)}>
            {toast.message}
          </ErrorAlert>
        );
      })}
    </div>
  );
};

export default ErrorAlertContainer;
