import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import DataDownload from '../../img/icons/data-download.svg';
import CloseAccount from '../../img/icons/close-account.svg';
import LockData from '../../img/icons/lock-data.svg';
import style from '../../style/components/california/ManageData.module.scss';

const choices = [
  {
    description:
      'to start the process of collecting a consolidated copy of your personal data.',
    handle: 'request',
    href: '/request-data',
    image: DataDownload,
    text: 'Request',
  },
  {
    description:
      'to keep all data for the site and prohibit resell of personal information and data collected.',
    handle: 'lock',
    href: '/lock-data',
    image: LockData,
    text: 'Lock Data',
  },
];

const ManageData = ({ disabled = [], handleDeleteData }) => (
  <Fragment>
    <h1 className={style.title}>Manage Your Account’s Data</h1>
    <dl className={style.updated}>
      <dt>Last Updated:</dt>
      <dd>07/12/2021</dd>
    </dl>
    <p className={style.description}>
      Most of the personal data that ID.me has about you is accessible through
      the My Account application <Link to="/overview">account.id.me</Link> (e.g.
      search queries, location data, etc). For more information, see your data
      rights and privacy settings. If you have any questions or concerns about
      your personal data contained in your downloadable file, please contact us.
    </p>
    <section className={style.choices}>
      {choices.map((choice) => {
        const dis = disabled.includes(choice.handle);
        return (
          <div
            className={dis ? style.disabled : style.choice}
            key={choice.handle}
          >
            <div>
              <img src={choice.image} alt="" />
              <p>
                Click <strong>"{choice.text}"</strong> {choice.description}
              </p>
            </div>
            {dis ? (
              <div className={style.link} to={choice.href} disabled>
                {choice.text}
              </div>
            ) : (
              <Link className={style.link} to={choice.href}>
                {choice.text}
              </Link>
            )}
          </div>
        );
      })}
      <div
        className={disabled.includes('close') ? style.disabled : style.choice}
        key={'close'}
      >
        <div>
          <img src={CloseAccount} alt="" />
          <p>
            Click <strong>"Close Account"</strong> to close account and delete
            account profile information.
          </p>
        </div>
        {disabled.includes('close') ? (
          <div className={style.link} onClick={handleDeleteData} disabled>
            Close Account
          </div>
        ) : (
          <button className={style.link} onClick={handleDeleteData}>
            Close Account
          </button>
        )}
      </div>
    </section>
  </Fragment>
);

export default ManageData;
