import React, { memo, useCallback } from 'react';
import DeleteButton from '../DeleteButton';
import { MMMDYYYY } from '../../utils/time';
import checkmark from '../../img/icons/checkmark.svg';
import style from '../../style/components/signin/TwoFArow.module.scss';

const TwoFAActiveRow = ({
  date,
  handleDelete,
  handleUpdate,
  index,
  mfaPolicies,
  name,
  totalEnrolled,
}) => {
  const updateIsDefined = !!handleUpdate;
  const isOnly2FA = totalEnrolled === 1;

  const onClickDelete = useCallback(() => {
    handleDelete(index);
  }, [handleDelete, index]);

  const onClickUpdate = useCallback(() => {
    if (updateIsDefined) {
      handleUpdate(index);
    }
    // do nothing
  }, [handleUpdate, index, updateIsDefined]);

  return (
    <div className={style.rowActive}>
      <div className={style.left}>
        <div className={style.name}>{name}</div>
        <div className={style.date}>Added {MMMDYYYY(date)}</div>
      </div>

      <div className={style.center}>
        {mfaPolicies.map((use, index) => (
          <div className={style.pill} key={index}>
            <img alt="" src={checkmark} />
            <span>{use}</span>
          </div>
        ))}
      </div>

      <div className={style.right}>
        {updateIsDefined && (
          <idme-button
            variant="tertiary"
            size="small"
            text="Rename"
            onClick={onClickUpdate}
          />
        )}
        {!isOnly2FA && <DeleteButton onClick={onClickDelete} />}
      </div>
    </div>
  );
};

export default memo(TwoFAActiveRow);
