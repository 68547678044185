import React, { memo } from 'react';
import PropTypes from 'prop-types';
import style from '../../style/components/changeRequests/PendingRequestTile.module.scss';

const PendingRequestTile = ({ description, title, icon }) => {
  return (
    <div className={style.tile}>
      <div className={style.tileBody}>
        <img className={style.icon} src={icon} alt="" />
        <div className={style.content}>
          <h4 className={style.heading}>{title}</h4>
          <p className={style.body}>
            Your requested change is pending review. We will send you an email
            when it's complete.
          </p>
          <p className={style.body}>{description}</p>
        </div>
      </div>
    </div>
  );
};

PendingRequestTile.propTypes = {
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

PendingRequestTile.defaultProps = {};

export default memo(PendingRequestTile);
