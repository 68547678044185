import React, { Component } from 'react';
import deleteGray from '../img/icons/delete-gray.svg';
import deleteBlack from '../img/icons/delete-black.svg';
import style from '../style/components/DeleteButton.module.scss';

const noop = () => {};

class DeleteButton extends Component {
  state = {
    img: deleteGray,
  };

  ref = React.createRef();

  handleMouseEnter = (e) => {
    this.setState({
      img: deleteBlack,
    });
  };

  handleMouseOut = (e) => {
    if (e.target === this.ref.current) {
      return;
    }
    this.setState({
      img: deleteGray,
    });
  };

  render() {
    const { onClick } = this.props;
    const { img } = this.state;

    return (
      <button
        className={style.button}
        type="button"
        onClick={onClick}
        onMouseEnter={this.handleMouseEnter}
        onFocus={this.handleMouseEnter}
        onMouseOut={this.handleMouseOut}
        onBlur={this.handleMouseOut}
      >
        <img
          ref={this.ref}
          alt="delete"
          src={img}
          onMouseEnter={this.handleMouseEnter}
          onMouseOut={noop}
        />
      </button>
    );
  }
}

export default DeleteButton;
