import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import style from '../../style/components/california/Confirm.module.scss';

const Confirm = ({ url }) => (
  <Fragment>
    <h1>Confirm California residency</h1>
    <p>
      Our records indicate that your address is not a California address. This
      request applies only to Personal Information that we collect about
      California residents when we act as a “business” as defined under the
      California Consumer Privacy Act of 2018 (“CCPA”).
    </p>
    <p>
      If you think that is inaccurate, or if you have become a California
      resident, you will need to verify your information in order to continue
      with the request.
    </p>
    <h3 className={style.h3}>Would you like to update your records?</h3>
    <div className={style.buttons}>
      <Link className={style.cancel} to="/overview">
        Cancel and Exit
      </Link>
      <a className={style.update} href={url}>
        Update Records
      </a>
    </div>
  </Fragment>
);

export default Confirm;
