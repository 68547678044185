import {
  FETCH_ACCOUNT_ACTIVITIES_BEGIN,
  FETCH_ACCOUNT_ACTIVITIES_SUCCESS,
  FETCH_ACCOUNT_ACTIVITIES_FAILURE,
} from '../actions/activities';

export const initialState = {
  '*': [],
  connection: [],
  disconnect: [],
  error: null,
  isFetching: false,
  isSuccess: false,
  page: {
    '*': 0,
    connection: 0,
    disconnect: 0,
    signin: 0,
    verification: 0,
  },
  complete: {
    '*': false,
    connection: false,
    disconnect: false,
    signin: false,
    verification: false,
  },
  signin: [],
  verification: [],
};

const concatActivities = (prev = [], curr = []) => [...prev, ...curr];

const activities = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_ACCOUNT_ACTIVITIES_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
        isSuccess: false,
      };
    case FETCH_ACCOUNT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        [action.key]: concatActivities(state[action.key], action.payload),
        complete: {
          ...state.complete,
          [action.key]: action.complete,
        },
        error: null,
        isFetching: false,
        isSuccess: true,
        page: {
          ...state.page,
          [action.key]: state.page[action.key] + 1,
        },
      };
    case FETCH_ACCOUNT_ACTIVITIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        isSuccess: false,
      };
    default:
      return state;
  }
};

export default activities;
