import {
  FETCH_USER_BEGIN,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
} from '../actions/user';

export const initialState = {
  city: '',
  email: '',
  error: null,
  fname: '',
  isFetching: false,
  lname: '',
  phone: '',
  state: '',
  uploadError: '',
  zip: '',
};

const user = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_USER_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default user;
