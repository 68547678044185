import React from 'react';
import LockImg from '../../img/icons/data-locked-gray.svg';
import style from '../../style/components/profile/DataLocked.module.scss';

const DataLocked = () => (
  <div className={style.wrapper}>
    <h3 className={style.header}>
      <img src={LockImg} alt="" />
      Data Locked
    </h3>
    <p>
      When data is locked, your information will no longer be shared for
      marketing communications or shared with third-parties.
    </p>
    <div className={style.row}>
      <h4>DATA LOCKED</h4>
    </div>
  </div>
);

export default DataLocked;
