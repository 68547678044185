import React, { memo } from 'react';
import style from '../../style/components/signin/TwoFArow.module.scss';
import InputWithError from '../InputWithError';

const TwoFAEditRow = ({
  handleChange,
  handleCancel,
  handleConfirm,
  newName,
}) => (
  <div className={style.rowEdit}>
    <div className={style.left}>
      <InputWithError
        label="Name"
        value={newName}
        onChange={handleChange}
        noMargin
        required
      />
    </div>
    <div className={style.right}>
      <idme-button
        variant="secondary"
        text="Cancel"
        layout="content"
        type="button"
        size="small"
        onClick={handleCancel}
      />
      <idme-button
        variant="primary"
        text="Apply Change"
        layout="content"
        type="button"
        size="small"
        onClick={handleConfirm}
      />
    </div>
  </div>
);

export default memo(TwoFAEditRow);
