import axios from 'axios';
import { checkResponseToBeArray } from '../utils/axios';
import { sendToSentry } from '../utils/error';
import { URL_HOMEPAGE, URL_SUBSCRIPTIONS } from '../constants/urls';

export const FETCH_SUBSCRIPTIONS_BEGIN = 'FETCH_SUBSCRIPTIONS_BEGIN';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_FAILURE = 'FETCH_SUBSCRIPTIONS_FAILURE';

export const FETCH_DEFAULTHOMEPAGE_BEGIN = 'FETCH_DEFAULTHOMEPAGE_BEGIN';
export const FETCH_DEFAULTHOMEPAGE_SUCCESS = 'FETCH_DEFAULTHOMEPAGE_SUCCESS';
export const FETCH_DEFAULTHOMEPAGE_FAILURE = 'FETCH_DEFAULTHOMEPAGE_FAILURE';

export const SAVE_HOMEPAGE_BEGIN = 'SAVE_HOMEPAGE_BEGIN';
export const SAVE_SUBSCRIPTIONS_BEGIN = 'SAVE_SUBSCRIPTIONS_BEGIN';
export const SAVE_PREFERENCES_SUCCESS = 'SAVE_PREFERENCES_SUCCESS';
export const SAVE_PREFERENCES_FAILURE = 'SAVE_PREFERENCES_FAILURE';

export const SUBSCRIPTIONS_TOGGLE = 'SUBSCRIPTIONS_TOGGLE';
export const SUBSCRIPTIONS_TOGGLE_ALL = 'SUBSCRIPTIONS_TOGGLE_ALL';

export const HOMEPAGE_SET = 'HOMEPAGE_SET';

export const fetchSubscriptions = () => async (dispatch) => {
  dispatch({ type: FETCH_SUBSCRIPTIONS_BEGIN });

  try {
    const response = await axios.get(URL_SUBSCRIPTIONS);

    checkResponseToBeArray(response);

    dispatch({
      payload: response.data,
      type: FETCH_SUBSCRIPTIONS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_SUBSCRIPTIONS_FAILURE,
    });
    if (error instanceof TypeError) {
      sendToSentry(error);
    }
  }
};

export const fetchHomepage = () => async (dispatch) => {
  dispatch({ type: FETCH_DEFAULTHOMEPAGE_BEGIN });

  try {
    const response = await axios.get(URL_HOMEPAGE);

    dispatch({
      payload: response.data.homepage,
      type: FETCH_DEFAULTHOMEPAGE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_DEFAULTHOMEPAGE_FAILURE,
    });
  }
};

export const saveHomepage = (homepage) => async (dispatch) => {
  dispatch({ type: SAVE_HOMEPAGE_BEGIN });

  try {
    const homepageResponse = await axios.put(URL_HOMEPAGE, {
      homepage,
    });

    dispatch({
      payload: homepageResponse.data.homepage,
      type: FETCH_DEFAULTHOMEPAGE_SUCCESS,
    });

    dispatch({ type: SAVE_PREFERENCES_SUCCESS });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: SAVE_PREFERENCES_FAILURE,
    });
    if (error instanceof TypeError) {
      sendToSentry(error);
    }
    // Fetch homepage again to reset the form
    await fetchHomepage()(dispatch);
  }
};

export const saveSubscriptions = (subscriptions) => async (dispatch) => {
  dispatch({ type: SAVE_SUBSCRIPTIONS_BEGIN });

  try {
    const subscriptionsResponse = await axios.put(URL_SUBSCRIPTIONS, {
      data: subscriptions,
    });

    checkResponseToBeArray(subscriptionsResponse);

    dispatch({
      payload: subscriptionsResponse.data,
      type: FETCH_SUBSCRIPTIONS_SUCCESS,
    });

    dispatch({ type: SAVE_PREFERENCES_SUCCESS });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: SAVE_PREFERENCES_FAILURE,
    });
    if (error instanceof TypeError) {
      sendToSentry(error);
    }
    // Fetch subscriptions again to reset the form
    await fetchSubscriptions()(dispatch);
  }
};

export const toggleSubscription = (index) => ({
  payload: index,
  type: SUBSCRIPTIONS_TOGGLE,
});

export const toggleAllSubscriptions = () => ({
  type: SUBSCRIPTIONS_TOGGLE_ALL,
});
