import { hot } from 'react-hot-loader/root';
import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import NavBar from './containers/NavBar';
import Footer from './components/Footer';
import LoadingPage from './components/LoadingPage';
import { DOMAIN, TRANSLATIONS_SUPPORTED } from './constants';
import routes from './routing/routes';
import ScrollToTop from './routing/ScrollToTop';
import AuthRoute from './routing/AuthRoute';

import '@idme/action-group';
import '@idme/button';
import '@idme/description-list';
import '@idme/global-nav';
import '@idme/icon';
import '@idme/id-card';
import '@idme/link';
import '@idme/modal';
import '@idme/navigation-button';

import i18n from './i18n';

class App extends Component {
  componentDidMount() {
    window.addEventListener('message', this.handleMessage);
    if (window.opener) {
      try {
        window.opener.postMessage({ status: 'complete' }, DOMAIN);
      } catch (e) {
        // fail silently
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
  }

  handleMessage = (e) => {
    if (e && e.data && e.data.status === 'close') {
      window.close();
    }
  };

  render() {
    return (
      <Suspense fallback={<LoadingPage />}>
        <Router basename={TRANSLATIONS_SUPPORTED ? i18n.language : null}>
          <ScrollToTop />
          <NavBar />
          <Switch>
            {routes.map((route) => (
              <AuthRoute key={route.path || '404'} {...route} />
            ))}
          </Switch>
          <Footer />
        </Router>
      </Suspense>
    );
  }
}

export { App };

export default hot(App);
