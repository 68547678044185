import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ButtonSecondary from '../ButtonSecondary';
import ButtonErrorSolid from '../ButtonErrorSolid';
import { getLoginProp, getLoginLogo } from '../../utils/loginPropMap';
import DeleteIcon from '../../img/icons/login-delete.svg';
import style from '../../style/components/signin/LoginDeleteModal.module.scss';

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

const LoginDeleteModal = ({ handleCancel, handleConfirm, isOpen, login }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={handleCancel}
    className={style.modal}
    overlayClassName={style.overlay}
  >
    <h3 className={style.header}>
      <img alt="Remove Login" src={DeleteIcon} />
    </h3>
    <div className={style.body}>
      <h4>
        Are you sure you would like to delete this login method from your ID.me
        account?
      </h4>
      <div className={style.logo}>
        <img
          className={style[login.handle]}
          src={getLoginLogo(login.handle)}
          alt="logo"
        />
      </div>
      <div className={style.name}>{login.name}</div>
      <div className={style.email}>
        {login.properties && getLoginProp(login, 'email')}
      </div>
      <div className={style.buttons}>
        <ButtonSecondary onClick={handleCancel}>Cancel</ButtonSecondary>
        <ButtonErrorSolid solid="true" onClick={handleConfirm}>
          Delete
        </ButtonErrorSolid>
      </div>
    </div>
  </Modal>
);

LoginDeleteModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  login: PropTypes.shape({}),
};

LoginDeleteModal.defaultProps = {
  login: {},
};

export default LoginDeleteModal;
