import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import walletIcon from '../../img/icons/wallet.svg';
import style from '../../style/components/signin/TwoFA.module.scss';

const OldWallet2FA = ({ handleSetup }) => {
  const top = (
    <div className={style.row}>
      <div className={style.imgWrapper}>
        <img alt="wallet" src={walletIcon} className={style.oldWallet} />
      </div>
      <div>
        <h5>ID.me Wallet</h5>
        <p className={style.error}>
          The ID.me Wallet app has been decommissioned
        </p>
      </div>
    </div>
  );

  const bottom = (
    <div className={style.alert}>
      <p>
        Please download the new&nbsp;
        <strong>ID.me Authenticator</strong>
        &nbsp;app to securely sign in to your ID.me account.
      </p>
      <div className={style.buttonWrapper}>
        <idme-button
          variant="secondary"
          size="small"
          text="Set Up"
          onClick={handleSetup}
        />
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className={style.deprecated}>
        {top}
        {bottom}
      </div>
    </Fragment>
  );
};

OldWallet2FA.propTypes = {
  handleSetup: PropTypes.func.isRequired,
};

export default OldWallet2FA;
