import React, { memo } from 'react';
import UserProps from '../../proptypes/user';
import style from '../../style/components/profile/PersonalProfile.module.scss';
import UpdateAddressButton from '../UpdateAddressButton';
import UpdateNameButton from '../UpdateNameButton';
import Card from '../Card';
import { addressLine1, addressLine2 } from '../../utils/address';

const PersonalProfile = ({ user }) => {
  const noName = !user.fname && !user.lname;
  const noAddress =
    !user.street && !user.street2 && !user.city && !user.state && !user.zip;

  return (
    <Card
      title="Personal Profile"
      icon={<idme-icon name="user" color="stone" size="large" />}
      className={style.card}
    >
      {noName && user.can_request_name_change ? null : (
        <>
          <section>
            <div>
              <h4 className={style.subtitle}>Name</h4>
              <p>{noName ? 'No name' : `${user.fname} ${user.lname}`}</p>
            </div>
            <div className={style.editButton}>
              {user.can_request_name_change ? <UpdateNameButton /> : null}
            </div>
          </section>
          <div className={style.separator} />
        </>
      )}
      <section>
        <div>
          <h4 className={style.subtitle}>Address</h4>
          <p className={style.noMarginBottom}>
            {noAddress ? 'No address' : addressLine1(user.street, user.street2)}
          </p>
          <p className={style.noMarginTop}>
            {addressLine2(user.city, user.state, user.zip)}
          </p>
        </div>
        <div className={style.editButton}>
          <UpdateAddressButton />
        </div>
      </section>
    </Card>
  );
};
PersonalProfile.propTypes = {
  user: UserProps,
};

export default memo(PersonalProfile);
