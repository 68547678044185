import React from 'react';
import style from '../../style/components/linkages/LinkedAccounts.module.scss';
import PropTypes from 'prop-types';
import websiteIcon from '../../img/icons/database.svg';

import WebsitesGrantedAccessTable from './WebsitesGrantedAccessTable';
import { useSelector } from 'react-redux';

const WebsitesGrantedAccess = ({ accounts }) => {
  const isMinor = useSelector((state) => state.user.is_minor);

  if (isMinor) {
    return null;
  }

  return (
    <div className={style.accounts}>
      <h3 className={style.title}>
        <img alt="" src={websiteIcon} />
        Websites Granted Access
      </h3>
      <div className={style.body}>
        These are websites that you have granteed access to your data. They can
        only request information you chose to share with them.
      </div>
      {accounts.length > 0 ? (
        <WebsitesGrantedAccessTable accounts={accounts} />
      ) : null}
    </div>
  );
};

WebsitesGrantedAccess.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})),
};

WebsitesGrantedAccess.defaultProps = {
  accounts: [],
};

export default WebsitesGrantedAccess;
