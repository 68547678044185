export const checkPassword = (password) => {
  if (!password || password.length === 0) {
    return [false, false, false, false];
  }
  return [
    password.length >= 8,
    /[A-Z]/.test(password),
    /[a-z]/.test(password),
    /[0-9]/.test(password),
  ];
};

export const rules = [
  'At least 8 characters',
  'Uppercase letter',
  'Lowercase letter',
  'Number',
];
