import React from 'react';
import style from '../style/components/FormInputs.module.scss';
import Checkbox from './Checkbox';

/**
 * CheckboxGroup component represents an input group of checkboxes.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.error - The error message to display.
 * @param {string} props.name - The name of the checkbox group.
 * @param {boolean} props.required - Indicates if the checkbox group is required.
 * @param {Array<{label: string, value: string, active: boolean}>} props.options - The options for the checkboxes.
 * @param {string} props.label - The label for the checkbox group.
 * @param {boolean} props.noMargin - Indicates if the checkbox group should have no margin.
 * @param {Function} props.toggleCheckbox - The function to toggle the checkbox.
 * @returns {JSX.Element} The rendered CheckboxGroup component.
 */
const CheckboxGroup = ({
  error,
  name,
  required,
  options,
  label,
  noMargin,
  toggleCheckbox,
}) => (
  <label
    htmlFor={name}
    className={`${style.wrapper} ${noMargin ? style.noMargin : ''}`}
  >
    <div className={style.label}>
      {label}
      {required && <span className={style.required}>&nbsp;*</span>}
    </div>
    <div className={style.checkboxGroup}>
      {options.map((opt) => (
        <Checkbox
          key={opt.value}
          id={opt.value}
          checked={opt.active}
          onChange={() => toggleCheckbox(opt.value)}
        >
          <div>{opt.label}</div>
        </Checkbox>
      ))}
    </div>
    {error && (
      <div className={style.error} role="alert">
        <strong>Error: </strong>
        {error}
      </div>
    )}
  </label>
);

export default CheckboxGroup;
