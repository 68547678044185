import React, { useState } from 'react';
import UpdateAddressModal from './changeRequests/UpdateAddressModal';
import SuccessModal from './SuccessModal';

const UpdateAddressButton = () => {
  const [isUpdateAddressModalOpen, setIsUpdateAddressModalOpen] =
    useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  return (
    <>
      <idme-button
        variant="tertiary"
        text="Edit"
        size="small"
        onClick={() => setIsUpdateAddressModalOpen(true)}
      />

      {isUpdateAddressModalOpen && (
        <UpdateAddressModal
          isOpen={isUpdateAddressModalOpen}
          closeModal={() => setIsUpdateAddressModalOpen(false)}
          openSuccessModal={() => setIsSuccessModalOpen(true)}
        />
      )}

      {isSuccessModalOpen && (
        <SuccessModal
          text="Your personal address has been updated."
          isOpen={isSuccessModalOpen}
          closeModal={() => setIsSuccessModalOpen(false)}
        />
      )}
    </>
  );
};

export default UpdateAddressButton;
