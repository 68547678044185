import {
  FETCH_SESSION_NEW_REDIRECT,
  FETCH_SESSION_NEW_FAILURE,
  FETCH_SESSION_END_BEGIN,
  FETCH_SESSION_END_FAILURE,
  FETCH_SESSION_BEGIN,
  FETCH_SESSION_SUCCESS,
  FETCH_SESSION_FAILURE,
  FETCH_SESSION_ERROR,
} from '../actions/session';

export const initialState = {
  error: null,
  fetched: false,
  isFetching: false,
  isLoggedIn: false,
  isLoggingOut: false,
};

const session = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_SESSION_NEW_REDIRECT:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_SESSION_NEW_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };

    case FETCH_SESSION_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_SESSION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        fetched: true,
        isFetching: false,
        isLoggedIn: true,
      };
    case FETCH_SESSION_FAILURE:
      return {
        ...state,
        fetched: false,
        isLoggedIn: false,
      };
    case FETCH_SESSION_ERROR:
      return {
        ...state,
        error: action.payload,
        fetched: true,
        isFetching: false,
        isLoggedIn: false,
      };

    case FETCH_SESSION_END_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
        isLoggingOut: true,
      };
    case FETCH_SESSION_END_FAILURE:
      // still clear things out!
      return {
        ...initialState,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default session;
