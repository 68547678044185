import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import IdmeLogo from '../img/idme-logo.svg';
import LinkedinLogo from '../img/icons/icon-linkedin.svg';
import FacebookLogo from '../img/icons/icon-facebook.svg';
import TwitterLogo from '../img/icons/icon-twitter.svg';
import InstagramLogo from '../img/icons/icon-instagram.svg';
import style from '../style/components/Footer.module.scss';
import { TERMS_URL } from '../constants';

const Logo = () => (
  <div className={style.social}>
    <a href="https://id.me">
      <img className={style.logo} src={IdmeLogo} alt="ID.me" />
    </a>
    <p className={style.copy}>
      ID.me simplifies how individuals prove and share their identity online.
    </p>
    <div className={style.social}>
      <a href="https://twitter.com/IDme" className={style.socialIcons}>
        <img
          className={style.iconTwitter}
          src={TwitterLogo}
          alt="Follow ID.me on Twitter"
        />
      </a>
      <a href="https://www.facebook.com/IDmeInc" className={style.socialIcons}>
        <img
          className={style.iconFacebook}
          src={FacebookLogo}
          alt="Follow ID.me on Facebook"
        />
      </a>
      <a
        href="https://www.linkedin.com/company/id.me"
        className={style.socialIcons}
      >
        <img
          className={style.iconLinkedin}
          src={LinkedinLogo}
          alt="Follow ID.me on Linkedin"
        />
      </a>
      <a href="https://www.instagram.com/id.me/" className={style.socialIcons}>
        <img
          className={style.iconInstagram}
          src={InstagramLogo}
          alt="Follow ID.me on Instagram"
        />
      </a>
    </div>
  </div>
);

const Discover = () => (
  <div className={style.discover}>
    <p className={style.title}>Discover</p>
    <div className={style.group}>
      <ul>
        <li>
          <a href="https://www.id.me/business/products">Products</a>
        </li>
        <li>
          <a href="https://www.id.me/business/solutions">Solutions</a>
        </li>
        <li>
          <a
            className="nav-item-link"
            href="https://www.id.me/business/industries"
          >
            Industries
          </a>
        </li>
        <li>
          <a href="https://www.id.me/individuals">Individuals</a>
        </li>
      </ul>
    </div>
  </div>
);

const Learn = () => (
  <div className={style.learn}>
    <p className={style.title}>Learn</p>
    <ul>
      <li>
        <a href="https://network.id.me/case-studies/">Case Studies</a>
      </li>
      <li>
        <a href="https://network.id.me/brochures">Data Sheets</a>
      </li>
      <li>
        <a href="https://network.id.me/white-papers/">White Papers</a>
      </li>
      <li>
        <a href="https://network.id.me/news-press-releases/">Press Releases</a>
      </li>
    </ul>
  </div>
);

const Connect = () => (
  <div className={style.connect}>
    <p className={style.title}>Connect</p>
    <ul>
      <li>
        <a href="https://www.id.me/contact">Contact Sales</a>
      </li>
      <li>
        <a href="https://help.id.me/hc/en-us">Contact Support</a>
      </li>
      <li>
        <a href="https://developers.id.me/">Developer Support</a>
      </li>
      <li>
        <a href="https://network.id.me/media-inquiry/">Media Inquiries</a>
      </li>
    </ul>
  </div>
);

const Legal = () => (
  <div className={style.legal}>
    <span>Copyright © ID.me, Inc.</span>
    <ul>
      <li>
        <a href="https://www.id.me/privacy">Privacy</a>
      </li>
      <li>
        <a href="https://www.id.me/biometric">Biometric Privacy</a>
      </li>
      <li>
        <a href={TERMS_URL}>Terms</a>
      </li>
      <li>
        <a href="https://www.id.me/cash-back-terms">Cash Back Terms</a>
      </li>
      <li>
        <Link to="/privacy">Privacy Rights Center</Link>
      </li>
    </ul>
  </div>
);

const Cta = () => (
  <div className={style.cta}>
    <a href="https://help.id.me" className={style.support}>
      Contact Support
      <span className={style.arrow}>&#10095;</span>
    </a>
  </div>
);

const Footer = () => (
  <footer className={style.footer}>
    <div className={style.content}>
      {Logo()}
      <div className={style.footerNav}>
        {Discover()}
        {Learn()}
        {Connect()}
      </div>
      {Cta()}
    </div>
    {Legal()}
  </footer>
);

export default memo(Footer);
