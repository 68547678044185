import React from 'react';
import PropTypes from 'prop-types';
import style from '../../style/components/profile/EmailConfirmationModal.module.scss';
import StyledModal from '../StyledModal';

const EmailConfirmationModal = ({ email, handleCancel, isOpen }) => (
  <StyledModal
    isOpen={isOpen}
    closeModal={handleCancel}
    title="Confirmation is pending for the following email"
    cancelButton={{ text: 'Close' }}
  >
    <div className={style.email}>{email}</div>
    <p>
      Please confirm this email address by clicking on the link in the email.
      This email will then be added to your ID.me account.
    </p>
  </StyledModal>
);

EmailConfirmationModal.propTypes = {
  email: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

EmailConfirmationModal.defaultProps = {
  email: '',
};

export default EmailConfirmationModal;
