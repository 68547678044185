import axios from 'axios';
import { URL_LOGINS } from '../constants/urls';
import { statusActive, statusInactive } from '../utils/filters';

export const FETCH_LOGINS_BEGIN = 'FETCH_LOGINS_BEGIN';
export const FETCH_LOGINS_ACTIVE_SUCCESS = 'FETCH_LOGINS_ACTIVE_SUCCESS';
export const FETCH_LOGINS_INACTIVE_SUCCESS = 'FETCH_LOGINS_INACTIVE_SUCCESS';
export const FETCH_LOGINS_FAILURE = 'FETCH_LOGINS_FAILURE';

export const FETCH_DELETE_LOGIN_BEGIN = 'FETCH_DELETE_LOGIN_BEGIN';
export const FETCH_DELETE_LOGIN_SUCCESS = 'FETCH_DELETE_LOGIN_SUCCESS';
export const FETCH_DELETE_LOGIN_FAILURE = 'FETCH_DELETE_LOGIN_FAILURE';

export const fetchLogins = () => async (dispatch) => {
  dispatch({ type: FETCH_LOGINS_BEGIN });

  try {
    const response = await axios.get(URL_LOGINS);
    const data = response.data || [];

    const active = data.filter(statusActive);
    const inactive = data.filter(statusInactive);

    dispatch({
      payload: active,
      type: FETCH_LOGINS_ACTIVE_SUCCESS,
    });
    dispatch({
      payload: inactive,
      type: FETCH_LOGINS_INACTIVE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_LOGINS_FAILURE,
    });
  }
};

export const deleteLogin = (id) => async (dispatch) => {
  dispatch({ type: FETCH_DELETE_LOGIN_BEGIN });

  try {
    const response = await axios.delete(`${URL_LOGINS}/${id}`);
    const data = response.data || [];

    const active = data.filter(statusActive);
    const inactive = data.filter(statusInactive);

    dispatch({
      payload: active,
      type: FETCH_LOGINS_ACTIVE_SUCCESS,
    });
    dispatch({
      payload: inactive,
      type: FETCH_LOGINS_INACTIVE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_DELETE_LOGIN_FAILURE,
    });
  }
};
