import React from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from '../ButtonPrimary';
import ButtonSecondary from '../ButtonSecondary';
import lockIcon from '../../img/icons/password.svg';
import PasswordValidation from './PasswordValidation';
import style from '../../style/components/signin/Password.module.scss';
import Card from '../Card';
import InputWithError from '../InputWithError';

const Password = ({
  confirmation,
  current,
  error,
  handleCancel,
  handleCurrent,
  handleNew1,
  handleNew2,
  handleSave,
  instruction,
  isSaveDisabled,
  newPass,
  invalid,
  rules,
  success,
  validated,
}) => (
  <Card
    title="Change Password"
    icon={<img className={style.img} alt="lock" src={lockIcon} />}
    className={style.wrapper}
  >
    <form className={style.form} onSubmit={handleSave}>
      <fieldset>
        <label htmlFor="old-pass">
          <span>{instruction}</span>
          <InputWithError
            id="old-pass"
            type="password"
            label="Current Password"
            value={current}
            onChange={handleCurrent}
            placeholder="Type your current password"
            required
          />
        </label>
        <label htmlFor="new-pass">
          <span className={style.srOnly}>New Password</span>
          <InputWithError
            id="new-pass"
            type="password"
            label="New Password"
            onChange={handleNew1}
            placeholder="Type your new password"
            value={newPass}
            className={invalid ? style.invalid : ''}
            required
          />
        </label>
        <label htmlFor="new-pass-2">
          <span className={style.srOnly}>New Password</span>
          <InputWithError
            id="new-pass-2"
            type="password"
            label="Confirm New Password"
            onChange={handleNew2}
            placeholder="Retype your new password"
            value={confirmation}
            required
          />
        </label>
      </fieldset>
      <PasswordValidation rules={rules} validated={validated} />
      <div className={style.controls}>
        <ButtonSecondary onClick={handleCancel}>Cancel</ButtonSecondary>
        <ButtonPrimary
          type="submit"
          onClick={handleSave}
          disabled={isSaveDisabled}
        >
          Save
        </ButtonPrimary>
        {success && <span className={style.success}>Password updated.</span>}
        {error && Array.isArray(error) && error.length > 0 && (
          <div className={style.error}>
            {error.map((e) => (
              <p key={e}>{e}</p>
            ))}
          </div>
        )}
      </div>
    </form>
  </Card>
);

Password.propTypes = {
  confirmation: PropTypes.string.isRequired,
  current: PropTypes.string.isRequired,
  error: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleCurrent: PropTypes.func.isRequired,
  handleNew1: PropTypes.func.isRequired,
  handleNew2: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  instruction: PropTypes.string,
  isSaveDisabled: PropTypes.bool,
  newPass: PropTypes.string.isRequired,
};

Password.defaultProps = {
  instruction: 'Choose a unique password to protect your account',
  isSaveDisabled: true,
};

export default Password;
