import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import LinkedAccountsAccounts from '../containers/LinkedAccountsAccounts';

import style from '../style/pages/LinkedAccounts.module.scss';
import { getReactEnv } from '../constants';

const LinkedAccounts = () => {
  useEffect(() => {
    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  return (
    <main className={style.content}>
      <LinkedAccountsAccounts />
    </main>
  );
};

export default LinkedAccounts;
