import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ButtonSecondary from '../ButtonSecondary';
import ButtonErrorSolid from '../ButtonErrorSolid';

import DeleteBackupcodes from '../../img/icons/backupcodes-delete.svg';
import DeleteDevicekey from '../../img/icons/devicekey-delete.svg';
import DeleteFidokey from '../../img/icons/fidokey-delete.svg';
import DeleteGenerator from '../../img/icons/generator-delete.svg';
import DeletePhone from '../../img/icons/phone-delete.svg';
import DeletePush from '../../img/icons/push-delete.svg';
import DeleteYubikey from '../../img/icons/yubikey-delete.svg';

import style from '../../style/components/signin/TwoFADeleteModal.module.scss';
import {
  BACKUPCODES,
  DEVICEKEY,
  FIDOKEY,
  GENERATOR,
  PHONE,
  PUSH,
  YUBIKEY,
} from '../../constants/mfa';

const icons = {
  [BACKUPCODES]: DeleteBackupcodes,
  [DEVICEKEY]: DeleteDevicekey,
  [FIDOKEY]: DeleteFidokey,
  [GENERATOR]: DeleteGenerator,
  [PHONE]: DeletePhone,
  [PUSH]: DeletePush,
  [YUBIKEY]: DeleteYubikey,
};

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

const TwoFADeleteModal = ({
  handleCancel,
  handleConfirm,
  isOpen,
  isSubmitting,
  name,
  mfaHandle,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={handleCancel}
    className={style.modal}
    overlayClassName={style.overlay}
  >
    <h3 className={style.header}>
      <img alt="Remove" src={icons[mfaHandle]} />
    </h3>
    <div className={style.body}>
      <h4>{`Delete ${name}?`}</h4>
      <p>If you do, it will no longer be associated with your ID.me account.</p>
      <div className={style.buttons}>
        <ButtonSecondary onClick={handleCancel}>Cancel</ButtonSecondary>
        <ButtonErrorSolid
          solid="true"
          onClick={handleConfirm}
          disabled={isSubmitting}
        >
          Delete
        </ButtonErrorSolid>
      </div>
    </div>
  </Modal>
);

TwoFADeleteModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  name: PropTypes.string,
};

TwoFADeleteModal.defaultProps = {
  name: '',
};

export default TwoFADeleteModal;
