import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import SigninPrivacyWebsites from '../../containers/SigninPrivacyWebsites';
import SigninPrivacyActivity from '../../containers/SigninPrivacyActivity';
import SigninPrivacyDataRights from '../../containers/SigninPrivacyDataRights';
import style from '../../style/components/signin/Privacy.module.scss';
import { getReactEnv } from '../../constants';

const Privacy = () => {
  useEffect(() => {
    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  return (
    <main className={style.wrapper}>
      <SigninPrivacyDataRights />
      <SigninPrivacyWebsites />
      <SigninPrivacyActivity />
    </main>
  );
};

export default Privacy;
