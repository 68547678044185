import axios from 'axios';
import { sendToSentry } from '../utils/error';
import { URL_LINKED_ACCOUNTS } from '../constants/urls';

export const FETCH_LINKED_ACCOUNTS_BEGIN = 'FETCH_LINKED_ACCOUNTS_BEGIN';
export const FETCH_LINKED_ACCOUNTS_SUCCESS = 'FETCH_LINKED_ACCOUNTS_SUCCESS';
export const FETCH_LINKED_ACCOUNTS_FAILURE = 'FETCH_LINKED_ACCOUNTS_FAILURE';

export const fetchLinkedAccounts = () => async (dispatch) => {
  dispatch({ type: FETCH_LINKED_ACCOUNTS_BEGIN });

  try {
    const response = await axios.get(URL_LINKED_ACCOUNTS);

    dispatch({
      payload: response.data,
      type: FETCH_LINKED_ACCOUNTS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_LINKED_ACCOUNTS_FAILURE,
    });
    if (error instanceof TypeError) {
      sendToSentry(error);
    }
  }
};

export const deleteLinkage =
  ({ id }) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_LINKED_ACCOUNTS_BEGIN,
    });

    try {
      const response = await axios.delete(`${URL_LINKED_ACCOUNTS}/${id}`);

      dispatch({
        payload: response.data,
        type: FETCH_LINKED_ACCOUNTS_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.message,
        type: FETCH_LINKED_ACCOUNTS_FAILURE,
      });
      if (error instanceof TypeError) {
        sendToSentry(error);
      }
    }
  };

export const cancelAuthorization =
  ({ id }) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_LINKED_ACCOUNTS_BEGIN,
    });

    try {
      const response = await axios.post(`${URL_LINKED_ACCOUNTS}/cancel`, {
        id,
      });

      dispatch({
        payload: response.data,
        type: FETCH_LINKED_ACCOUNTS_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.message,
        type: FETCH_LINKED_ACCOUNTS_FAILURE,
      });
    }
  };

export const revokeConsent =
  ({ id }) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_LINKED_ACCOUNTS_BEGIN,
    });

    try {
      const response = await axios.post(
        `${URL_LINKED_ACCOUNTS}/revoke_consent`,
        {
          id,
        },
      );

      dispatch({
        payload: response.data,
        type: FETCH_LINKED_ACCOUNTS_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.message,
        type: FETCH_LINKED_ACCOUNTS_FAILURE,
      });
    }
  };
