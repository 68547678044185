import React from 'react';
import { Link } from 'react-router-dom';
import style from '../../style/components/overview/MyActivity.module.scss';
import activityIcon from '../../img/icons/activities.svg';

const MyActivity = () => (
  <article className={style.activity}>
    <h3 className={style.title}>
      <img src={activityIcon} alt="icon" />
      My Activity
    </h3>
    <div className={style.signin}>
      <div className={style.description}>
        View login and verification activities on your account if you suspect
        fraudulent activity.
      </div>
    </div>
    <section className={style.manage}>
      <Link to="/signin/privacy">View All Activities</Link>
    </section>
  </article>
);

export default MyActivity;
