import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import RequestContainer from '../containers/CaliforniaRequest';
import style from '../style/pages/Request.module.scss';
import { getReactEnv } from '../constants';

const RequestData = () => {
  useEffect(() => {
    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  return (
    <div className={style.page}>
      <div className={style.content}>
        <RequestContainer />
      </div>
    </div>
  );
};
export default RequestData;
