import React from 'react';
import SupportRequestRow from './SupportRequestRow';
import style from '../../style/components/StyledTable.module.scss';
import LoadingSpinner from '../LoadingSpinner';

const SupportRequestsTable = ({ requests, isFetching }) => (
  <table className={style.table}>
    <thead>
      <tr>
        <th>TICKET #</th>
        <th>DESCRIPTION</th>
        <th>ACTIVITY</th>
        <th>UPDATED</th>
        <th>SUBMITTED</th>
      </tr>
    </thead>
    {requests.length ? (
      <tbody>
        {requests.map((request, index) => (
          <SupportRequestRow
            key={`${request.id}`}
            index={index}
            style={style}
            {...request}
          />
        ))}
      </tbody>
    ) : (
      <tfoot>
        <tr>
          <td className={style.none} colSpan="6">
            <p>
              {isFetching ? (
                <>
                  <LoadingSpinner />
                </>
              ) : (
                <>You don't have any open tickets.</>
              )}
            </p>
          </td>
        </tr>
      </tfoot>
    )}
  </table>
);

export default SupportRequestsTable;
