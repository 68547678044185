import {
  FETCH_LOGINS_BEGIN,
  FETCH_LOGINS_ACTIVE_SUCCESS,
  FETCH_LOGINS_INACTIVE_SUCCESS,
  FETCH_LOGINS_FAILURE,
  FETCH_DELETE_LOGIN_BEGIN,
  FETCH_DELETE_LOGIN_FAILURE,
} from '../actions/logins';

export const initialState = {
  error: null,
  isFetching: false,
  logins: [],
  loginsInactive: [],
};

const logins = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_LOGINS_BEGIN:
    case FETCH_DELETE_LOGIN_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_LOGINS_ACTIVE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        logins: action.payload,
      };
    case FETCH_LOGINS_INACTIVE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        loginsInactive: action.payload,
      };
    case FETCH_LOGINS_FAILURE:
    case FETCH_DELETE_LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default logins;
