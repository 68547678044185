import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Logins from '../components/signin/Logins';
import LoginDeleteModal from '../components/signin/LoginDeleteModal';
import { fetchLogins, deleteLogin } from '../actions/logins';
import { popup } from '../utils/popup';
import { DOMAIN, getReactEnv } from '../constants';

export class SigninLogins extends Component {
  static propTypes = {
    actionDeleteLogin: PropTypes.func.isRequired,
    actionFetchLogins: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    logins: PropTypes.arrayOf(PropTypes.shape({})),
    loginsInactive: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    isFetching: false,
    logins: [],
    loginsInactive: [],
  };

  state = {
    modalIsOpen: false,
  };

  componentDidMount() {
    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }

    const { actionFetchLogins, isFetching } = this.props;
    if (!isFetching) {
      actionFetchLogins();
    }

    window.addEventListener('message', this.handleMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
  }

  handleMessage = (e) => {
    const { actionFetchLogins } = this.props;
    if (e && e.data && e.data.status === 'complete') {
      actionFetchLogins();
      try {
        e.source &&
          e.source.postMessage &&
          e.source.postMessage({ status: 'close' }, DOMAIN);
      } catch (e) {
        // fail silently
      }
    }
  };

  handleLoginAdd = (url) => {
    popup('login', url);
  };

  handleLoginDelete = (login) => {
    this.setState({
      login,
      modalIsOpen: true,
    });
  };

  handleLoginDeleteCancel = () => {
    this.setState({
      login: undefined,
      modalIsOpen: false,
    });
  };

  handleLoginDeleteConfirm = async () => {
    const { actionDeleteLogin } = this.props;
    const { login, isFetching } = this.state;

    // Prevent multiple requests from being sent at the same time
    if (!isFetching) {
      await actionDeleteLogin(login.id);

      this.handleLoginDeleteCancel();
    }
  };

  render() {
    const { login, modalIsOpen } = this.state;
    const { logins, loginsInactive } = this.props;

    return (
      <Fragment>
        <Logins
          logins={logins}
          loginsInactive={loginsInactive}
          handleAdd={this.handleLoginAdd}
          handleDelete={this.handleLoginDelete}
        />
        <LoginDeleteModal
          isOpen={modalIsOpen}
          login={login}
          handleCancel={this.handleLoginDeleteCancel}
          handleConfirm={this.handleLoginDeleteConfirm}
        />
      </Fragment>
    );
  }
}

export const mapStateToProps = (state) => ({ ...state.logins });

const mapDispatchToProps = {
  actionDeleteLogin: deleteLogin,
  actionFetchLogins: fetchLogins,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SigninLogins),
);
