import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import {
  SENTRY_DSN,
  SENTRY_WHITELIST,
  SENTRY_ENABLED,
  getReactEnv,
} from '../constants';

if (SENTRY_ENABLED) {
  Sentry.init({
    autoSessionTracking: false,
    dsn: SENTRY_DSN,
    environment: getReactEnv(),
    release: process.env.REACT_APP_RELEASE,
    whitelistUrls: SENTRY_WHITELIST,
  });
}

const SentryConfig = () => {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.uuid) {
      Sentry.setUser({ id: user.uuid });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  return null;
};

export default SentryConfig;
