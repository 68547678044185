import React, { memo } from 'react';
import PropTypes from 'prop-types';
import style from '../style/components/Checkbox.module.scss';

const Checkbox = ({ checked, children, id, onChange, disabled, name }) => (
  <label className={style.label} htmlFor={`check-${id}`}>
    <input
      type="checkbox"
      id={`check-${id}`}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      name={name}
    />
    <div className={style.checkbox} aria-hidden="true" />
    <div className={style.children}>{children}</div>
  </label>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
};

export default memo(Checkbox);
