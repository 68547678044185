import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EmailConfirm from '../components/EmailConfirm';
import { confirmEmail } from '../actions/emailConfirmation';
import EmailSuccess from '../img/icons/email-success.svg';
import EmailError from '../img/icons/email-error.svg';

export class EmailConfirmContainer extends Component {
  static propTypes = {
    actionConfirmEmail: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    message: PropTypes.string,
    message2: PropTypes.string,
    showLink: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
  };

  static defaultProps = {
    message: '',
    message2: '',
  };

  async componentDidMount() {
    const { actionConfirmEmail, token } = this.props;

    const code = new URLSearchParams(window.location.search).get('code');
    await actionConfirmEmail(token, code);
  }

  render() {
    const { isFetching, isSuccess, message, message2, showLink } = this.props;

    let img;
    if (!isFetching) {
      img = isSuccess
        ? {
            alt: 'Email Success',
            src: EmailSuccess,
          }
        : {
            alt: 'Email Error',
            src: EmailError,
          };
    }

    return (
      <EmailConfirm
        img={img}
        isSuccess={isSuccess}
        loading={isFetching}
        message={message}
        message2={message2}
        showLink={showLink}
      />
    );
  }
}

const mapStateToProps = (state) => state.emailConfirmation;

const mapDispatchToProps = {
  actionConfirmEmail: confirmEmail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailConfirmContainer);
