import React from 'react';
import style from '../style/components/InfoCallout.module.scss';

const InfoCallout = ({ children }) => {
  return (
    <section className={style.callout}>
      <div>{children}</div>
    </section>
  );
};

export default InfoCallout;
