import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import MyIds from '../components/wallet/MyIds';
import { fetchIds } from '../actions/ids';
import { DOMAIN, getReactEnv } from '../constants';
import IdProps from '../proptypes/id';

export class WalletIds extends Component {
  static propTypes = {
    actionFetchIds: PropTypes.func.isRequired,
    active: PropTypes.arrayOf(
      PropTypes.shape({
        ...IdProps,
      }),
    ),
    expired: PropTypes.arrayOf(
      PropTypes.shape({
        ...IdProps,
      }),
    ),
    inactive: PropTypes.arrayOf(
      PropTypes.shape({
        ...IdProps,
      }),
    ),
    isFetching: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    active: [],
    expired: [],
    inactive: [],
  };

  state = {
    isActiveCollapsed: false,
    isExpiredCollapsed: false,
    isInactiveCollapsed: false,
  };

  componentDidMount() {
    const { actionFetchIds, isFetching } = this.props;
    if (!isFetching) {
      actionFetchIds();
    }
    window.addEventListener('message', this.handleMessage);

    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
  }

  handleMessage = (e) => {
    const { actionFetchIds } = this.props;
    if (e && e.data && e.data.status === 'complete') {
      actionFetchIds();
      try {
        e.source &&
          e.source.postMessage &&
          e.source.postMessage({ status: 'close' }, DOMAIN);
      } catch (e) {
        // fail silently
      }
    }
  };

  handleToggleActive = () => {
    this.setState((prevState) => ({
      isActiveCollapsed: !prevState.isActiveCollapsed,
    }));
  };

  handleToggleExpired = () => {
    this.setState((prevState) => ({
      isExpiredCollapsed: !prevState.isExpiredCollapsed,
    }));
  };

  handleToggleInactive = () => {
    this.setState((prevState) => ({
      isInactiveCollapsed: !prevState.isInactiveCollapsed,
    }));
  };

  render() {
    const { active, expired, inactive } = this.props;
    return (
      <MyIds
        {...this.state}
        active={active}
        expired={expired}
        inactive={inactive}
        collapseActive={this.handleToggleActive}
        collapseExpired={this.handleToggleExpired}
        collapseInactive={this.handleToggleInactive}
      />
    );
  }
}

export const mapStateToProps = (state) => ({
  ...state.ids,
});

const mapDispatchToProps = {
  actionFetchIds: fetchIds,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WalletIds),
);
