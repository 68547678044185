import { v4 as uuid } from 'uuid';

export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';
export const DISMISS_ERROR_MESSAGE = 'DISMISS_ERROR_MESSAGE';

export const addErrorMessage = () => async (dispatch, error) => {
  // TODO: map error to custom messages
  dispatch({
    payload: {
      id: uuid(),
      message: 'Something went wrong. Try again in a few minutes.',
    },
    type: ADD_ERROR_MESSAGE,
  });
};

export const dismissErrorMessage = (id) => async (dispatch) => {
  dispatch({
    payload: id,
    type: DISMISS_ERROR_MESSAGE,
  });
};
