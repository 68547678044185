import React from 'react';
import PropTypes from 'prop-types';
import UpdateEmailButton from './UpdateEmailButton';
import style from '../../style/components/profile/Email.module.scss';
import { WORK, PERSONAL } from '../../constants';
import EmailStatus from './EmailStatus';
import workProfile from '../../proptypes/workProfile';
import emailPropType from '../../proptypes/email';

const emailTypeText = (emailTypes) => {
  if (emailTypes.includes(WORK) && emailTypes.includes(PERSONAL)) {
    return 'Personal & Work';
  } else if (emailTypes.includes(WORK)) {
    return 'Work';
  } else if (emailTypes.includes(PERSONAL)) {
    return 'Personal';
  }
  return '';
};

const Email = ({
  email,
  emailTypeEnabled,
  isFetching,
  handlePrimary,
  handleResend,
  handleDelete,
  index,
  workProfiles,
}) => {
  const handlePrimaryClick = () => {
    handlePrimary(index);
  };

  const handleResendClick = () => {
    handleResend(index);
  };

  const handleDeleteClick = () => {
    handleDelete(index);
  };

  return (
    <li className={style.email}>
      <div className={style.flexArea}>
        <div className={style.address}>{email.address}</div>

        <div className={style.emailKindDiv}>
          {emailTypeEnabled ? (
            <div className={style.emailKind}>
              {emailTypeText(email.emailTypes)}
              {workProfiles.length > 0 ? (
                <div className={style.workProfileName}>
                  (
                  {workProfiles
                    .map((workProfile) => workProfile.business_name)
                    .join(', ')}
                  )
                </div>
              ) : null}
            </div>
          ) : null}
        </div>

        <div className={style.emailStatusDiv}>
          <EmailStatus
            isPrimary={email.isPrimary}
            isVerified={email.isVerified}
          />
        </div>
      </div>

      <div>
        <div className={style.buttonContainer}>
          {!email.isPrimary || !email.isVerified || emailTypeEnabled ? (
            <UpdateEmailButton
              email={email}
              emailTypeEnabled={emailTypeEnabled}
              handlePrimary={handlePrimaryClick}
              handleResend={handleResendClick}
              handleDelete={handleDeleteClick}
              isFetching={isFetching}
            />
          ) : null}
        </div>
      </div>
    </li>
  );
};

Email.propTypes = {
  email: emailPropType.isRequired,
  emailTypeEnabled: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handlePrimary: PropTypes.func.isRequired,
  handleResend: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isFetching: PropTypes.bool,
  workProfiles: PropTypes.arrayOf(workProfile),
};

Email.defaultProps = {
  isFetching: false,
  workProfiles: [],
};

export default Email;
