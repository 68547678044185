import React from 'react';
import PropTypes from 'prop-types';
import StyledModal from '../StyledModal';
import { PERSONAL, WORK } from '../../constants';
import ToastNotification from '../ToastNotification';
import emailPropType from '../../proptypes/email';
import style from '../../style/components/profile/EmailDeleteModal.module.scss';

const EmailDeleteModal = ({
  emails,
  email,
  handleCancel,
  handleConfirm,
  isOpen,
}) => {
  if (!email) {
    return null;
  }

  const isLastWorkEmail =
    email.emailTypes.includes(WORK) &&
    emails.filter((e) => e.emailTypes.includes(WORK)).length === 1;

  const isLastPersonalEmail =
    email.emailTypes.includes(PERSONAL) &&
    emails.filter((e) => e.emailTypes.includes(PERSONAL)).length === 1;

  return (
    <StyledModal
      isOpen={isOpen}
      closeModal={handleCancel}
      title={`Are you sure you want to remove ${email.address}?`}
      primaryButton={{
        text: 'Remove',
        action: handleConfirm,
      }}
    >
      <p>If you do, it will no longer be associated with your ID.me account.</p>
      {isLastWorkEmail && (
        <ToastNotification type="warning">
          <span className={style.semibold}>{email.address}</span> is the only
          "Work" email on your account. If you use this account for work
          purposes, you may be required to add a new "Work" email in the future.
        </ToastNotification>
      )}
      {isLastPersonalEmail && (
        <ToastNotification type="warning">
          <span className={style.semibold}>{email.address}</span> is the only
          "Personal" email on your account. Remember, you can only have one
          ID.me account, and it's best to always have a personal email on your
          account since you may lose access to your work email. You may be
          required to add a "Personal" email in the future if you delete it now.
        </ToastNotification>
      )}
    </StyledModal>
  );
};

EmailDeleteModal.propTypes = {
  email: emailPropType,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default EmailDeleteModal;
