import React, { useState } from 'react';
import style from '../style/components/SuccessCard.module.scss';
import checkmark from '../img/icons/success-checkmark.svg';
import close from '../img/icons/close.svg';
import ButtonLink from './ButtonLink';

const SuccessCard = ({ children }) => {
  const [open, setOpen] = useState(true);

  return (
    open && (
      <section className={style.card}>
        <div className={style.iconLeft}>
          <img src={checkmark} alt="checkmark" />
        </div>

        <ButtonLink onClick={() => setOpen(false)}>
          <div className={style.iconRight}>
            <img src={close} alt="close" />
          </div>
        </ButtonLink>

        <div>{children}</div>
      </section>
    )
  );
};

export default SuccessCard;
