import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import WebsitesGrantedAccess from '../components/signin/WebsitesGrantedAccess';
import { fetchWebsites, deleteWebsite } from '../actions/websites';
import memoize from '../utils/memoize';
import WebsiteProps from '../proptypes/website';
import ConsentDeleteModal from '../components/signin/ConsentDeleteModal';

// naive search function. this could be improved
export const siteSearch = (sites, search) =>
  sites.filter(
    (site) =>
      site.organization.toLocaleLowerCase().indexOf(search) !== -1 ||
      site.groups.reduce(
        (isMatch, group) =>
          isMatch || group.toLocaleLowerCase().indexOf(search) !== -1,
        false,
      ),
  );

export class SigninPrivacyWebsites extends Component {
  static propTypes = {
    actionDeleteWebsite: PropTypes.func.isRequired,
    actionFetchWebsites: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    websites: PropTypes.arrayOf(WebsiteProps),
  };

  static defaultProps = {
    websites: [],
  };

  state = {
    consent: {},
    search: '',
  };

  filterWebsites = memoize(siteSearch);

  componentDidMount() {
    const { isFetching, actionFetchWebsites } = this.props;
    if (!isFetching) {
      actionFetchWebsites();
    }
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({
      search: value,
    });
  };

  handleConsentDelete = (consent) => {
    this.setState({
      consent,
      modalIsOpen: true,
    });
  };

  handleConsentDeleteCancel = () => {
    this.setState({
      consent: {},
      modalIsOpen: false,
    });
  };

  handleConsentDeleteConfirm = async () => {
    const { actionDeleteWebsite } = this.props;
    const { consent } = this.state;

    await actionDeleteWebsite(consent.id);

    this.handleConsentDeleteCancel();
  };

  render() {
    const { consent = {}, modalIsOpen, search } = this.state;
    const { websites } = this.props;
    const filteredWebsites = this.filterWebsites(
      websites,
      search.toLocaleLowerCase(),
    );

    return (
      <>
        <WebsitesGrantedAccess
          search={search}
          handleChange={this.handleChange}
          handleRevoke={this.handleConsentDelete}
          websites={filteredWebsites}
        />
        <ConsentDeleteModal
          isOpen={modalIsOpen}
          org={consent.organization}
          handleCancel={this.handleConsentDeleteCancel}
          handleConfirm={this.handleConsentDeleteConfirm}
        />
      </>
    );
  }
}

export const mapStateToProps = (state) => ({ ...state.websites });

const mapDispatchToProps = {
  actionDeleteWebsite: deleteWebsite,
  actionFetchWebsites: fetchWebsites,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SigninPrivacyWebsites),
);
