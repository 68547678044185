import React from 'react';
import style from '../style/components/FormInputs.module.scss';

const LabelTextError = ({
  error,
  label,
  name,
  required,
  type = 'text',
  ...rest
}) => (
  <label htmlFor={name} className={style.wrapper}>
    <div className={style.label}>
      {label}
      {required && <span className={style.required}>&nbsp;*</span>}
    </div>
    <input
      type={type}
      className={error ? style.errorInput : ''}
      id={name}
      name={name}
      {...rest}
    />
    {error && (
      <div className={style.error} role="alert">
        {error}
      </div>
    )}
  </label>
);

export default LabelTextError;
