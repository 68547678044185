import React, { useRef } from 'react';
import UserProps from '../proptypes/user';
import { IDME_URL, RX_URL, SHOP_URL } from '../constants';

const GlobalNav = ({ user }) => {
  const globalNavRef = useRef(null);

  return (
    <idme-global-nav
      helpCenterHref="https://help.id.me"
      logoHref={IDME_URL}
      navTitle="Site"
      myAccountHref="/"
      logOutAuthenticityToken="form_authenticity_token,"
      isSignedIn
      logOutHref="/logout"
      logOutMethod="get"
      registerLinkHref="new_registration_path,"
      userEmail={user.email}
      userName={user.fname || ''}
      ref={globalNavRef}
    >
      <idme-nav-item
        href={`${SHOP_URL}?&utm_campaign=B2C&utm_source=nav_menu&utm_medium=web`}
        text="Shop"
      ></idme-nav-item>
      <idme-nav-item
        href={`${IDME_URL}/government?utm_campaign=B2C&utm_medium=web&utm_source=nav_menu`}
        text="Government"
      ></idme-nav-item>
      <idme-nav-item
        href={`${RX_URL}?&utm_campaign=B2C&utm_source=nav_menu&utm_medium=web`}
        text="Rx"
      ></idme-nav-item>
      <idme-nav-item
        href={`${IDME_URL}/jobshub?utm_campaign=B2C&utm_medium=web&utm_source=nav_menu`}
        text="Jobs"
      ></idme-nav-item>
    </idme-global-nav>
  );
};

GlobalNav.propTypes = {
  user: UserProps.isRequired,
};

export default GlobalNav;
