import React, { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import UserProps from '../../proptypes/user';
import User from '../User';
import ProfileIcon from '../../img/icons/profile.svg';
import style from '../../style/components/overview/Profile.module.scss';

const Profile = ({ user }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <article className={style.top}>
        <h3 className={style.title}>
          <img alt="ID icon" src={ProfileIcon} />
          {t('overview.myProfile')}
        </h3>
        <div className={style.content}>
          {user.email && <User user={user} style={style} />}
        </div>
      </article>
      <section className={style.bottom} />
    </Fragment>
  );
};

Profile.propTypes = {
  user: UserProps,
};

Profile.defaultProps = {
  user: {},
};

export default memo(Profile);
