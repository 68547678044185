import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  fetchDataDownloadStatus,
  fetchDataLockStatus,
  fetchIsCaliforniaResident,
} from '../actions/california';
import LoadingPage from '../components/LoadingPage';
import ManageData from '../components/california/ManageData';
import ConfirmCalifornia from '../components/california/Confirm';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import ButtonSecondary from '../components/ButtonSecondary';
import style from '../style/components/california/DeleteDataWarningModal.module.scss';

const disabledActions = ({ isDownloadPending, isLocked, isLockPending }) => {
  const disabled = [];
  if (isDownloadPending) {
    disabled.push('request');
  }
  if (isLocked || isLockPending) {
    disabled.push('lock');
  }
  return disabled;
};

class ManageDataContainer extends React.Component {
  componentDidMount() {
    const {
      actionFetchDataDownloadStatus,
      actionFetchDataLockStatus,
      actionFetchIsCaliforniaResident,
    } = this.props;

    actionFetchIsCaliforniaResident();
    actionFetchDataDownloadStatus();
    actionFetchDataLockStatus();
  }

  state = {
    isOpen: false,
  };

  handleDeleteData = () => {
    this.setState({ isOpen: true });
  };

  handleBack = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const {
      isDownloadPending,
      isFetched,
      isCaliforniaResident,
      isLockPending,
      isLocked,
    } = this.props;

    const { isOpen } = this.state;

    if (!isFetched) {
      return <LoadingPage />;
    }

    if (isCaliforniaResident) {
      const disabled = disabledActions({
        isDownloadPending,
        isLocked,
        isLockPending,
      });
      return (
        <Fragment>
          <ManageData
            disabled={disabled}
            handleDeleteData={this.handleDeleteData}
          />
          <Modal
            isOpen={isOpen}
            className={style.modal}
            overlayClassName={style.overlay}
          >
            <div className={style.body}>
              <p>
                Per the National Institute of Standards and Technology policy
                standards (i.e., NIST 800-63-2 and 800-63-3), the National
                Archives requires that this encrypted evidence (which includes
                biometric data) be securely retained as proof of this
                verification. Biometric data includes things like the photo on
                your government-issued ID. This data is only used to complete
                the transaction for which the personal information was collected
                and to prevent fraudulent verification attempts. How long the
                data is needed will depend on the nature of the data and
                relevant legal or operational retention needs. This data will
                not be sold or shared with third parties in connection with any
                commercial purpose.
              </p>
              <p>
                The following bases under CA law for denial of deletion apply
                here:
              </p>
              <p>
                <ol>
                  <li>
                    Complete the transaction for which the personal information
                    was collected, fulfill the terms of a written warranty or
                    product recall conducted in accordance with federal law,
                    provide a good or service requested by the consumer, or
                    reasonably anticipated by the consumer within the context of
                    a business’ ongoing business relationship with the consumer,
                    or otherwise perform a contract between the business and the
                    consumer.
                  </li>
                  <li>
                    Help to ensure security and integrity to the extent the use
                    of the consumer’s personal information is reasonably
                    necessary and proportionate for those purposes.
                  </li>
                </ol>
              </p>
              <p>You may choose to either:</p>
              <p>
                <Link to="/signin/privacy">Revoke permission</Link> from
                selected organizations to view your data, or Continue to delete
                your ID.me account:
              </p>
              <div className={style.buttonContainer}>
                <ButtonSecondary onClick={this.handleBack}>
                  Back
                </ButtonSecondary>

                <Link className={style.link} to="/signin/close">
                  Continue
                </Link>
              </div>
            </div>
          </Modal>
        </Fragment>
      );
    }

    const { url } = this.props;

    return <ConfirmCalifornia url={url} />;
  }
}

const mapStateToProps = (state) => ({
  ...state.california,
});

const mapDispatchToProps = {
  actionFetchDataDownloadStatus: fetchDataDownloadStatus,
  actionFetchDataLockStatus: fetchDataLockStatus,
  actionFetchIsCaliforniaResident: fetchIsCaliforniaResident,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageDataContainer);
