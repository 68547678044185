import React, { memo } from 'react';
import PropTypes from 'prop-types';
import style from '../style/components/ButtonErrorSolid.module.scss';

const ButtonErrorSolid = ({ children, ...props }) => (
  <button className={style.buttonErrorSolid} type="button" {...props}>
    {children}
  </button>
);

ButtonErrorSolid.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(ButtonErrorSolid);
