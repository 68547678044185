import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MMDDYYYY, HHMM } from '../../utils/time';
import combineStrings from '../../utils/combineStrings';

const Location = (location, ip, style) => {
  const formattedLocation = combineStrings([
    location.city,
    location.state,
    location.country,
  ]);

  if (formattedLocation || ip) {
    return (
      <td title="IP LOCATION">
        {formattedLocation && (
          <div className={style.bold}>{formattedLocation}</div>
        )}
        {ip && <div>{ip}</div>}
      </td>
    );
  }

  return <td title="IP LOCATION">N/A</td>;
};

const ActivityRow = ({
  browser,
  device,
  ip,
  location,
  method,
  timestamp,
  type,
  style,
}) => (
  <tr className={style.row}>
    <td title="TIME STAMP">
      {MMDDYYYY(timestamp)}&nbsp;{HHMM(timestamp)}
    </td>
    <td title="ACTIVITY" className={style.wordBreakBold}>
      {type}
    </td>
    <td title="DETAILS" className={style.wordBreakBold}>
      {method}
    </td>
    <td title="DEVICE">
      <div className={style.bold}>{device}</div>
      <div>{browser}</div>
    </td>
    {Location(location, ip, style)}
  </tr>
);

ActivityRow.propTypes = {
  browser: PropTypes.string,
  device: PropTypes.string,
  ip: PropTypes.string,
  location: PropTypes.shape({}),
  method: PropTypes.string,
  style: PropTypes.shape({}).isRequired,
  timestamp: PropTypes.string,
  type: PropTypes.string,
};

ActivityRow.defaultProps = {
  browser: '',
  device: '',
  ip: '',
  location: {},
  method: '',
  timestamp: '',
  type: '',
};

export default memo(ActivityRow);
