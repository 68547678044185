import React from 'react';
import { Link } from 'react-router-dom';
import style from '../style/components/SideNav.module.scss';

const SideNav = ({ current, routes }) => (
  <nav className={style.nav}>
    <ul>
      {routes.map((route) => (
        <li key={route.to} className={current === route.to ? style.active : ''}>
          <Link {...route} />
        </li>
      ))}
    </ul>
  </nav>
);

export default SideNav;
