import {
  FETCH_ALERTS_BEGIN,
  FETCH_ALERTS_SUCCESS,
  FETCH_ALERTS_FAILURE,
  CONFIRM_ALERTS_BEGIN,
  CONFIRM_ALERTS_SUCCESS,
  CONFIRM_ALERTS_FAILURE,
} from '../actions/alerts';

export const initialState = {
  error: null,
  isFetching: false,
  visible: false,
};

const alerts = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_ALERTS_BEGIN:
    case CONFIRM_ALERTS_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_ALERTS_SUCCESS:
    case CONFIRM_ALERTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    case FETCH_ALERTS_FAILURE:
    case CONFIRM_ALERTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default alerts;
