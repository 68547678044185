import axios from 'axios';
import {
  URL_CCPA_LEGAL,
  URL_DATA,
  URL_DATA_RESIDENCY,
  URL_DATA_STATUS,
} from '../constants/urls';
import {
  CCPA_DOWNLOAD,
  CCPA_LOCK,
  PROCESSING,
  PREPARED,
  COMPLETED,
  CALIFORNIA,
} from '../constants';

export const FETCH_CCPA_BEGIN = 'FETCH_CCPA_BEGIN';
export const FETCH_CCPA_SUCCESS = 'FETCH_CCPA_SUCCESS';
export const FETCH_CCPA_FAILURE = 'FETCH_CCPA_FAILURE';

export const FETCH_CALIFORNIA_BEGIN = 'FETCH_CALIFORNIA_BEGIN';
export const FETCH_CALIFORNIA_SUCCESS = 'FETCH_CALIFORNIA_SUCCESS';
export const FETCH_CALIFORNIA_FAILURE = 'FETCH_CALIFORNIA_FAILURE';

export const FETCH_REQUEST_DATA_BEGIN = 'FETCH_REQUEST_DATA_BEGIN';
export const FETCH_REQUEST_DATA_SUCCESS = 'FETCH_REQUEST_DATA_SUCCESS';
export const FETCH_REQUEST_DATA_FAILURE = 'FETCH_REQUEST_DATA_FAILURE';

export const FETCH_LOCK_DATA_BEGIN = 'FETCH_LOCK_DATA_BEGIN';
export const FETCH_LOCK_DATA_SUCCESS = 'FETCH_LOCK_DATA_SUCCESS';
export const FETCH_LOCK_DATA_FAILURE = 'FETCH_LOCK_DATA_FAILURE';

export const FETCH_DATA_STATUS_BEGIN = 'FETCH_DATA_STATUS_BEGIN';
export const FETCH_DATA_STATUS_SUCCESS = 'FETCH_DATA_STATUS_SUCCESS';
export const FETCH_DATA_STATUS_FAILURE = 'FETCH_DATA_STATUS_FAILURE';

export const FETCH_LOCK_STATUS_BEGIN = 'FETCH_LOCK_STATUS_BEGIN';
export const FETCH_LOCK_STATUS_SUCCESS = 'FETCH_LOCK_STATUS_SUCCESS';
export const FETCH_LOCK_STATUS_FAILURE = 'FETCH_LOCK_STATUS_FAILURE';

export const FETCH_DATA_FILE_BEGIN = 'FETCH_DATA_FILE_BEGIN';
export const FETCH_DATA_FILE_SUCCESS = 'FETCH_DATA_FILE_SUCCESS';
export const FETCH_DATA_FILE_FAILURE = 'FETCH_DATA_FILE_FAILURE';

/**
 * @deprecated
 */
export const fetchCCPA = () => async (dispatch) => {
  dispatch({ type: FETCH_CCPA_BEGIN });

  try {
    const response = await axios.get(URL_CCPA_LEGAL);
    response.data.version = response.data.version.number;

    dispatch({
      payload: response.data,
      type: FETCH_CCPA_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error && error.response && error.response.statusText,
      type: FETCH_CCPA_FAILURE,
    });
  }
};

/**
 * @deprecated
 */
export const fetchIsCaliforniaResident = () => async (dispatch) => {
  dispatch({ type: FETCH_CALIFORNIA_BEGIN });

  try {
    const response = await axios.get(URL_DATA_RESIDENCY);

    dispatch({
      payload: response.data,
      type: FETCH_CALIFORNIA_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error && error.response && error.response.statusText,
      type: FETCH_CALIFORNIA_FAILURE,
    });
  }
};

/**
 * @deprecated
 */
export const requestData = () => async (dispatch) => {
  dispatch({ type: FETCH_REQUEST_DATA_BEGIN });

  try {
    await axios.post(URL_DATA, {
      category: CCPA_DOWNLOAD,
      state: CALIFORNIA,
    });

    dispatch({
      type: FETCH_REQUEST_DATA_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error && error.response && error.response.statusText,
      type: FETCH_REQUEST_DATA_FAILURE,
    });
  }
};

/**
 * @deprecated
 */
export const lockData = () => async (dispatch) => {
  dispatch({ type: FETCH_LOCK_DATA_BEGIN });

  try {
    await axios.post(URL_DATA, {
      category: CCPA_LOCK,
      state: CALIFORNIA,
    });

    dispatch({
      type: FETCH_LOCK_DATA_SUCCESS,
    });
    return true;
  } catch (error) {
    dispatch({
      payload: error && error.response && error.response.statusText,
      type: FETCH_LOCK_DATA_FAILURE,
    });
  }
};

/**
 * @deprecated
 */
export const fetchDataDownloadStatus = () => async (dispatch) => {
  dispatch({ type: FETCH_DATA_STATUS_BEGIN });

  try {
    const response = await axios.get(
      `${URL_DATA_STATUS}?category=${CCPA_DOWNLOAD}`,
    );

    let isDownloadPending = false;
    let isDownloadReady = false;

    try {
      isDownloadPending = response.data.status === PROCESSING;
      isDownloadReady = response.data.status === PREPARED;
    } catch {}

    dispatch({
      payload: { isDownloadPending, isDownloadReady },
      type: FETCH_DATA_STATUS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error && error.response && error.response.statusText,
      type: FETCH_DATA_STATUS_FAILURE,
    });
  }
};

/**
 * @deprecated
 */
export const fetchDataLockStatus = () => async (dispatch) => {
  dispatch({ type: FETCH_LOCK_STATUS_BEGIN });

  try {
    const response = await axios.get(
      `${URL_DATA_STATUS}?category=${CCPA_LOCK}`,
    );

    let isLockPending = false;
    let isLocked = false;

    try {
      isLockPending = response.data.status === PROCESSING;
      isLocked = response.data.status === COMPLETED;
    } catch {}

    dispatch({
      payload: { isLocked, isLockPending },
      type: FETCH_LOCK_STATUS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error && error.response && error.response.statusText,
      type: FETCH_LOCK_STATUS_FAILURE,
    });
  }
};
