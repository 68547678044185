import axios from 'axios';
import { URL_EMAIL_CONFIRMATION } from '../constants/urls';

export const FETCH_EMAIL_CONFIRM_BEGIN = 'FETCH_EMAIL_CONFIRM_BEGIN';
export const FETCH_EMAIL_CONFIRM_SUCCESS = 'FETCH_EMAIL_CONFIRM_SUCCESS';
export const FETCH_EMAIL_CONFIRM_FAILURE = 'FETCH_EMAIL_CONFIRM_FAILURE';

export const confirmEmail = (token, code) => async (dispatch) => {
  dispatch({ type: FETCH_EMAIL_CONFIRM_BEGIN });

  try {
    await axios.put(`${URL_EMAIL_CONFIRMATION}${token}`, null, {
      params: { code: code },
    });

    dispatch({ type: FETCH_EMAIL_CONFIRM_SUCCESS });

    return true;
  } catch (error) {
    dispatch({
      payload: error && error.response && error.response.statusText,
      type: FETCH_EMAIL_CONFIRM_FAILURE,
    });
    return false;
  }
};
