import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SHOP, SHOP_URL } from '../../constants';
import style from '../../style/components/overview/Preferences.module.scss';
import PreferencesIcon from '../../img/icons/preferences.svg';
import HomepageIcon from '../../img/icons/homepage.svg';
import SubscriptionsIcon from '../../img/icons/subscriptions.svg';

export const homepageLink = (homepage) => {
  if (homepage === SHOP) {
    return <a href={SHOP_URL}>ID.me Shop</a>;
  }

  return (
    <a href="https://www.id.me/government?utm_source=account">
      ID.me Government
    </a>
  );
};

const Preferences = ({ homepage, count }) => (
  <article className={style.preferences}>
    <h3 className={style.title}>
      <img src={PreferencesIcon} alt="icon" />
      My Preferences
    </h3>
    <div className={style.detail}>
      <img src={HomepageIcon} alt="homepage" />
      <span>Default Homepage</span>
      {homepageLink(homepage)}
    </div>
    <div className={style.detail}>
      <img src={SubscriptionsIcon} alt="subscriptions" />
      <span>Email Subscriptions</span>
      <Link to="/profile">{`${count} Active`}</Link>
    </div>
    <section className={style.manage}>
      <Link to="/profile">Manage My Preferences</Link>
    </section>
  </article>
);

Preferences.propTypes = {
  count: PropTypes.number,
  homepage: PropTypes.string,
};

Preferences.defaultProps = {
  count: 0,
  homepage: '',
};

export default memo(Preferences);
