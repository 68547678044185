import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Preferences from '../components/profile/Preferences';
import {
  fetchHomepage,
  fetchSubscriptions,
  toggleSubscription,
  toggleAllSubscriptions,
  saveHomepage,
  saveSubscriptions,
} from '../actions/preferences';
import memoize from '../utils/memoize';
import { ACCOUNT, WALLET } from '../constants';
import UserProps from '../proptypes/user';

export class ProfilePreferences extends Component {
  static propTypes = {
    actionFetchHomepage: PropTypes.func.isRequired,
    actionFetchSubscriptions: PropTypes.func.isRequired,
    actionToggleAllSubscriptions: PropTypes.func.isRequired,
    actionToggleSubscription: PropTypes.func.isRequired,
    homepage: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    subscriptions: PropTypes.arrayOf(PropTypes.shape({})),
    user: UserProps.isRequired,
  };

  static defaultProps = {
    homepage: '',
    subscriptions: [],
  };

  state = {};

  sliderChecked = memoize((subscriptions) => {
    if (!subscriptions || !subscriptions.length || !subscriptions.every) {
      return false;
    }
    const isChecked = (sub) => sub.active;
    return subscriptions.every(isChecked);
  });

  componentDidMount() {
    const { actionFetchSubscriptions, actionFetchHomepage, isFetching } =
      this.props;
    if (!isFetching) {
      actionFetchSubscriptions();
      actionFetchHomepage();
    }
  }

  handleHomepageChange = (e) => {
    const { actionSaveHomepage, isFetching } = this.props;

    if (isFetching) {
      return;
    }
    actionSaveHomepage(e.target.value);
  };

  handleSubscriptionToggle = (index) => {
    let { subscriptions, actionSaveSubscriptions, isFetching } = this.props;

    if (isFetching) {
      return;
    }

    const itemToToggle = { ...subscriptions[index] };
    itemToToggle.active = !itemToToggle.active;
    subscriptions = [
      ...subscriptions.slice(0, index),
      itemToToggle,
      ...subscriptions.slice(index + 1),
    ];

    actionSaveSubscriptions(subscriptions);

    // optimistically toggle
    this.props.actionToggleSubscription(index);
  };

  handleSubscriptionToggleAll = () => {
    let { subscriptions, actionSaveSubscriptions, isFetching } = this.props;

    if (isFetching) {
      return;
    }

    const allChecked = subscriptions.every((sub) => sub.active);

    subscriptions = subscriptions.map((sub) => ({
      ...sub,
      active: !allChecked,
    }));

    actionSaveSubscriptions(subscriptions);

    // optimistically toggle all
    this.props.actionToggleAllSubscriptions();
  };

  render() {
    const { homepage, isFetching, subscriptions, user } = this.props;
    const funcs = {
      handleCheck: this.handleSubscriptionToggle,
      handleRadioChange: this.handleHomepageChange,
      handleSubscribe: this.handleSubscriptionToggleAll,
    };

    const sliderChecked = this.sliderChecked(subscriptions);

    if (user?.is_minor) {
      return null;
    }

    return (
      <Preferences
        defaultHomepage={homepage === WALLET ? ACCOUNT : homepage} // TODO: only pass homepage after account-api accepts WALLET
        sliderChecked={sliderChecked}
        subscriptions={subscriptions}
        isFetching={isFetching}
        {...funcs}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  ...state.preferences,
});

const mapDispatchToProps = {
  actionFetchHomepage: fetchHomepage,
  actionFetchSubscriptions: fetchSubscriptions,
  actionSaveHomepage: saveHomepage,
  actionSaveSubscriptions: saveSubscriptions,
  actionToggleAllSubscriptions: toggleAllSubscriptions,
  actionToggleSubscription: toggleSubscription,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfilePreferences),
);
