import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import style from '../../style/components/signin/AccountDeleteModal.module.scss';
import StyledModal from '../StyledModal';
import LabelSelect from '../LabelSelect';
import LabelTextArea from '../LabelTextArea';
import IndicatesRequiredField from '../IndicatesRequiredField';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCloseAccountReasons, closeAccountConfirm } from '../../actions';
import AccountDeleteConfirmModal from './AccountDeleteConfirmModal';
import { isEmpty } from '../../utils/isEmpty';

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

function initialFormData() {
  return {
    reason: '',
    comment: '',
  };
}

function isCommentRequired(formData, reasons) {
  const otherReason = reasons.find((r) => r.name === 'Other');
  return otherReason && formData.reason === otherReason.id.toString();
}

function validate(formData, reasons) {
  const errors = {};

  if (isEmpty(formData.reason)) {
    errors.reason = 'Reason is required';
  }

  if (isCommentRequired(formData, reasons) && isEmpty(formData.comment)) {
    errors.comment = 'Comment is required';
  }

  return errors;
}

const AccountDeleteModal = ({ closeModal, isOpen }) => {
  const [isAccountDeleteConfirmModalOpen, setIsAccountDeleteConfirmModalOpen] =
    useState(false);

  const [errors, setErrors] = useState(false);
  const [formData, setFormData] = useState(initialFormData());
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  useEffect(() => {
    // fetch close account reasons when modal is opened
    if (isOpen && !account.isFetching && !account.reasons.length) {
      dispatch(fetchCloseAccountReasons());
    }

    // close modal if the account has been deleted
    if (isOpen && !account.isFetching && account.deleted) {
      setIsAccountDeleteConfirmModalOpen(true);
      closeModal();
    }
  }, [
    isOpen,
    closeModal,
    dispatch,
    account,
    setIsAccountDeleteConfirmModalOpen,
  ]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const newErrors = validate(formData, account.reasons);
      setErrors(newErrors);

      if (!Object.keys(newErrors).length) {
        dispatch(
          closeAccountConfirm({
            comment: formData.comment,
            id: formData.reason,
          }),
        );
      }
    },
    [dispatch, formData, setErrors, account.reasons],
  );

  const submitting = account.isFetching;

  return (
    <>
      <StyledModal
        isOpen={isOpen}
        closeModal={() => {
          setFormData(initialFormData());
          setErrors(false);
          closeModal();
        }}
        submitting={submitting}
        title="Are you sure you want to permanently close your account?"
        wrapper={(children) => (
          <form onSubmit={handleSubmit} noValidate>
            {children}
          </form>
        )}
        primaryButton={{ text: 'Yes, close my account' }}
        cancelButton={{ text: 'No, cancel this request' }}
      >
        <p>
          Help us improve by sharing why you're closing your account as well as
          any comments you might have for our team.
        </p>

        <IndicatesRequiredField />
        <div className={style.inputGroup}>
          <div className={style.fullWidth}>
            <LabelSelect
              name="reason"
              label="Reason"
              placeholder="Select a reason for closing your account"
              error={errors.reason}
              value={formData.reason}
              onChange={handleChange}
              options={account.reasons.map((r) => ({
                value: r.id,
                label: r.name,
              }))}
              disabled={submitting || null}
              noMargin
              required
            />
          </div>
        </div>

        <div className={style.inputGroup}>
          <div className={style.fullWidth}>
            <LabelTextArea
              name="comment"
              label="Comment"
              placeholder="Share additional feedback or comments"
              error={errors.comment}
              value={formData.comment}
              onChange={handleChange}
              required={isCommentRequired(formData, account.reasons)}
            />
          </div>
        </div>
      </StyledModal>
      <AccountDeleteConfirmModal
        isOpen={isAccountDeleteConfirmModalOpen}
        closeModal={() => setIsAccountDeleteConfirmModalOpen(false)}
      />
    </>
  );
};

AccountDeleteModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

AccountDeleteModal.defaultProps = {
  reasons: [],
};

export default AccountDeleteModal;
