import React, { memo } from 'react';
import UserProps from '../../proptypes/user';
import { MMMMYYYY } from '../../utils/time';
import style from '../../style/components/profile/Header.module.scss';
import Card from '../Card';

const Header = ({ user }) => {
  const noName = !user.fname && !user.lname;

  return (
    <Card className={style.header}>
      {noName ? null : (
        <div className={style.name}>{`${user.fname} ${user.lname}`}</div>
      )}

      <div className={style.memberSince}>
        Member Since {MMMMYYYY(user.date)}
      </div>
    </Card>
  );
};

Header.propTypes = {
  user: UserProps,
};

export default memo(Header);
