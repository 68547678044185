import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import LoadingPage from '../components/LoadingPage';
import { redirect } from '../utils/redirect';
import { sessionCheck } from '../actions/session';
import i18n from '../i18n';
import { TRANSLATIONS_SUPPORTED } from '../constants';

function AuthRoute({
  actionSessionCheck,
  fetched,
  isFetching,
  isLoggedIn,
  location,
  requireAuth,
  url,
  ...rest
}) {
  const history = useHistory();
  useEffect(() => {
    // check the session on auth pages if not yet checked
    if (requireAuth && !fetched && !isFetching) {
      actionSessionCheck();
    }
  }, [actionSessionCheck, fetched, isFetching, requireAuth]);

  useEffect(() => {
    // redirect for auth page when not logged in
    if (requireAuth && fetched && !isLoggedIn) {
      redirect(url, location.pathname + location.search);
    }
  }, [
    fetched,
    isLoggedIn,
    requireAuth,
    url,
    location.pathname,
    location.search,
  ]);

  useEffect(() => {
    if (TRANSLATIONS_SUPPORTED) {
      // add language to URL when not specified (except for home page because redirect already happens)
      const pathBeginning = window.location.pathname.split('/')[1];
      if (pathBeginning && pathBeginning !== i18n.language) {
        // this forces the router to add language to URL because of the basename config
        history.replace(window.location.pathname + window.location.search);
      }
    }
  }, [history]);

  if (
    // non auth page
    !requireAuth ||
    // auth page
    (requireAuth && isLoggedIn)
  ) {
    return <Route {...rest} />;
  }

  return <Route {...rest} component={LoadingPage} />;
}

const mapStateToProps = (state) => ({ ...state.session });

const mapDispatchToProps = {
  actionSessionCheck: sessionCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
