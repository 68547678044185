import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  deleteEmail,
  fetchEmails,
  makePrimary,
  resendConfirmation,
} from '../actions/emails';
import Emails from '../components/profile/Emails';
import EmailDeleteModal from '../components/profile/EmailDeleteModal';
import EmailConfirmationModal from '../components/profile/EmailConfirmationModal';

export class ProfileEmails extends Component {
  static propTypes = {
    actionFetchEmails: PropTypes.func.isRequired,
    emails: PropTypes.arrayOf(PropTypes.shape({})),
    error: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    emails: [],
    error: '',
  };

  state = {
    deleteModalEmail: null,
    deleteModalIsOpen: false,
    confirmationModalEmail: {},
    confirmationModalIsOpen: false,
  };

  componentDidMount() {
    const { isFetching, actionFetchEmails } = this.props;
    if (!isFetching) {
      actionFetchEmails();
    }
  }

  handlePrimary = (index) => {
    const { actionMakePrimary, emails, isFetching } = this.props;

    if (!isFetching) {
      actionMakePrimary(emails[index]);
    }
  };

  handleDelete = (index) => {
    const { emails, isFetching } = this.props;

    if (!isFetching) {
      this.setState({
        deleteModalEmail: emails[index],
        deleteModalIsOpen: true,
      });
    }
  };

  handleDeleteCancel = () => {
    this.setState({
      deleteModalEmail: null,
      deleteModalIsOpen: false,
    });
  };

  handleDeleteConfirm = async () => {
    const { deleteModalEmail } = this.state;
    const { actionDeleteEmail } = this.props;

    await actionDeleteEmail(deleteModalEmail);

    this.setState({
      deleteModalEmail: null,
      deleteModalIsOpen: false,
    });
  };

  handleResend = (index) => {
    const { actionResendConfirmation, emails, isFetching } = this.props;

    if (!isFetching) {
      this.setState({
        confirmationModalEmail: emails[index],
        confirmationModalIsOpen: true,
      });
      actionResendConfirmation(emails[index]);
    }
  };

  handleResendClose = () => {
    this.setState({
      confirmationModalEmail: '',
      confirmationModalIsOpen: false,
    });
  };

  render() {
    const {
      deleteModalEmail,
      deleteModalIsOpen,
      confirmationModalEmail,
      confirmationModalIsOpen,
    } = this.state;
    const { emails, isFetching } = this.props;
    const funcs = {
      handleDelete: this.handleDelete,
      handlePrimary: this.handlePrimary,
      handleResend: this.handleResend,
    };

    return (
      <Fragment>
        <Emails
          emails={emails}
          isFetching={isFetching}
          handlePrimary={this.handlePrimary}
          handleResend={this.handleResend}
          handleDelete={this.handleDelete}
          {...funcs}
        />
        <EmailDeleteModal
          emails={emails}
          isOpen={deleteModalIsOpen}
          email={deleteModalEmail}
          handleCancel={this.handleDeleteCancel}
          handleConfirm={this.handleDeleteConfirm}
        />
        <EmailConfirmationModal
          isOpen={confirmationModalIsOpen}
          email={
            (confirmationModalEmail && confirmationModalEmail.address) || ''
          }
          handleCancel={this.handleResendClose}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.emails });

const mapDispatchToProps = {
  actionDeleteEmail: deleteEmail,
  actionFetchEmails: fetchEmails,
  actionMakePrimary: makePrimary,
  actionResendConfirmation: resendConfirmation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEmails);
