import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import WebsitesGrantedAccess from '../components/linkages/WebsitesGrantedAccess';
export class LinkedAccountsWebsites extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    accounts: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    accounts: [],
  };

  render() {
    const { accounts } = this.props;

    return (
      <Fragment>
        <WebsitesGrantedAccess accounts={accounts} />
      </Fragment>
    );
  }
}

export const mapStateToProps = (state) => ({ ...state.websites });

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LinkedAccountsWebsites),
);
