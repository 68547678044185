import axios from 'axios';
import { URL_IDS } from '../constants/urls';
import { statusActive, statusExpired, statusInactive } from '../utils/filters';
import { uniqueId } from '../utils/uniqueId';

export const FETCH_IDS_BEGIN = 'FETCH_IDS_BEGIN';
export const FETCH_IDS_ACTIVE_SUCCESS = 'FETCH_IDS_ACTIVE_SUCCESS';
export const FETCH_IDS_EXPIRED_SUCCESS = 'FETCH_IDS_EXPIRED_SUCCESS';
export const FETCH_IDS_INACTIVE_SUCCESS = 'FETCH_IDS_INACTIVE_SUCCESS';
export const FETCH_IDS_FAILURE = 'FETCH_IDS_FAILURE';

export const fetchIds = () => async (dispatch) => {
  dispatch({ type: FETCH_IDS_BEGIN });

  try {
    const response = await axios.get(URL_IDS);
    let ids = response.data || [];

    ids = ids.map((id) => {
      id.id = uniqueId();
      return id;
    });

    const active = ids.filter(statusActive);
    const expired = ids.filter(statusExpired);
    const inactive = ids.filter(statusInactive);

    dispatch({
      payload: active,
      type: FETCH_IDS_ACTIVE_SUCCESS,
    });

    dispatch({
      payload: expired,
      type: FETCH_IDS_EXPIRED_SUCCESS,
    });

    dispatch({
      payload: inactive,
      type: FETCH_IDS_INACTIVE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_IDS_FAILURE,
    });
  }
};
