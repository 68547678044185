import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFocus from '../../hooks/useFocus';
import style from '../../style/components/profile/UpdateWorkProfileModal.module.scss';
import InputWithError from '../InputWithError';
import LabelSelect from '../LabelSelect';
import { clearUpdateWorkProfile } from '../../actions/workProfile';
import { fetchEmails, updateWorkProfile } from '../../actions';
import ErrorAlertContainer from '../ErrorAlertContainer';
import IndicatesRequiredField from '../IndicatesRequiredField';
import SmartyStreetsAutocomplete from '../changeRequests/SmartyStreetsAutocomplete';
import StyledModal from '../StyledModal';
import { fetchStates } from '../../actions/states';
import { isEmpty } from '../../utils/isEmpty';
import Checkbox from '../Checkbox';
import { isValidUSZip } from '../../utils/address';
import { isValidPhoneNumber, isValidExtension } from '../../utils/phone';
import ContactInformationFormSection from './ContactInformationFormSection';

function isValidLinkedInURL(linkedin) {
  // assert that the url starts with https://www.linkedin.com or https://linkedin.com
  return /^https:\/\/(www\.)?linkedin.com/i.test(linkedin);
}

function validate(formData, isNumberValid) {
  const errors = {};

  // TODO: add back in for self asserted work profiles
  // if (isEmpty(formData.business_name)) {
  //   errors.business_name = 'Business name is required';
  // }

  if (isEmpty(formData.title)) {
    errors.title = 'Job title is required';
  }

  if (formData.linkedin && !isValidLinkedInURL(formData.linkedin)) {
    errors.linkedin =
      'Invalid LinkedIn URL. Must start with https://www.linkedin.com';
  }

  // address
  if (formData.includeWorkAddress) {
    if (isEmpty(formData.street)) {
      errors.street = 'Street address is required';
    }

    if (isEmpty(formData.city)) {
      errors.city = 'City is required';
    }

    if (isEmpty(formData.state)) {
      errors.state = 'State is required';
    }

    if (isEmpty(formData.zip)) {
      errors.zip = 'ZIP Code is required';
    } else if (!isValidUSZip(formData.zip)) {
      errors.zip = 'Invalid ZIP Code';
    }
  }

  // email
  if (isEmpty(formData.email_id)) {
    errors.email_id = 'Email is required';
  }

  // phone number
  if (!isEmpty(formData.number)) {
    if (!isNumberValid || !isValidPhoneNumber(formData.number)) {
      errors.number = 'Invalid phone number';
    }

    if (isEmpty(formData.linetype)) {
      errors.linetype = 'Type is required';
    }

    if (formData.hasExtension) {
      if (isEmpty(formData.extension)) {
        errors.extension = 'Extension is required';
      } else if (!isValidExtension(formData.extension)) {
        errors.extension = 'Invalid extension';
      }
    }
  }

  return errors;
}

function initialFormData(initialData) {
  return {
    business_name: initialData.business_name || '',
    title: initialData.title || '',
    linkedin: initialData.linkedin || '',

    // address
    includeWorkAddress: !!initialData.street,
    street: initialData.street || '',
    street2: initialData.street2 || '',
    city: initialData.city || '',
    state: initialData.state || '',
    zip: initialData.zip || '',

    // email
    email_id: initialData.email_id || '',

    // phone number
    number: initialData.number || '',
    hasExtension: !!initialData.extension,
    isValidPhoneNumber: initialData.isValidPhoneNumber || false,
    extension: initialData.extension || '',
    linetype: initialData.linetype || 'mobile',
  };
}

const UpdateWorkProfileModal = ({
  workProfileId,
  closeModal,
  isOpen,
  openSuccessModal,
  initialData,
}) => {
  const dispatch = useDispatch();

  const workProfileState = useSelector((state) => state.workProfile);

  const [errors, setErrors] = useState(false);
  const [formData, setFormData] = useState(initialFormData(initialData));

  const [isNumberValid, setIsNumberValid] = useState(true);

  const stateData = useSelector((state) => state.states);

  useEffect(() => {
    if (
      isOpen &&
      !stateData.error &&
      !stateData.isFetching &&
      !stateData.states.length
    ) {
      dispatch(fetchStates());
    }
  }, [isOpen, dispatch, stateData]);

  const handleChange = (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setFormData({ ...formData, [event.target.name]: value });
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const newErrors = validate(formData, isNumberValid);
      setErrors(newErrors);

      if (!Object.keys(newErrors).length) {
        // Create the submit data starting with required fields
        let submitData = {
          business_name: formData.business_name,
          title: formData.title,
          linkedin: formData.linkedin,
          email_id: formData.email_id,
        };

        if (formData.includeWorkAddress) {
          submitData = {
            ...submitData,
            street: formData.street,
            street2: formData.street2 || null,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
          };
        }

        if (formData.number) {
          submitData = {
            ...submitData,
            number: formData.number,
            linetype: formData.linetype,
          };

          if (formData.hasExtension) {
            submitData = {
              ...submitData,
              extension: formData.extension,
            };
          }
        }

        dispatch(updateWorkProfile(workProfileId, submitData));
      }
    },
    [dispatch, workProfileId, formData, setErrors, isNumberValid],
  );

  useEffect(() => {
    // when successful response: close modal, refresh data, and open success modal
    if (workProfileState.isUpdateSuccess) {
      dispatch(clearUpdateWorkProfile());
      dispatch(fetchEmails());
      closeModal();
      openSuccessModal();
    }
  }, [workProfileState, dispatch, closeModal, openSuccessModal]);

  const [zipInputRef, setZipInputFocus] = useFocus();

  const submitting = workProfileState.isFetching;

  return (
    <StyledModal
      isOpen={isOpen}
      closeModal={closeModal}
      submitting={submitting}
      title={'Edit your work profile for ' + initialData.business_name}
      primaryButton={{
        hidden: false,
        text: 'Save',
        submittingText: 'Saving...',
      }}
      wrapper={(children) => (
        <form
          onSubmit={handleSubmit}
          noValidate={true}
          className={style.wrapper}
        >
          {children}
        </form>
      )}
    >
      <IndicatesRequiredField />

      <section>
        <h4>Work Information</h4>
        {/* TODO: add back in for self-asserted work profiles */}
        {/* <InputWithError
          name="business_name"
          label="Business Name"
          placeholder="Enter business name"
          error={errors.business_name}
          value={formData.business_name}
          onChange={handleChange}
          required
          disabled={submitting}
        /> */}
        <InputWithError
          name="title"
          label="Job Title"
          placeholder="Enter job title"
          error={errors.title}
          value={formData.title}
          onChange={handleChange}
          required
          disabled={submitting}
        />
        <InputWithError
          name="linkedin"
          label="LinkedIn Profile"
          placeholder="https://www.linkedin.com/in/username"
          type="url"
          error={errors.linkedin}
          value={formData.linkedin}
          onChange={handleChange}
          disabled={submitting}
        />

        <Checkbox
          id="includeWorkAddress"
          onChange={handleChange}
          checked={formData.includeWorkAddress}
          name="includeWorkAddress"
          disabled={submitting}
        >
          Include work address
        </Checkbox>
      </section>

      {formData.includeWorkAddress && (
        <section>
          <h4>Work Address</h4>
          <SmartyStreetsAutocomplete
            name="street"
            label="Address Line 1"
            placeholder="Enter street address"
            error={errors.street}
            value={formData.street}
            required
            handleChange={handleChange}
            handleSelectSuggestion={(selectedAddressInfo) => {
              // update form with selected suggestion
              setFormData({ ...formData, ...selectedAddressInfo });

              // focus on zip field which is the last one filled out
              setZipInputFocus();
            }}
            disabled={submitting}
          />
          <InputWithError
            name="street2"
            label="Address Line 2"
            placeholder="Enter apt, suite, unit, building"
            error={errors.street2}
            value={formData.street2}
            onChange={handleChange}
            disabled={submitting}
          />
          <InputWithError
            name="city"
            label="City"
            placeholder="Enter city name"
            error={errors.city}
            value={formData.city}
            onChange={handleChange}
            required
            disabled={submitting}
          />

          <div className={style.inputGroup}>
            <div className={style.halfWidth}>
              <LabelSelect
                label="State"
                name="state"
                placeholder="Select a state"
                error={errors.state}
                value={formData.state}
                onChange={handleChange}
                options={stateData.states.map((s) => ({
                  label: s.name,
                  value: s.code,
                }))}
                noMargin
                required
                disabled={submitting}
              />
            </div>
            <div className={style.halfWidth}>
              <InputWithError
                name="zip"
                label="ZIP Code"
                placeholder="Enter ZIP code"
                error={errors.zip}
                value={formData.zip}
                onChange={handleChange}
                inputRef={zipInputRef}
                noMargin
                required
                disabled={submitting}
              />
            </div>
          </div>
        </section>
      )}

      <ContactInformationFormSection
        formData={formData}
        setFormData={setFormData}
        setIsNumberValid={setIsNumberValid}
        isWork={true}
        errors={errors}
        handleChange={handleChange}
      />

      <ErrorAlertContainer />
    </StyledModal>
  );
};

export default UpdateWorkProfileModal;
