import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TwoFAActiveRow from './TwoFAActiveRow';
import TwoFAEditRow from './TwoFAEditRow';

import BackupcodesIcon from '../../img/icons/backupcodes.svg';
import DevicekeyIcon from '../../img/icons/devicekey.svg';
import FidokeyIcon from '../../img/icons/fidokey.svg';
import GeneratorIcon from '../../img/icons/generator.svg';
import PhoneIcon from '../../img/icons/phone.svg';
import PushIcon from '../../img/icons/push.svg';
import YubikeyIcon from '../../img/icons/yubikey.svg';

import style from '../../style/components/signin/TwoFA.module.scss';
import {
  BACKUPCODES,
  DEVICEKEY,
  FIDOKEY,
  GENERATOR,
  PHONE,
  PUSH,
  YUBIKEY,
} from '../../constants/mfa';

const MFA_OPTION_DATA = {
  [BACKUPCODES]: {
    canUpdate: false,
    description: <p>Use a one-time backup code each time you sign in.</p>,
    icon: BackupcodesIcon,
  },
  [DEVICEKEY]: {
    canUpdate: true,
    description: (
      <p>
        Sign in with facial recognition, your fingerprint, or pattern unique to
        your device.
      </p>
    ),
    icon: DevicekeyIcon,
  },
  [FIDOKEY]: {
    canUpdate: true,
    description: (
      <p>Use a physical security key (insert or tap) with your device.</p>
    ),
    icon: FidokeyIcon,
  },
  [GENERATOR]: {
    canUpdate: true,
    description: (
      <p>
        Generate verification codes via code generator apps like{' '}
        <strong>ID.me Authenticator</strong> to sign in.
      </p>
    ),
    icon: GeneratorIcon,
  },
  [PHONE]: {
    canUpdate: false,
    description: (
      <>
        <p>
          Verification codes are sent by text message or phone call.
          <br />
          Not applicable for DEA EPCS authentication
        </p>
      </>
    ),
    icon: PhoneIcon,
  },
  [PUSH]: {
    canUpdate: true,
    description: (
      <p>
        Approve sign-ins via Push Notifications sent to the{' '}
        <strong>ID.me Authenticator</strong> mobile app.
      </p>
    ),
    icon: PushIcon,
  },
  [YUBIKEY]: {
    canUpdate: true,
    description: (
      <>
        <p>Enroll a YubiKey security key for secure mobile authentication.</p>
        <div className={style.weak}>
          ID.me Authenticator app download required.
        </div>
      </>
    ),
    icon: YubikeyIcon,
  },
};

const checkBrowserCompatibility = async (mfaHandle) => {
  if (mfaHandle === 'devicekey') {
    if (
      typeof window.PublicKeyCredential != 'undefined' &&
      typeof window.PublicKeyCredential
        .isUserVerifyingPlatformAuthenticatorAvailable != 'undefined'
    ) {
      return await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
    }
    return false;
  }
  return true;
};

const getRowStyle = (isEnrolled, isBrowserCompatible) => {
  if (!isBrowserCompatible) {
    return style.notCompatible;
  }
  return isEnrolled ? style.enrolled : style.unenrolled;
};

const Row2FA = ({
  handleAdd,
  handleChange,
  handleDelete,
  handleUpdate,
  handleCancelUpdate,
  handleConfirmUpdate,
  factors,
  factorToUpdate,
  newName,
  title,
  totalEnrolled,
  mfaHandle,
}) => {
  const isEnrolled = factors && factors.length > 0;

  const [isBrowserCompatible, setIsBrowserCompatible] = useState(true);
  useEffect(() => {
    async function checkCompatibilityAndSetState() {
      setIsBrowserCompatible(await checkBrowserCompatibility(mfaHandle));
    }
    checkCompatibilityAndSetState();
  }, [mfaHandle, setIsBrowserCompatible]);

  const isBackupcodes = mfaHandle === BACKUPCODES;
  const top = (
    <div className={style.row}>
      <div className={style.imgWrapper}>
        <img
          alt=""
          src={MFA_OPTION_DATA[mfaHandle].icon}
          className={style[mfaHandle]}
        />
      </div>
      <div>
        <h5>{title}</h5>
        {MFA_OPTION_DATA[mfaHandle].description}
        {!isBrowserCompatible && (
          <div className={style.notCompatibleMessage}>
            Not supported on this browser.
          </div>
        )}
      </div>
      {!isBackupcodes && (
        <div className={style.buttonWrapper}>
          <idme-button
            variant="secondary"
            size="small"
            text={isEnrolled ? 'Add' : 'Set Up'}
            disabled={!isBrowserCompatible || null}
            onClick={handleAdd}
          />
        </div>
      )}
    </div>
  );

  const bottom =
    isEnrolled &&
    factors.map((factor, index) => {
      if (factorToUpdate && factorToUpdate.index === index) {
        return (
          <TwoFAEditRow
            key={factor.id}
            newName={newName}
            handleConfirm={handleConfirmUpdate}
            handleCancel={handleCancelUpdate}
            handleChange={handleChange}
            {...factor}
          />
        );
      }
      return (
        <TwoFAActiveRow
          handleDelete={handleDelete}
          handleUpdate={
            MFA_OPTION_DATA[mfaHandle].canUpdate &&
            (factorToUpdate || handleUpdate)
          }
          index={index}
          key={factor.id}
          totalEnrolled={totalEnrolled}
          {...factor}
        />
      );
    });

  return (
    <div className={getRowStyle(isEnrolled, isBrowserCompatible)}>
      {top}
      {bottom}
    </div>
  );
};

Row2FA.propTypes = {
  factors: PropTypes.arrayOf(PropTypes.shape({})),
  handleAdd: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  mfaHandle: PropTypes.string.isRequired,
};

export default Row2FA;
