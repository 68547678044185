import {
  FETCH_CHANGE_REQUESTS_BEGIN,
  FETCH_CHANGE_REQUESTS_FAILURE,
  FETCH_CHANGE_REQUESTS_SUCCESS,
} from '../actions/changeRequests';

export const initialState = {
  error: null,
  isFetching: false,
  requests: { available: [], pending: [] },
};

const change_requests = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_CHANGE_REQUESTS_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_CHANGE_REQUESTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        requests: action.payload,
      };
    case FETCH_CHANGE_REQUESTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default change_requests;
