import {
  FETCH_UPDATE_ADDRESS_BEGIN,
  FETCH_UPDATE_ADDRESS_SUCCESS,
  FETCH_UPDATE_ADDRESS_FAILURE,
  FETCH_UPDATE_ADDRESS_CLEAR,
} from '../actions/addresses';

export const initialState = {
  error: null,
  isFetching: false,
};

const addresses = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_UPDATE_ADDRESS_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        isFetching: false,
        isUpdateSuccess: true,
      };
    case FETCH_UPDATE_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        isUpdateSuccess: false,
      };
    case FETCH_UPDATE_ADDRESS_CLEAR:
      return {
        ...state,
        error: null,
        isFetching: false,
        isUpdateSuccess: null,
      };
    default:
      return state;
  }
};

export default addresses;
