import React from 'react';
import style from '../style/components/IndicatesRequiredField.module.scss';

const IndicatesRequiredField = ({ ...props }) => (
  <div className={style.indicatesRequiredField} {...props}>
    * Indicates a required field
  </div>
);

export default IndicatesRequiredField;
