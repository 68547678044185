import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Counter from '../Counter';
import { getLoginLogo } from '../../utils/loginPropMap';
import style from '../../style/components/overview/Logins.module.scss';
import loginIcon from '../../img/icons/logins.svg';

const renderLogins = (logins) => (
  <div className={style.logins}>
    {logins.map((login) => (
      <div key={login.handle} className={style.login}>
        <img
          className={style[login.handle]}
          src={getLoginLogo(login.handle)}
          alt={login.name}
        />
      </div>
    ))}
  </div>
);

const Logins = ({ logins }) => (
  <article className={style.wrapper}>
    <div className={style.title}>
      <h3>
        <img alt="login" src={loginIcon} />
        My Logins
      </h3>
      <Counter>
        {logins.length}
        &nbsp;Active
      </Counter>
    </div>
    {renderLogins(logins)}
    <div className={style.manage}>
      <Link to="/signin/logins">Manage Logins</Link>
    </div>
  </article>
);

Logins.propTypes = {
  logins: PropTypes.arrayOf(PropTypes.shape({})),
};

Logins.defaultProps = {
  logins: [],
};

export default Logins;
