import React, { memo } from 'react';
import PropTypes from 'prop-types';
import LoginsActiveTable from './LoginsActiveTable';
import LoginInactive from './LoginInactive';
import loginPropTypes from '../../proptypes/login';
import { getLoginLogo } from '../../utils/loginPropMap';
import loginsIcon from '../../img/icons/logins.svg';
import style from '../../style/components/signin/Logins.module.scss';
import Card from '../Card';

const Logins = ({ handleAdd, handleDelete, logins, loginsInactive }) => (
  <Card
    title="ID.me Logins"
    icon={<img className={style.img} alt="logins" src={loginsIcon} />}
    className={style.logins}
  >
    <h4 className={style.subtitle}>{`Active (${logins.length})`}</h4>
    <LoginsActiveTable logins={logins} handleDelete={handleDelete} />
    <h4 className={style.subtitle}>Inactive</h4>
    <p>
      Adding logins will give you additional ways to sign in to your ID.me
      account.
    </p>
    <section className={style.inactive}>
      {loginsInactive.map((login) => (
        <LoginInactive
          {...login}
          key={login.handle}
          className={style.inactiveItem}
          handleAdd={handleAdd}
          logo={getLoginLogo(login.handle)}
        />
      ))}
    </section>
  </Card>
);

Logins.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  logins: PropTypes.arrayOf(loginPropTypes),
  loginsInactive: PropTypes.arrayOf(loginPropTypes),
};

Logins.defaultProps = {
  logins: [],
  loginsInactive: [],
};

export default memo(Logins);
