import React, { memo } from 'react';
import PropTypes from 'prop-types';
import style from '../style/components/ButtonPrimary.module.scss';

const ButtonPrimary = ({ children, ...props }) => (
  <button className={style.buttonPrimary} type="button" {...props}>
    {children}
  </button>
);

ButtonPrimary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(ButtonPrimary);
