import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fetchCashback } from '../actions/cashback';
import Cashback from '../components/overview/Cashback';

export class OverviewCashback extends Component {
  static propTypes = {
    actionFetchCashback: PropTypes.func.isRequired,
    cashBackLifetime: PropTypes.number.isRequired,
  };

  static defaultProps = {};

  componentDidMount() {
    const { actionFetchCashback, isFetching } = this.props;
    if (!isFetching) {
      actionFetchCashback();
    }
  }

  render() {
    const { cashBackLifetime, hideCashback } = this.props;
    if (hideCashback) {
      return null;
    }
    return <Cashback cashBackLifetime={cashBackLifetime} />;
  }
}

export const mapStateToProps = (state) => ({ ...state.cashback });

const mapDispatchToProps = {
  actionFetchCashback: fetchCashback,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OverviewCashback),
);
