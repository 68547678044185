import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';
import Slider from '../Slider';

const Subscriptions = ({
  handleCheck,
  handleSubscribe,
  sliderChecked,
  style,
  subscriptions,
  isFetching,
}) => {
  if (subscriptions.length === 0) {
    return null;
  }

  return (
    <>
      <div className={style.subscriptionsTop}>
        <div>Email Subscriptions</div>
        <Slider
          checked={sliderChecked}
          handleClick={handleSubscribe}
          id="subscribe-all-none"
          label={sliderChecked ? 'Unsubscribe All' : 'Subscribe All'}
        />
      </div>
      <div className={style.subscriptionsList}>
        {subscriptions
          .sort((a, b) => a.id - b.id)
          .map((sub, index) => (
            <Checkbox
              key={sub.name}
              id={sub.name}
              checked={sub.active}
              onChange={() => handleCheck(index)}
              disabled={isFetching}
            >
              <div>
                <h4>{sub.name}</h4>
                <span>{sub.description}</span>
              </div>
            </Checkbox>
          ))}
      </div>
    </>
  );
};

Subscriptions.propTypes = {
  handleCheck: PropTypes.func.isRequired,
  handleSubscribe: PropTypes.func.isRequired,
  sliderChecked: PropTypes.bool.isRequired,
  style: PropTypes.shape({}).isRequired,
  subscriptions: PropTypes.arrayOf(PropTypes.shape({})),
};

Subscriptions.defaultProps = {
  subscriptions: [],
};

export default memo(Subscriptions);
