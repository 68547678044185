import React from 'react';
import StyledModal from '../StyledModal';

const SelfieModal = ({ handleCancel, handleConfirm, isOpen, submitting }) => (
  <StyledModal
    isOpen={isOpen}
    closeModal={handleCancel}
    title="Are you sure you want to delete your selfie?"
    primaryButton={{ text: 'Yes, delete my selfie', action: handleConfirm }}
    cancelButton={{ text: 'No, cancel this request' }}
    submitting={submitting}
  >
    <p>Once your selfie is deleted, then this action cannot be undone.</p>
  </StyledModal>
);

export default SelfieModal;
