import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IdH4 } from '../IdHead';
import { ACTIVE, EXPIRED } from '../../constants';
import { MMDDYYYY } from '../../utils/time';
import style from '../../style/components/overview/Ids.module.scss';

const IdList = ({ active, expired }) => {
  const [selected, setSelected] = useState({ id: -1 });
  const [type, setType] = useState(ACTIVE);

  // filter out expired IDs for groups that have an active ID
  const activeGroups = active.map((d) => d.type);
  const showExpired = expired.filter((id) => {
    return !activeGroups.includes(id.type);
  });

  const noActive = active.length === 0;
  const noExpired = showExpired.length === 0;

  if ((!active && !expired) || (noActive && noExpired)) {
    return (
      <div className={style.no}>
        <h4>No linked IDs yet...</h4>
        <p>
          Adding an ID will give you access to benefits at ID.me partners that
          accept that ID.
        </p>
        <Link to="/wallet/ids">Manage IDs</Link>
      </div>
    );
  }

  return (
    <div className={style.list}>
      <ul>
        {active
          .filter((credential) => credential.id !== selected.id)
          .map((credential, index) => (
            <li key={credential.type} className={style.idCard}>
              <button
                type="button"
                onClick={() => {
                  setSelected(credential);
                  setType(ACTIVE);
                }}
              >
                <IdH4
                  {...credential}
                  iconUrl={credential.credential_icon_url}
                />
              </button>
              {/* only render the body if it is the last one and there are no expired ones and none are selected */}
              {noExpired &&
                index === active.length - 1 &&
                selected.id === -1 && (
                  <div className={style.cardBottom}>
                    <div className={style.cardDescription}>
                      {credential.title}
                    </div>
                    <div className={style.cardDate}>
                      <div>VERIFIED</div>
                      <div>{MMDDYYYY(credential.verified)}</div>
                    </div>
                  </div>
                )}
            </li>
          ))}
        {showExpired
          .filter((credential) => credential.id !== selected.id)
          .map((credential, index) => (
            <li key={credential.type} className={style.idCard}>
              <button
                type="button"
                onClick={() => {
                  setSelected(credential);
                  setType(EXPIRED);
                }}
              >
                <IdH4
                  {...credential}
                  iconUrl={credential.credential_icon_url}
                />
              </button>
              {/* only render the body if it is the last one and none are selected */}
              {index === showExpired.length - 1 && selected.id === -1 && (
                <div className={style.cardBottom}>
                  <div className={style.cardDescription}>
                    {credential.title}
                  </div>
                  <div className={style.cardDate}>
                    <div className={style.expired}>EXPIRED</div>
                    <div>{MMDDYYYY(credential.expires)}</div>
                  </div>
                </div>
              )}
            </li>
          ))}
        {selected.id !== -1 && (
          <li key={selected.type} className={style.idCard}>
            <button>
              <IdH4 {...selected} iconUrl={selected.credential_icon_url} />
            </button>
            {type === ACTIVE ? (
              <div className={style.cardBottom}>
                <div className={style.cardDescription}>{selected.title}</div>
                <div className={style.cardDate}>
                  <div>VERIFIED</div>
                  <div>{MMDDYYYY(selected.verified)}</div>
                </div>
              </div>
            ) : (
              <div className={style.cardBottom}>
                <div className={style.cardDescription}>{selected.title}</div>
                <div className={style.cardDate}>
                  <div className={style.expired}>EXPIRED</div>
                  <div>{MMDDYYYY(selected.expires)}</div>
                </div>
              </div>
            )}
          </li>
        )}
      </ul>
      <div className={style.manage}>
        <Link to="/wallet/ids">Manage IDs</Link>
      </div>
    </div>
  );
};

export default IdList;
