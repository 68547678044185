import memoize from './memoize';

/**
 * Calculates the weight of an email based on primary and confirmed.
 * @param {Object} email - The email object.
 * @returns {number} - The weight of the email.
 */
const emailWeights = (email) => {
  if (email.isPrimary) {
    return 2;
  }
  if (email.isVerified) {
    return 1;
  }
  return 0;
};

/**
 * Sorts an array of emails based on their weights and alphabetical address as a secondary sort.
 * @param {Array} emails - The array of emails to be sorted.
 * @returns {Array} - The sorted array of emails.
 */
const sortEmails = (emails) => {
  return emails.sort((a, b) => {
    const weightComparison = emailWeights(b) - emailWeights(a);
    if (weightComparison !== 0) {
      return weightComparison;
    }
    return a.address.localeCompare(b.address);
  });
};

/**
 * Returns memoized sorting of emails based on weights and alphabetical address.
 * @param {Array} emails - The array of emails to be sorted.
 * @returns {Array} - The sorted array of emails.
 */
export const sortedEmails = memoize((emails) => sortEmails(emails));
