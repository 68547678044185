import React, { Fragment, useState } from 'react';
import RevokeWebsiteModal from './RevokeWebsiteModal';

const RevokeWebsiteButton = ({ site, account }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Fragment>
      <idme-button
        variant="secondary"
        size="small"
        text="Revoke"
        layout="content"
        onClick={() => setIsModalOpen(true)}
      />
      <RevokeWebsiteModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        account={account}
        site={site}
      />
    </Fragment>
  );
};

export default RevokeWebsiteButton;
