import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Verified from '../img/icons/verified.svg';
import One from '../img/indicator/one.svg';
import Two from '../img/indicator/two.svg';
import Three from '../img/indicator/three.svg';
import Four from '../img/indicator/four.svg';
import Five from '../img/indicator/five.svg';

import style from '../style/components/IdHead.module.scss';

const DEFAULT_COLOR = '#2ea76c';

const indicators = {
  1: One,
  2: Two,
  3: Three,
  4: Four,
  5: Five,
};

function makeIdHead(headingLevel) {
  if (headingLevel < 1 || headingLevel > 6) {
    throw new Error('headingLevel must be between 1 and 6.');
  }

  const IdHead = ({
    color = DEFAULT_COLOR,
    iconUrl = Verified,
    stars,
    title,
  }) => {
    const body = (
      <Fragment>
        <img alt="" className={style.icon} src={iconUrl} />
        <span className={style.title}>{title}</span>
        {indicators[stars] && (
          <img alt="" className={style.indicator} src={indicators[stars]} />
        )}
      </Fragment>
    );

    return React.createElement(
      `h${headingLevel}`,
      {
        className: style.idTitle,
        style: { backgroundColor: color },
      },
      body,
    );
  };

  IdHead.propTypes = {
    color: PropTypes.string,
    iconUrl: PropTypes.string,
    stars: PropTypes.number,
    title: PropTypes.string,
  };

  return IdHead;
}

export const IdH1 = makeIdHead(1);
export const IdH2 = makeIdHead(2);
export const IdH3 = makeIdHead(3);
export const IdH4 = makeIdHead(4);
export const IdH5 = makeIdHead(5);
export const IdH6 = makeIdHead(6);
