import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/shared/en.json';
import translationES from './locales/shared/es.json';
import translationAR from './locales/shared/ar.json';
import translationBN from './locales/shared/bn.json';
import translationZH_HANS from './locales/shared/zh-Hans.json';
import translationZH from './locales/shared/zh.json';
import translationFR_CA from './locales/shared/fr-CA.json';
import translationFA from './locales/shared/fa.json';
import translationHI from './locales/shared/hi.json';
import translationHT from './locales/shared/ht.json';
import translationHY from './locales/shared/hy.json';
import translationIT from './locales/shared/it.json';
import translationJA from './locales/shared/ja.json';
import translationKO from './locales/shared/ko.json';
import translationKM from './locales/shared/km.json';
import translationPA from './locales/shared/pa.json';
import translationRU from './locales/shared/ru.json';
import translationTH from './locales/shared/th.json';
import translationTL from './locales/shared/tl.json';
import translationVI from './locales/shared/vi.json';
import translationYI from './locales/shared/yi.json';

const SUPPORTED_LANGUAGES = [
  'en',
  'es',
  'ar',
  'bn',
  'zh_Hans',
  'zh',
  'fr_CA',
  'hi',
  'ht',
  'hy',
  'it',
  'ja',
  'pa',
  'ru',
  'th',
  'tl',
  'vi',
  'yi',
];
// TODO ADD 'km', 'ko', 'fa' to SUPPORTED_LANGUAGES

// the translations
const resources = {
  en: { translation: translationEN },
  es: { translation: translationES },
  ar: { translation: translationAR },
  bn: { translation: translationBN },
  zh_Hans: { translation: translationZH_HANS },
  zh: { translation: translationZH },
  fr_CA: { translation: translationFR_CA },
  fa: { translation: translationFA },
  hi: { translation: translationHI },
  ht: { translation: translationHT },
  hl: { translation: translationHY },
  it: { translation: translationIT },
  ja: { translation: translationJA },
  pa: { translation: translationPA },
  km: { translation: translationKM },
  ko: { translation: translationKO },
  ru: { translation: translationRU },
  th: { translation: translationTH },
  tl: { translation: translationTL },
  vi: { translation: translationVI },
  yi: { translation: translationYI },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      lookupFromPathIndex: 0,
      order: ['path', 'navigator'],
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources,
    supportedLngs: SUPPORTED_LANGUAGES,
  });

export default i18n;
