import React from 'react';
import style from '../../style/components/signin/PasswordValidation.module.scss';

const PasswordValidation = ({ rules = [], validated }) => (
  <div className={style.validation}>
    <h3>Password should contain:</h3>
    <ul>
      {rules.map((rule, index) => {
        const className = validated[index] ? style.green : style.red;
        const symbol = validated[index] ? '✓' : 'X';
        return (
          <li key={rule}>
            <span className={className}>{symbol}</span>
            &nbsp;
            {rule}
          </li>
        );
      })}
    </ul>
    <p>Password can&#39;t be common or easily guessable</p>
  </div>
);

export default PasswordValidation;
