import {
  FETCH_DATA_DOWNLOADS_STATUS_BEGIN,
  FETCH_DATA_DOWNLOADS_STATUS_SUCCESS,
  FETCH_DATA_DOWNLOADS_STATUS_FAILURE,
  FETCH_DATA_DOWNLOAD_FILE_BEGIN,
  FETCH_DATA_DOWNLOAD_FILE_SUCCESS,
  FETCH_DATA_DOWNLOAD_FILE_FAILURE,
} from '../actions/dataDownloads';

export const initialState = {
  error: null,
  isFetching: false,
};

const dataDownloads = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_DATA_DOWNLOADS_STATUS_BEGIN:
    case FETCH_DATA_DOWNLOAD_FILE_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
        isRequestSuccess: null,
      };
    case FETCH_DATA_DOWNLOADS_STATUS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        isFetching: false,
      };
    case FETCH_DATA_DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        isFetching: false,
        isRequestSuccess: true,
      };

    case FETCH_DATA_DOWNLOADS_STATUS_FAILURE:
    case FETCH_DATA_DOWNLOAD_FILE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default dataDownloads;
