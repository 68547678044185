import React from 'react';
import PropTypes from 'prop-types';
import DefaultHomepage from './DefaultHomepage';
import Subscriptions from './Subscriptions';
import style from '../../style/components/profile/Preferences.module.scss';
import Card from '../Card';

const Preferences = ({
  defaultHomepage,
  handleCheck,
  handleRadioChange,
  handleSubscribe,
  sliderChecked,
  subscriptions,
  isFetching,
}) => (
  <Card
    title="Preferences"
    icon={<idme-icon name="sliders" color="stone" size="large" />}
    className={style.wrapper}
  >
    <div>
      <DefaultHomepage
        defaultHomepage={defaultHomepage}
        handleRadioChange={handleRadioChange}
        isFetching={isFetching}
        style={style}
      />
      <Subscriptions
        handleCheck={handleCheck}
        handleSubscribe={handleSubscribe}
        sliderChecked={sliderChecked}
        subscriptions={subscriptions}
        isFetching={isFetching}
        style={style}
      />
    </div>
  </Card>
);

Preferences.propTypes = {
  defaultHomepage: PropTypes.string,
  handleCheck: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleSubscribe: PropTypes.func.isRequired,
  sliderChecked: PropTypes.bool.isRequired,
  subscriptions: PropTypes.arrayOf(PropTypes.shape({})),
  isFetching: PropTypes.bool.isRequired,
};

Preferences.defaultProps = {
  defaultHomepage: '',
  subscriptions: [],
};

export default Preferences;
