import {
  FETCH_IDS_BEGIN,
  FETCH_IDS_ACTIVE_SUCCESS,
  FETCH_IDS_EXPIRED_SUCCESS,
  FETCH_IDS_INACTIVE_SUCCESS,
  FETCH_IDS_FAILURE,
} from '../actions/ids';

export const initialState = {
  active: [],
  error: null,
  expired: [],
  inactive: [],
  isFetching: false,
};

const ids = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_IDS_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_IDS_ACTIVE_SUCCESS:
      return {
        ...state,
        active: action.payload,
        isFetching: false,
      };
    case FETCH_IDS_EXPIRED_SUCCESS:
      return {
        ...state,
        expired: action.payload,
        isFetching: false,
      };
    case FETCH_IDS_INACTIVE_SUCCESS:
      return {
        ...state,
        inactive: action.payload,
        isFetching: false,
      };
    case FETCH_IDS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default ids;
