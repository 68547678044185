import React from 'react';
import PropTypes from 'prop-types';
import style from '../../style/components/linkages/LinkedAccounts.module.scss';
import LinkedAccountsTable from './LinkedAccountsTable';

const LinkedAccounts = ({ accounts }) => (
  <main className={style.accounts}>
    <h3 className={style.title}>
      <idme-icon name="users" />
      Linked Accounts
    </h3>
    <div className={style.table}>
      <LinkedAccountsTable accounts={accounts} />
    </div>
  </main>
);

LinkedAccounts.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})),
  isFetching: PropTypes.bool.isRequired,
};

LinkedAccounts.defaultProps = {
  accounts: [],
};

export default LinkedAccounts;
