import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CloseAccount from '../components/signin/CloseAccount';
import AccountDeleteModal from '../components/signin/AccountDeleteModal';
import UserProps from '../proptypes/user';
import { getReactEnv } from '../constants';

export class SigninCloseAccount extends Component {
  static propTypes = {
    modalDisplay: PropTypes.bool,
    user: UserProps.isRequired,
  };

  state = {
    isOpen: false,
  };

  componentDidMount() {
    if (getReactEnv() === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }

  handleCloseAccount = async () => {
    this.setState({ isOpen: true });
  };

  handleCloseAccountCancel = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { modalDisplay, user } = this.props;
    const { isOpen } = this.state;

    return (
      <Fragment>
        <CloseAccount
          handleCloseAccount={this.handleCloseAccount}
          hasStateWorkforceCredential={user.workforce_credential}
          modalDisplay={modalDisplay}
        />
        <AccountDeleteModal
          isOpen={isOpen}
          closeModal={this.handleCloseAccountCancel}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SigninCloseAccount),
);
