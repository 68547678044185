import axios from 'axios';
import { URL_CASHBACK } from '../constants/urls';

export const FETCH_CASHBACK_BEGIN = 'FETCH_CASHBACK_BEGIN';
export const FETCH_CASHBACK_SUCCESS = 'FETCH_CASHBACK_SUCCESS';
export const FETCH_CASHBACK_FAILURE = 'FETCH_CASHBACK_FAILURE';

export const fetchCashback = () => async (dispatch) => {
  dispatch({ type: FETCH_CASHBACK_BEGIN });
  try {
    const response = await axios.get(URL_CASHBACK);

    dispatch({
      payload: response.data,
      type: FETCH_CASHBACK_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: error.message,
      type: FETCH_CASHBACK_FAILURE,
    });
  }
};
