import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import reducers from './reducers';

const configureStore = () => {
  // configure redux and the redux store
  const middleware = [thunk];

  const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(...middleware)),
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(reducers);
      });
    }
  }

  return store;
};

export default configureStore;
